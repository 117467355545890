import { useState } from 'react';
import styles from './FilterModalSave.module.scss';
import { Button, InputField, Spacer, H2 } from '@components/index';
import { BUTTON_ACTION_TYPE, BUTTON_SIZE, BUTTON_TYPES, SPACER_DIRECTION } from '@types';
import SaveIcon from '@mui/icons-material/Save';

type FilterModalProps = {
    saveFilter: (name:string) => void;
    cancel: () => void;
}

const FilterModalSave = (props:FilterModalProps) => {
	const {
		saveFilter,
		cancel
	} = props;
	const [filterName, setFilterName] = useState('');
	const triggerCancel = () => {
		setFilterName('');
		cancel();
	}
	const triggerSave = () => {
		saveFilter(filterName);
		triggerCancel();
	}
	return (
		<form className={styles.container}>
			<SaveIcon style={{ color: 'var(--brand-orange-default)', fontSize: '60px' }} />
			<H2 color={'var(--brand-orange-default)'} text={'Save?'} />
			<Spacer size={30} />
			<InputField
				name={'Save-Filter-Name'}
				autoFocus
				label={'Enter a name for your Filter'}
				value={filterName}
				onChange={(e) => {
					setFilterName(e.target.value);
				}} />
			<div className={styles.buttons}>
				<Spacer size={20} dir={SPACER_DIRECTION.VERTICAL} />
				<Button size={BUTTON_SIZE.SMALL} type={BUTTON_TYPES.TERTIARY} text={'Cancel'} onClick={triggerCancel} />
				<Spacer size={40} dir={SPACER_DIRECTION.VERTICAL} />
				<Button
					actionType={BUTTON_ACTION_TYPE.SUBMIT}
					size={BUTTON_SIZE.SMALL}
					text={'Save Filter'}
					onClick={triggerSave}
					disabled={filterName === ''}
				/>
			</div>
		</form>
	);
}

export default FilterModalSave;

import styles from './Loader.module.css';

type LoaderProps = {
	size?: number;
	spinnerColor?: string;
	trayColor?: string;
	thickness?: number;
	speed?: number;
}
type InnerStyle = {
	width?: string;
	height?: string;
	border?: string;
	borderTop?: string;
	animationDuration?: string;
}

export const Loader = (props:LoaderProps) => {
	const {
		size = 30,
		spinnerColor = 'var(--brand-orange-default)',
		trayColor = 'var(--neutral-05)',
		thickness = 5,
		speed = 500
	} = props;
	const applyStyles = () => {
		const Styles:InnerStyle = {};
		Styles.width = `${size}px`;
		Styles.height = `${size}px`;
		Styles.border = `${thickness}px solid ${trayColor}`;
		Styles.borderTop = `${thickness}px solid ${spinnerColor}`;
		Styles.animationDuration = `${speed}ms`;
		return Styles;
	}
	return (
		<div className={styles.loader} style={applyStyles()} />
	);
}

import {
	useState,
	useContext,
	useEffect,
	useRef,
	PropsWithChildren,
	MouseEvent
} from 'react';
import { createPortal } from 'react-dom';
import styles from './FloatingMenu.module.scss';
import { FloatingMenuContext } from './FloatingMenu';
import CloseButton from '@mui/icons-material/Close';
import { TObject } from '@types';

type TProps = {
	menuPos?: 'left' | 'center' | 'right';
	borderRadius?: number;
	offset?: number;
	showCloseButton?: boolean;
	closeOnClick?: boolean;
}

export const Menu = (props:PropsWithChildren<TProps>) => {
	const {
		menuPos = 'center',
		borderRadius = 10,
		offset = 5,
		showCloseButton = false,
		closeOnClick = true,
		children
	} = props;
	const ctx = useContext(FloatingMenuContext);
	const [isAboveFold, setIsAboveFold] = useState(true);
	const [showMenu, setShowMenu] = useState(false);
	const menuRef = useRef<HTMLDivElement | null>(null);
	const setPosition = () => {
		if (ctx?.parentRef && menuRef.current) {
			const { offsetHeight, offsetWidth } = ctx.parentRef;
			const { left, top } = ctx.parentRef.getBoundingClientRect();
			const { offsetHeight: oHeight, offsetWidth: oWidth } = menuRef.current;
			const styles:TObject = {};
			let xPos = 0;
			switch (menuPos) {
			case 'left':
				xPos = left;
				break;
			case 'right':
				xPos = left + offsetWidth - oWidth;
				break;
			default:
				xPos = left - (oWidth / 2) + (offsetWidth / 2);
			}
			styles.position = 'fixed';
			styles.left = 0;
			styles.top = 0;
			styles.minWidth = 'max-content';
			styles.willChange = 'transform';
			if (isAboveFold) {
				styles.transform = `translate(${xPos}px, ${offsetHeight + top + offset}px)`;
			} else {
				styles.transform = `translate(${xPos}px, ${top - oHeight - offset}px)`;
			}
			return styles;
		}
		return {}
	}
	const setStyles = () => {
		const styles:TObject = {};
		styles.borderRadius = `${borderRadius}px`;
		return styles;
	}
	const preventBubble = (e:MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		if (closeOnClick && ctx?.toggleMenu) ctx?.toggleMenu(false);
	}
	const toggleMenu = () => {
		if (ctx?.showMenu && ctx?.parentRef) {
			const { offsetHeight } = ctx.parentRef;
			const { top } = ctx.parentRef.getBoundingClientRect();
			let oHeight = 0;
			if (menuRef && menuRef.current) oHeight = menuRef.current.offsetHeight
			setIsAboveFold(window.innerHeight - (offsetHeight + top) - oHeight > 0);
			setTimeout(() => {
				setShowMenu(true)
			}, 100);
		} else {
			setShowMenu(false);
		}
	}

	useEffect(() => {
		toggleMenu();
	}, [ctx?.showMenu]); // eslint-disable-line

	return (
		<>
			{ctx?.showMenu && createPortal(<div data-testid={'@Ofload-FloatingMenu-Menu'} onClick={(e) => preventBubble(e)} className={`floating-menu-menu ${styles.menu} ${showMenu ? styles.showMenu : ''}`} style={{ ...setPosition(), ...setStyles() }} ref={menuRef}>
				{showCloseButton && <button data-testid={'@Ofload-FloatingMenu-CloseBtn'} className={styles.closeBtn} onClick={() => { ctx?.toggleMenu(false) }} aria-label={'Close Floating Menu Button'}><CloseButton /></button>}
				{children}
			</div>, document.body)}
		</>
	);
}

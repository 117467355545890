import styles from './SuspenseLoader.module.scss';
import { Loader } from '@components';

const SuspenseLoader = () => {
	return (
		<div className={styles.container}>
			<Loader />
		</div>
	);
}

export default SuspenseLoader;
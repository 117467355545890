import styles from './TypeAhead.module.scss';
import { Loader } from '@components';
import { TypeAheadOption, TGroupedOpts } from '@types';

type TProps = {
	options: TypeAheadOption[] | string[] | TGroupedOpts | null;
	selected: (o:TypeAheadOption | string, p?:{ group: string }) => void;
	noOptionsText?: string;
	isLoadingOptions: boolean;
	selectid?: string | null;
}
type ListItem = {
	selectId: string | null | undefined;
	className: string;
	onClick?: () => void;
	role: string;
	label: string | JSX.Element;
}

export const Options = (props:TProps) => {
	const {
		options,
		selected,
		noOptionsText,
		isLoadingOptions,
		selectid
	} = props;
	const isObject = (o:any) => {
		return typeof o === 'object' && !Array.isArray(o) && o !== null;
	}
	const noOptions = () => {
		if (options === null) {
			return noOptionsText ?
				<li data-selectid={selectid} className={styles.noOptsTxt}>{noOptionsText}</li> :
				<li data-selectid={selectid} className={styles.noOptsTxt}>Keep typing for more options...</li>;
		} else if (Array.isArray(options) && !options.length && !isLoadingOptions) {
			return noOptionsText ?
				<li data-selectid={selectid} className={styles.noOptsTxt}>{noOptionsText}</li> :
				<li data-selectid={selectid} className={styles.noOptsTxt}>Keep typing for more options...</li>;
		} else if (
			typeof options === 'object' &&
			!Array.isArray(options) &&
			Object.keys(options).length === 0
		) {
			return noOptionsText ?
				<li data-selectid={selectid} className={styles.noOptsTxt}>{noOptionsText}</li> :
				<li data-selectid={selectid} className={styles.noOptsTxt}>Keep typing for more options...</li>;
		}
		return null;
	}
	const buildOptions = () => {
		const opts: ListItem[] = [];
		if (Array.isArray(options)) {
			for (let i=0, l=options.length; i<l; ++i) {
				let label;
				if (isObject(options[i])) {
					const opt = options[i] as TypeAheadOption;
					label = opt.label; // eslint-disable-line
				} else {
					const opt = options[i] as string;
					label = opt;
				}
				opts.push({
					selectId: selectid,
					className: styles.option,
					onClick: () => selected(options[i]),
					role: 'option',
					label
				});
			}
		} else if (
			typeof options === 'object' &&
			!Array.isArray(options) &&
			options !== null
		) {
			for (const [key, value] of Object.entries(options)) {
				opts.push({
					selectId: selectid,
					className: styles.groupHeader,
					role: 'group',
					label: key
				});
				for (let i=0, l=value.length; i<l; ++i) {
					const group = key;
					let label;
					if (isObject(value[i])) {
						const opt = value[i] as TypeAheadOption;
						label = opt.label; // eslint-disable-line
					} else {
						const opt = value[i] as string;
						label = opt;
					}
					opts.push({
						selectId: selectid,
						className: styles.option,
						onClick: () => selected(value[i], { group }),
						role: 'option',
						label
					});
				}
			}
		}
		return opts;
	}
	return (
		<>
			{!isLoadingOptions && noOptions()}
			{isLoadingOptions && <li data-testid={'@Ofload-TypeAhead-Loader'} className={styles.loader}>
				<Loader size={15} thickness={4} />
			</li>}
			{!isLoadingOptions && buildOptions().map((option:ListItem, index:number) => {
				return option.role === 'group' ? <li
					key={index}
					data-selectid={option.selectId}
					className={option.className}
					role={option.role}
				>
					{option.label}
				</li> : <li
					key={index}
					data-selectid={option.selectId}
					className={option.className}
					onClick={option.onClick}
					role={option.role}
					aria-selected={false}
				>
					{option.label}
				</li>
			})}
		</>
	);
}

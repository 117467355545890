import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { JWT } from '@state';
import { useRecoilValue } from 'recoil';
import jwt_decode from 'jwt-decode';
import { TJWT } from '@types';
import { Loader } from '@components';
import styles from './RoleGuard.module.scss';

type TProps = {
	initComplete: boolean;
	roles: string[];
	mode?: 'allow' | 'deny';
	redirect: string;
	domain?: 'spa' | 'eos'
}

const RoleGuard = (props: TProps) => {
	const {
		roles = [],
		mode = 'deny',
		redirect = '/',
		domain = 'spa'
	} = props;
	const jwt = useRecoilValue(JWT);
	const navigate = useNavigate();
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (jwt) {
			const decoded:TJWT = jwt_decode(jwt);
			const hasRole = decoded.roles.some((role) => roles.includes(role));
			if (
				(hasRole && mode === 'deny') ||
				(!hasRole && mode === 'allow')
			) {
				domain === 'spa' ?
					navigate(redirect) :
					window.location.href = redirect;
			} else {
				setReady(true);
			}
		}
	}, [jwt]);

	return (
		<>
			{!ready && <div className={styles.container}>
				<Loader />
			</div>}
			{ready && <Outlet />}
		</>
	);
}

export default RoleGuard;

import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { Radio, Text } from '@components';
import { TEXT_WEIGHT } from '@types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type FilterProps = {
    header: any;
    meta: any;
    filterData: any;
    onChange: (val: any) => void;
};

export const FilterSelectGroup = (props: FilterProps) => {
	const { header, meta, filterData, onChange } = props;
	const [RadioOptions, setRadioOptions] = useState<any[]>([]);
	const [accordionExpanded, setAccordionExpanded] = useState(meta?.isOpen ?? false);
	const fullName = meta?.columnFullName;
	const filterOptions = meta?.filterOptions?.options ?? [];
	const changeRadioValue = (e: any) => {
		const newOpts = RadioOptions.map((opt) => {
			opt.checked = false;
			if (opt.value === e.target.value) opt.checked = true;
			return opt;
		});

		setRadioOptions(newOpts);

		const newFilterData = {
			...filterData,
			value: newOpts,
			columnField: meta?.databaseLocation,
			operatorValue: meta.operatorValue
				? meta.operatorValue
				: newOpts.filter((opt) => opt.checked).length > 1
					? 'In'
					: 'Is',
		};

		onChange(newFilterData);
	};

	const generateRadioOptions = (options: any[]) => {
		const opts = options.map((opt) => {
			return {
				checked: opt?.checked,
				value: typeof opt === 'string' ? opt : opt.value,
				label: typeof opt === 'string' ? opt : opt.label,
			};
		});
		return opts;
	};
	useEffect(() => {
		if (filterData?.value) {
			setRadioOptions(filterData.value);
		} else {
			setRadioOptions(generateRadioOptions(filterOptions));
		}
	}, [meta, filterData]);
	return (
		<>
			<div className={styles.filterLabel}>
				<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={fullName !== undefined ? fullName : header} />
				<button
					onClick={() => {
						setAccordionExpanded(!accordionExpanded);
					}}
					className={`${styles.accordionButton} ${accordionExpanded ? styles.flipArrow : ''}`}
					aria-label={'Set Accordion Expanded'}
				>
					<KeyboardArrowDownIcon />
				</button>
			</div>
			{accordionExpanded && (
				<div className={styles.inset}>
					{RadioOptions.map((option, i) => {
						return <Radio
							key={i}
							value={option.value}
							name={option.label}
							label={option.label}
							checked={option.checked}
							onChange={(e: any) => {
								changeRadioValue(e);
							}}
						/>
					})}
				</div>
			)}
		</>
	);
};

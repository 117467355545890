import {
	TEOSMasterManifest,
	TEOSshipment,
	UnallocatedShipment,
} from '@types';

export const generateTruckInfo = (shipment: UnallocatedShipment | TEOSshipment, masterManifest?: TEOSMasterManifest | null) => {

	const truckSizeName = masterManifest?.truck_size?.name ||
				('req_truck_size' in shipment
					? shipment.req_truck_size?.name
					: '');
	const truckTypeName = masterManifest?.truck_type?.name ||
				('req_truck_type' in shipment
					? shipment.req_truck_type?.name
					: '');

	return [truckSizeName, truckTypeName].filter((v) => !!v).join(' ') || '-';
}

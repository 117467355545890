import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticatedUser } from '@state';
import { setPendoIdentify } from '@utils/pendoSetIdentity.ts';

export const usePendoIdentity = () => {
	const authUser = useRecoilValue(authenticatedUser);

	useEffect(() => {
		if (authUser) {
			const { email } = authUser.team;
			const companyName = authUser.company?.company_name;

			setPendoIdentify({
				visitor: {
					id: authUser.id,
					email,
					slug: authUser.slug,
					role: authUser.company?.id === 1 ? 'Admin' : authUser.shipper?.id
						? 'Shipper'
						: authUser?.carrier?.id ? 'Carrier' : null,
					company: companyName
				},
				account: {
					id: authUser.company?.id,
					company_name: companyName
				}
			});
		}
	}, [authUser]);
};

export default usePendoIdentity;
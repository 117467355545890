export const combineValues = (values: any) => {
	return values.map((value: any) => {
		if (!value.value) {
			return null;
		}
		return [value.prefix, value.value, value.suffix]
			.filter((v) => !!v)
			.join(' ')
	})
		.filter(((v: any) => !!v))
		.join(' | ')
}
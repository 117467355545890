import {
	CARGO_TYPE_ID,
	UnallocatedShipment,
	TEOSshipment, UnallocatedCargo,
} from '@types';
import {
	getTotalQuantityAndWeight,
	combineValues,
	formatNumber,
	generateTruckInfo,
} from '@utils';

export const getCargoDescription = (shipment: UnallocatedShipment | TEOSshipment, cargoData = []) => {
	const cargos = shipment !== null ? shipment.cargos : cargoData;
	const firstCargo = cargos?.[0];
	const truckInfo = generateTruckInfo(shipment).replace(/-/g, '');
	const palletTotals = getTotalQuantityAndWeight(cargos, shipment?.cargos?.[0].cargo_type_id);
	const VolumetricTotals = cargos.reduce(
		(accumulator, cargo) => {
			accumulator.totalWeight += parseFloat(`${cargo.total_weight}`);
			accumulator.totalQty += parseFloat(`${cargo.qty}`);

			return accumulator;
		},
		{
			totalWeight: 0,
			totalQty: 0
		}
	);
	switch (firstCargo?.cargo_type_id) {
	case CARGO_TYPE_ID.Pallets:
		return combineValues([
			{ value: 'Less Than' },
			{
				value: palletTotals.totalQty,
				suffix: 'spaces',
				suffixSingular: 'space'
			},
			{
				value: palletTotals.totalPallets,
				suffix: 'pallets',
				suffixSingular: 'pallet'
			},
			{ value: truckInfo },
			{
				value: formatNumber(palletTotals.totalWeight),
				suffix: 'kg',
				prefix: 'Total weight:'
			},
		]);

	case CARGO_TYPE_ID.FullTruckload:
		return combineValues([
			{ value: 'Full Load' },
			{
				value: palletTotals.totalQty,
				suffix: 'spaces',
				suffixSingular: 'space'
			},
			{
				value: palletTotals.totalPallets,
				suffix: 'pallets',
				suffixSingular: 'pallet'
			},
			{ value: truckInfo },
			{
				value: formatNumber(VolumetricTotals.totalWeight),
				suffix: 'kg',
				prefix: 'Total weight:'
			},
		]);

	case CARGO_TYPE_ID.Volumetric: {
		const VolumetricTotals = cargos.reduce(
			(accumulator, cargo) => {
				accumulator.totalWeight += parseFloat(`${cargo.total_weight}`);
				accumulator.totalQty += (cargo as UnallocatedCargo).qty ?? 0;

				return accumulator;
			},
			{
				totalWeight: 0,
				totalQty: 0
			}
		);

		return combineValues([
			{ value: 'By weight' },
			{
				value: formatNumber(VolumetricTotals.totalWeight),
				suffix: 'kg',
				prefix: 'Total:'
			},
			{ value: truckInfo },
		]);
	}

	case CARGO_TYPE_ID.LoadingMeters: {
		const LMTotals = cargos.reduce(
			(accumulator, cargo) => {
				accumulator.totalMeters += (cargo as UnallocatedCargo).load_mtr ?? 0;

				return accumulator;
			},
			{ totalMeters: 0 }
		);

		return combineValues([
			{
				value: formatNumber(LMTotals.totalMeters),
				suffix: 'm',
				prefix: 'Loading meter:'
			},
			{ value: truckInfo },
		]);
	}
	case CARGO_TYPE_ID.Loose: {
		const VolumetricTotals = cargos.reduce(
			(accumulator, cargo) => {
				accumulator.totalWeight += typeof cargo.total_weight === 'number'
					? cargo.total_weight
					: parseFloat(cargo.total_weight);
				accumulator.totalQty += (cargo as UnallocatedCargo).qty ?? 0;

				return accumulator;
			},
			{
				totalWeight: 0,
				totalQty: 0
			}
		);

		return combineValues([
			{ value: 'Full Load' },
			{ value: 'Loose' },
			{ value: truckInfo },
			{
				value: formatNumber(VolumetricTotals.totalWeight),
				suffix: 'kg',
				prefix: 'Total weight:'
			},
		]);
	}
	case CARGO_TYPE_ID.Other:
		return combineValues([
			{ value: 'Other' },
			{
				value: palletTotals.totalQty,
				prefix: 'Quantity '
			},
			{ value: truckInfo },
			{
				value: formatNumber(palletTotals.totalWeight),
				suffix: 'kg',
				prefix: 'Total weight:'
			},
		]);
	default:
		return '';
	}
}

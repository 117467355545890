import { PropsWithChildren, KeyboardEvent, MouseEvent, useContext } from 'react';
import styles from './Select.module.scss';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Spacer } from '@components';
import { SPACER_DIRECTION } from '@types';
import { SelectContext } from './Select';

type TProps = {
	value: any;
	index?: number;
	selected?: any;
	group?: string[];
	multiple?: boolean;
	listItemMouseClick?: (e: MouseEvent<HTMLElement>, value: any, selected:boolean, groupName: string) => void;
	listItemKeyClick?: (e:KeyboardEvent<HTMLElement>, index: number, v: any, selected: boolean, groupName: string) => void;
	optGroups?: boolean;
	selectid?: string;
	levelIDs?: string[];
	parent?: string;
	groupName?: string;
}

export const Option = (props:PropsWithChildren<TProps>) => {
	const {
		value,
		children,
		index,
		selected,
		group,
		multiple,
		listItemMouseClick,
		listItemKeyClick,
		selectid,
		parent,
		groupName
	} = props;
	const context = useContext(SelectContext);
	const isSelected = () => {
		if (Array.isArray(selected)) {
			return selected.indexOf(value) >= 0;
		} else if (group) {
			if (Array.isArray(group)) {
				return selected === value && group[0] === groupName;
			}
			return selected === value && group === groupName;
		} else {
			return selected === value;
		}
	}

	const showOpts = () => {
		if (!context) return true;
		if (!context?.hasNested) return true;
		if (parent === '' && context.prevLevel && context.prevLevel.length === 0) return true;
		return parent === (context?.prevLevel && context.prevLevel[context.prevLevel?.length - 1]?.id);
	}
	showOpts();
	return (
		<>
			{showOpts() && <li
				role={'option'}
				aria-selected={isSelected()}
				tabIndex={0}
				onClick={(e: MouseEvent<HTMLElement>) => listItemMouseClick?.(e, value, isSelected(), groupName ?? '')}
				onKeyUp={(e:KeyboardEvent<HTMLElement>) => listItemKeyClick?.(e, index || 0, value, isSelected(), groupName ?? '') }
				className={`${styles.option} ${isSelected() ? styles.selected : ''}`}
			>
				<div data-selectid={selectid} className={`${styles.row} ${isSelected() ? styles.selected : ''}`}>
					{multiple && isSelected() && <CheckBoxIcon data-selectid={selectid} style={{ color: 'var(--brand-orange-default)' }} />}
					{multiple && !isSelected() && <CheckBoxOutlineBlankIcon data-selectid={selectid} />}
					<Spacer size={10} dir={SPACER_DIRECTION.VERTICAL} />
					<div className={styles.flexFill}>
						{children}
					</div>
					{!multiple && isSelected() && <DoneOutlinedIcon data-selectid={selectid} style={{ color: 'var(--brand-orange-default)', fontSize: '18px' }} />}
				</div>
			</li>}
		</>
	);
}

type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const ShipperIcon = (props:IconProps) => {
	const {
		width = 40,
		height = 40,
		color = 'var(--neutral-07)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 19 20'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d='M18.25 14.5C18.25 14.88 18.04 15.21 17.72 15.38L9.82 19.82C9.66 19.94 9.46 20 9.25 20C9.04 20 8.84 19.94 8.68 19.82L0.78 15.38C0.46 15.21 0.25 14.88 0.25 14.5V5.5C0.25 5.12 0.46 4.79 0.78 4.62L8.68 0.18C8.84 0.0600001 9.04 0 9.25 0C9.46 0 9.66 0.0600001 9.82 0.18L17.72 4.62C18.04 4.79 18.25 5.12 18.25 5.5V14.5ZM9.25 2.15L7.36 3.22L13.25 6.61L15.21 5.5L9.25 2.15ZM3.29 5.5L9.25 8.85L11.21 7.75L5.33 4.35L3.29 5.5ZM2.25 13.91L8.25 17.29V10.58L2.25 7.21V13.91ZM16.25 13.91V7.21L10.25 10.58V17.29L16.25 13.91Z' fill={color} />
		</svg>
	);
};

import axios from 'axios';

export const getSearchRegion = () => {
	let allRegions: any[] = [];
	let regionUpdatedAt = 0;
	const THREE_MINUTES = 3 * 60 * 1000;
	const searchRegion = async (searchText: string = '') => {
		if (allRegions.length === 0 || regionUpdatedAt + THREE_MINUTES < new Date().getTime()) {
			const allRegionsResp = await getAllRegions('');
			allRegions = allRegionsResp?.map((region: any) => ({ label: region.name, value: region.id }));
			regionUpdatedAt = new Date().getTime();
		}
		const localRegions = allRegions.filter((region) => {
			const lowerCaseSearchText = searchText.toLowerCase();
			return (
				region.label.toLowerCase().includes(lowerCaseSearchText) ||
                region.description?.toLowerCase().includes(lowerCaseSearchText)
			);
		});
		if (localRegions?.length) {
			return localRegions;
		} else {
			let regions: any[] = [];
			const results = await getAllRegions(searchText);
			regions = results?.map((region: any) => ({ label: region.name, value: region.id }));
			return regions;
		}
	};

	return searchRegion;
};

export const searchPostcode = async (searchText: string) => {
	if (searchText?.length <= 2) {
		return Promise.resolve([]);
	}

	const resp = await axios.get(`/api/carrier-rate-cards/postcode/search?postcode=${searchText}`).catch(() => null);
	return (resp?.data || []).map((postcode: any) => ({
		value: postcode.id,
		label: `${postcode.suburb} ${postcode.postcode}`,
	}));
};

const getAllRegions = async (query: string) => {
	const resp = await axios.get(`/api/carrier-rate-cards/regions${query ? `?query=${query}` : ''}`).catch(() => null);
	return resp?.data || [];
};

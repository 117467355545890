import styles from './FilterPanel.module.scss';
import { H2, H3, Spacer } from '@components';
import { PanelState } from '../Table'
import CloseIcon from '@mui/icons-material/Close';

type MatrixFilterPanelProps = {
    matrix: any;
    openPanel?: (panelName: 'filter' | 'settings' | 'matrix' | null) => void;
    closePanel?: () => void;
    panelState?: PanelState;
}

const MatrixFilterPanel = (props:MatrixFilterPanelProps) => {
	const {
		closePanel,
		matrix
	} = props;
	const handleClosePanel = () => {
		closePanel?.();
	}

	/*
	 *	NEED TO SET AND GET USER EMAIL ADDRESS ON LOGIN!!
	 */
	const isOwnMatrix = (matrix && (matrix.email === ''));
	return (
		<div className={styles.container}>
			<div className={styles.padded}>
				<button className={styles.closeBtn} onClick={() => handleClosePanel()} aria-label={'Close Panel'}><CloseIcon /></button>
				<H2 text={'Filter'} />
				<Spacer size={20} />
				<H3 text={matrix?.name} />
				<Spacer size={20} />
				<ul className={styles.listStyle}>
					{matrix && matrix.filters && matrix?.filters.map((filter: any) => {
						return <li>{filter.name}</li>;
					})}
					{isOwnMatrix && (<li>{'Assign To Shipments'}</li>)}
				</ul>
			</div>
		</div>
	);
}

export default MatrixFilterPanel;

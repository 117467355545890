import { useState, useEffect } from 'react';
import { Select, Datepicker, Text, Spacer } from '@components/index';

type FilterProp = {
    meta: any;
	header: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterDate = (props:FilterProp) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const [dateSelect, setDateSelect] = useState('');
	const [inputDate, setInputDate] = useState('');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const updateDateSelect = (v:any) => {
		setDateSelect(v);
		const newFilterData = { ...filterData };
		if (!newFilterData.value && !newFilterData?.value?.dateSelect) {
			newFilterData.value = {
				dateSelect: v,
				inputDate: '',
				dateFrom: '',
				dateTo: ''
			}
		} else {
			newFilterData.value.dateSelect = v;
		}
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'date';
		onChange(newFilterData);
	}
	const updateInputDate = (e:any, field:string) => {
		const v = e.target.value;
		if (field === 'Specific Date') {
			setInputDate(v);
		} else if (field === 'from') {
			setFromDate(v);
		} else if (field === 'to') {
			setToDate(v);
		}
		const newFilterData = { ...filterData };
		if (field === 'specific') {
			if (!newFilterData.value && !newFilterData?.value?.inputDate) {
				newFilterData.value = {
					dateSelect: 'Specific Date',
					inputDate: v,
					dateFrom: '',
					dateTo: ''
				}
			} else {
				newFilterData.value.inputDate = v;
			}
		} else if (field === 'from') {
			if (!newFilterData.value && !newFilterData?.value?.inputDate) {
				newFilterData.value = {
					dateSelect: 'Specific Date',
					inputDate: '',
					dateFrom: v,
					dateTo: ''
				}
			} else {
				newFilterData.value.dateFrom = v;
			}
		} else if (field === 'to') {
			if (!newFilterData.value && !newFilterData?.value?.inputDate) {
				newFilterData.value = {
					dateSelect: 'Specific Date',
					inputDate: '',
					dateFrom: '',
					dateTo: v
				}
			} else {
				newFilterData.value.dateTo = v;
			}
		}
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'date';
		onChange(newFilterData);
	}
	const dateOpts:any[] = [
		{
			label: 'Today',
			value: 'Today'
		},
		{
			label: 'Tomorrow',
			value: 'Tomorrow'
		},
		{
			label: 'Yesterday',
			value: 'Yesterday'
		},
		{
			label: 'This Week',
			value: 'This Week'
		},
		{
			label: 'Last 7 Days',
			value: 'Last 7 Days'
		},
		{
			label: 'Next 7 Days',
			value: 'Next 7 Days'
		},
		{
			label: 'This Month',
			value: 'This Month'
		},
		{
			label: 'Last 30 Days',
			value: 'Last 30 Days'
		},
		{
			label: 'This Year',
			value: 'This Year'
		},
		{
			label: 'Last Year',
			value: 'Last Year'
		},
		{
			label: 'Date Range',
			value: 'Date Range'
		},
		{
			label: 'Specific Date',
			value: 'Specific Date'
		}
	];
	useEffect(() => {
		setDateSelect(filterData?.value?.dateSelect || '');
		setInputDate(filterData?.value?.inputDate || '');
	}, [filterData]);
	return (
		<>
			<Select name={'Filter-Option-Select-Date'} value={dateSelect} placeholder={'Please select'} onChange={(v) => updateDateSelect(v)}>
				{dateOpts.map((option, i) => {
					return <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
				})}
			</Select>
			{dateSelect === 'Specific Date' && <div style={{ marginTop: '5px' }}>
				<Datepicker name={'Filter-Option-Specific-Date'} value={inputDate} onChange={(v) => updateInputDate(v, 'specific')} />
			</div>}
			{dateSelect === 'Date Range' && <div style={{ marginTop: '5px' }}>
				<Text type={'label'} text={'From'} />
				<Spacer size={5} />
				<Datepicker name={'Filter-Option-Date-Range-Start'} value={fromDate} onChange={(v) => updateInputDate(v, 'from')} keepErrorSpace={false} />
				<Spacer size={5} />
				<Text type={'label'} text={'To'} />
				<Spacer size={5} />
				<Datepicker name={'Filter-Option-Date-Range-End'} value={toDate} onChange={(v) => updateInputDate(v, 'to')} keepErrorSpace={false} />
			</div>}
		</>
	);
}

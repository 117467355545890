type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const PODRejectedIcon = (props:IconProps) => {
	const {
		width = 18,
		height = 18,
		color = 'var(--func-red-dark)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 18 18'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d="M4.5 16.5C4.0875 16.5 3.73438 16.3531 3.44063 16.0594C3.14687 15.7656 3 15.4125 3 15V3C3 2.5875 3.14687 2.23438 3.44063 1.94063C3.73438 1.64687 4.0875 1.5 4.5 1.5H10.5L15 6V9.2625C14.7625 9.175 14.5188 9.10938 14.2688 9.06563C14.0188 9.02188 13.7625 9 13.5 9V6.75H9.75V3H4.5V15H9.2625C9.3625 15.2875 9.4875 15.5563 9.6375 15.8063C9.7875 16.0563 9.9625 16.2875 10.1625 16.5H4.5ZM11.925 16.125L10.875 15.075L12.45 13.5L10.875 11.925L11.925 10.875L13.5 12.45L15.075 10.875L16.125 11.925L14.5688 13.5L16.125 15.075L15.075 16.125L13.5 14.5688L11.925 16.125Z" fill={color} />
		</svg>
	);
};
import { useState, useEffect, useRef, ReactNode } from 'react';
import styles from './Tabs.module.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { kebabCase } from 'lodash';
import { TObject } from '@types';

type TabProps = {
	tabTitle: string;
	TabContent: ReactNode;
	id?: number;
	group?: string;
	disabled?: boolean;
	padding?: boolean;
}

type TabsProps = {
	tabs: TabProps[];
	initialTab?: string | number;
	minimizeInactiveGroups?: boolean;
	onSelect?: (e: number) => void;
	selectedTab?: number;
	tabWidth?: number | string;
	showContentBorder?: boolean;
	borderRadius?: string;
}

type GroupedTabs = {
	group: string;
	tabs: TabProps[]
}

export const Tabs = (props: TabsProps) => {
	const {
		tabs = [],
		initialTab = '',
		minimizeInactiveGroups = false,
		onSelect,
		selectedTab,
		tabWidth,
		showContentBorder,
		borderRadius
	} = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const tabsRef = useRef<HTMLDivElement>(null);
	const currentTabPos = useRef(0);
	const [groups, setGroups] = useState<GroupedTabs[]>([]);
	const [activeTab, setActiveTab] = useState(selectedTab ? selectedTab : 0);
	const [hovered, setHovered] = useState(-1);
	const [isLeftArrowDisabled, setLeftArrowDisabled] = useState(true);
	const [isRightArrowDisabled, setRightArrowDisabled] = useState(false);
	const isMinimized = (group: GroupedTabs, groupIndex: number) => {
		const hasActive = group.tabs.findIndex((g) => {
			return g.id === activeTab;
		});
		if (hasActive < 0 && minimizeInactiveGroups && hovered !== groupIndex) {
			return styles.minimizeGroup;
		}
		return '';
	}
	const getWidth = () => {
		let width = 0;
		let oWidth = 0;
		if (containerRef.current) oWidth = containerRef.current.offsetWidth;
		if (typeof tabWidth === 'string') {
			if (tabWidth.indexOf('%') > -1) {
				const pc = parseInt(tabWidth);
				width = Math.round(oWidth / 100 * pc);
			} else {
				width = parseInt(tabWidth);
			}
		} else if (typeof tabWidth === 'number') {
			width = tabWidth;
		}
		return width;
	}
	const calcWidth = (tab: number) => {
		let totalWidth = 0;
		// console.log(tabsRef.current);
		if (tabsRef.current && tabsRef?.current?.children && tabsRef?.current?.children[0]?.classList.contains(styles.group)) {
			const groups = tabsRef.current.children;
			let currentTab = 0;
			for (let g = 0, l = groups.length; g < l; ++g) {
				if (tab !== -1 && currentTab === tab) break;
				for (let i = 0, len = groups[g].children.length; i < len; ++i) {
					totalWidth += (groups[g]?.children[i] as HTMLElement)?.offsetWidth ?? 0;
					currentTab += 1;
					if (tab !== -1 && currentTab === tab) break;
				}
			}
		} else if (tabsRef.current && tabsRef?.current?.children) {
			for (let i = 0, l = (tab === -1 ? tabsRef?.current?.children.length : tab); i < l; ++i) {
				totalWidth += (tabsRef?.current?.children[i] as HTMLElement)?.offsetWidth ?? 0;
			}
		}
		return totalWidth;
	}
	const showButtons = () => {
		if (containerRef.current) {
			const totalWidth = calcWidth(-1);
			if (totalWidth > containerRef.current.offsetWidth) {
				return true;
			}
		}
		return false;
	}
	const setStyles = (tabIndex: number | null = null, groupIndex: number | null = null) => {
		const styles: TObject = {}
		if (tabWidth) {
			if (typeof tabWidth === 'string' && tabWidth === 'fill') {
				styles.flex = '1 0 content';
			} else {
				styles.width = `${getWidth()}px`;
				styles.flex = 'initial';
			}
		} else {
			styles.flexShrink = 0;
			styles.flexBasis = 'content';
		}
		if (borderRadius && !showButtons() && tabIndex !== null) {
			if (tabIndex === 0) {
				styles.borderTopLeftRadius = borderRadius;
			}
			const gIndex = groupIndex ?? 0;
			if (tabIndex === groups[gIndex].tabs.length - 1) {
				styles.borderTopRightRadius = borderRadius;
			}
		}
		return styles;
	}
	const setContentStyle = () => {
		const styles: TObject = {};
		if (borderRadius) {
			styles.borderBottomLeftRadius = borderRadius;
			styles.borderBottomRightRadius = borderRadius;
		}
		return styles;
	}
	const scrollTabs = (bool: boolean | number) => {
		if (tabsRef && tabsRef.current) {
			let calcPos = 0;
			if (typeof bool === 'boolean') {
				calcPos = bool ? calcWidth(currentTabPos.current + 1) : calcWidth(currentTabPos.current - 1);
				currentTabPos.current = bool ? currentTabPos.current + 1 : currentTabPos.current - 1;
			} else {
				calcPos = calcWidth(bool);
				currentTabPos.current = bool;
			}
			const newPos = calcPos < 0 ? 0 : calcPos > calcWidth(-1) ? calcWidth(-1) : calcPos;
			tabsRef.current.scrollTo({
				left: newPos,
				behavior: 'smooth'
			});
			setLeftArrowDisabled(newPos <= 0);
			setRightArrowDisabled(calcWidth(-1) > 0 ? calcWidth(-1) - newPos <= tabsRef.current.offsetWidth : false);
		}
	}
	const setBtnRadius = (dir: 'left' | 'right') => {
		const styles: { [key: string]: string } = {};
		if (borderRadius) {
			if (dir === 'left') styles['borderTopLeftRadius'] = borderRadius;
			if (dir === 'right') styles['borderTopRightRadius'] = borderRadius;
		}
		return styles;
	}
	const handleTabSelect = (tabId: number) => {
		setActiveTab(tabId || 0);
		if (onSelect) onSelect(tabId || 0);// Additional logic if needed
	};
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const sorted: GroupedTabs[] = [];
			for (let i = 0, l = tabs.length; i < l; ++i) {
				const newTab = { ...tabs[i], id: i }
				const groupName = tabs[i].group ?? 'default';
				if (sorted.length === 0) {
					sorted.push({
						group: groupName,
						tabs: [newTab]
					});
				} else {
					const index = sorted.findIndex((tab: GroupedTabs) => {
						return tab.group === groupName;
					});
					if (index < 0) {
						sorted.push({
							group: groupName,
							tabs: [newTab]
						});
					} else {
						sorted[index].tabs.push(newTab);
					}
				}
			}
			setGroups(sorted);
		}
		return (() => {
			mounted = false;
		});
	}, [tabs]);
	useEffect(() => {
		if (initialTab !== '') {
			if (typeof initialTab === 'number') {
				let startTab = 0;
				if (initialTab <= tabs.length - 1) {
					startTab = initialTab;
				}
				setActiveTab(startTab);
			} else {
				const tabIndex = tabs.findIndex((tab) => {
					return tab.tabTitle === initialTab;
				});
				if (tabIndex >= 0) {
					setActiveTab(tabIndex);
				}
			}
		}
	}, [initialTab]);
	useEffect(() => {
		scrollTabs(activeTab);
	}, [activeTab]);

	useEffect(()=>{
		if (typeof selectedTab === 'number') {
			handleTabSelect(selectedTab || 0)
		}
	}, [selectedTab]);

	return (
		<div className={styles.container} ref={containerRef}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				{showButtons() && <button data-testid={'@Tabs-ScrollLeftBtn'} className={styles.tabButton} style={setBtnRadius('left')} onClick={() => { !isLeftArrowDisabled && scrollTabs(false) }} aria-label={'Scroll to First Tab'}><ArrowBackIosIcon className={`${styles.arrowIcons} ${isLeftArrowDisabled ? styles.arrowDisabled : styles.arrowEnable}`} /></button>}
				<div className={`${styles.scrollTabs} ${styles.tabs}`} ref={tabsRef}>
					{groups.length > 1 && groups.map((group, i) => {
						return <div data-testid={`@Tabs-Group${i}`} key={i} onMouseEnter={() => setHovered(i)} onMouseLeave={() => setHovered(-1)} className={`${styles.group} ${isMinimized(group, i)}`}>
							{group.tabs.map((tab, dex) => {
								return <div
									data-testid={`@Tabs-Tab-${kebabCase(tab.tabTitle)}`}
									key={dex}
									onClick={tab.disabled ? undefined : () => handleTabSelect(tab.id ?? 0)}
									className={`${styles.tab} ${activeTab === tab.id ? styles.activeTab : ''} ${tab.disabled ? styles.disabledTab : ''} ${tab.padding ? styles.paddingTab : ''}`}
									style={setStyles(dex, i)}
								>
									{tab.tabTitle}
								</div>
							})}
						</div>
					})}
					{groups.length === 1 && groups[0].tabs.map((tab, dex) => {
						return <div
							data-testid={`@Tabs-Tab-${kebabCase(tab.tabTitle)}`}
							key={dex}
							onClick={tab.disabled ? undefined : () => handleTabSelect(tab.id ?? 0)}
							className={`${styles.tab} ${activeTab === tab.id ? styles.activeTab : ''} ${tab.disabled ? styles.disabledTab : ''} ${tab.padding ? styles.paddingTab : ''}`}
							style={setStyles(dex)}
						>
							{tab.tabTitle}
						</div>
					})}
				</div>
				{showButtons() && <button data-testid={'@Tabs-ScrollRightBtn'} className={styles.tabButton} style={setBtnRadius('right')} onClick={() => { !isRightArrowDisabled && scrollTabs(true) }} aria-label={'Scroll to Last Tab'}><ArrowForwardIosIcon className={`${styles.arrowIcons} ${isRightArrowDisabled ? styles.arrowDisabled : styles.arrowEnable}`} /></button>}
			</div>
			<div data-testid={`@Tabs-Content`} className={`${styles.content} ${showContentBorder ? styles.contentBorder : ''}`} style={setContentStyle()}>
				{tabs.map((tab, i) => {
					return <div key={i} className={`${styles.tabContent} ${activeTab === i ? styles.activeContent : ''}`}>
						{activeTab === i ? tab.TabContent : ''}
					</div>
				})}
			</div>
		</div>
	);
}

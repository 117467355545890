export const formatNumber = (
	value = 0,
	{ locale = 'en-AU', maximumFractionDigits = 2, suffix = '', returnEmptyForZero = false } = {}
) => {
	if (returnEmptyForZero && !value) {
		return '';
	}
	const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits });
	const formattedNumber = formatter.format(value);
	return `${formattedNumber}${suffix}`;
};
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { navigator } from '@types';

const financeNav = () => {
	const root = `/finance`;
	return [
		{
			label: 'Shipper Billing',
			icon: (color: string) => <ReceiptOutlinedIcon style={{ color: color }} />,
			link: `/admin/finance/shipper/billing`,
			enabled: true,
			domain: 'eos',
			activeURL: 'shipper/billing'
		},
		{
			label: 'Carrier Billing',
			icon: (color: string) => <ReceiptLongOutlinedIcon style={{ color: color }} />,
			link: `/admin/finance/carrier/billing`,
			enabled: true,
			domain: 'eos',
			activeURL: 'carrier/billing'
		},
		{
			label: 'Issues & Claims',
			icon: (color: string) => <AssignmentLateOutlinedIcon style={{ color: color }} />,
			link: `/admin/finance/claimLines`,
			enabled: true,
			domain: 'eos',
			activeURL: 'claimLines'
		},
		{
			label: 'Invoice Report',
			icon: (color: string) => <VerifiedUserOutlinedIcon style={{ color: color }} />,
			link: `/admin/finance/invoice-audit`,
			enabled: true,
			domain: 'eos',
			activeURL: 'invoice-audit'
		},
		{
			label: 'Invoice Management',
			icon: (color: string) => <CurrencyExchangeIcon style={{ color: color }} />,
			link: `${root}/invoice_management`,
			enabled: true,
			domain: 'spa',
			activeURL: 'invoice_management'
		},
	] as navigator[];
}

export default financeNav;

import { atom } from 'recoil';
import {
	UnallocatedRows,
	TStop,
	TTruck,
	XDockForm,
	TSuperDock
} from '@types';

/* eslint-disable */
const keys = {
	SHIPMENTS_FOR_SD:		'@Ofload/Shipments_For_Super_Dock',
	SUPERDOCKS:				'@Ofload/SuperDocks',
	ACTIVE_SUPERDOCK:		'@Ofload/SuperDock',
	SD_TYPE:				'@Ofload/Super_Dock_Type',
	SD_MOVED_CARGO:			'@Ofload/Super_Dock_Moved_Cargo',
	SD_WORKING_SHIPMENTS:	'@Ofload/Super_Dock_Working_Shipments',
	SD_Trucks:				'@Ofload/Super_Dock_Trucks',
	SD_XDock:				'@Ofload/Super_Dock_Cross_Dock',
	SD_XDock_Open:			'@Ofload/Super_Dock_Cross_Dock_Open'
}
/* eslint-enable */

export const SuperDocks = atom<TSuperDock[]>({
	key: keys.SUPERDOCKS,
	default: []
});

export const ActiveSuperDock = atom<TSuperDock | null>({
	key: keys.ACTIVE_SUPERDOCK,
	default: null
});

export const ShipmentsForSD = atom<UnallocatedRows[]>({
	key: keys.SHIPMENTS_FOR_SD,
	default: []
});

export const SDType = atom<'Traditional' | 'Bulk' | null>({
	key: keys.SD_TYPE,
	default: null
});

export const SDWorkingShipments = atom<TStop[] | null>({
	key: keys.SD_WORKING_SHIPMENTS,
	default: null
});

export const SDTrucks = atom<TTruck[]>({
	key: keys.SD_Trucks,
	default: []
});

export const SDXDock = atom<XDockForm>({
	key: keys.SD_XDock,
	default: {
		truckID: '',
		onTruck: false,
		address: '',
		contactName: '',
		contactPhone: '',
		businessName: '',
		contactEmail: '',
		locationOpeningTime: '',
		locationClosingTime: '',
		dropOffDate: '',
		dropOffTime: '',
		pickUpDate: '',
		pickUpTime: '',
		dropOffTimeSlot: 'NOT_REQUIRED',
		dropOffTimeSlotRef: '',
		pickUpTimeSlot: 'NOT_REQUIRED',
		pickUpTimeSlotRef: '',
		locality_type: '',
		formatted_address: '',
		gm_place_id: '',
		postcode: {
			postcode: '',
			suburb: '',
			state: ''
		},
		truckType: 0,
		truckSize: 0,
		dropOffDriverInstruction: '',
		pickUpDriverInstruction: ''
	}
});

export const sdXDockOpen = atom<boolean>({
	key: keys.SD_XDock_Open,
	default: false
});

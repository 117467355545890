import { useState, useEffect } from 'react';
import styles from './Footer.module.scss';
import { Text, Select, InputField, Spacer } from '@components/index';
import DoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { INPUT_ICON_POS, SPACER_DIRECTION } from '@types';

type FooterProps = {
    pagination?: boolean;
    showRowCount?: boolean;
    canChangeRowCount?: boolean;
    currentPage?: number;
    rowsPerPage?: number;
    totalDocs?: number;
    setPageNumber?: (p:number) => void;
    setRowCount?: (c:number) => void;
    rowsPerPageOpts?: number[];
    continuousPagination?: boolean;
}

const Footer = (props:FooterProps) => {
	const {
		pagination = true,
		showRowCount = true,
		canChangeRowCount = true,
		currentPage = 1,
		rowsPerPage = 25,
		totalDocs = 0,
		setPageNumber,
		setRowCount,
		rowsPerPageOpts,
		continuousPagination= false,
	} = props;
	const [activePage, setActivePage] = useState(currentPage);
	const [rowCountPerPage, setRowCountPerPage] = useState(rowsPerPage);
	const [totalPages, setTotalPages] = useState(1);
	const [goto, setGoto] = useState('');
	const [pageRowCountOpts, setRowCountOpts] = useState([
		{
			value: 25,
			label: '25'
		}, {
			value: 50,
			label: '50'
		}, {
			value: 100,
			label: '100'
		}
	]);
	const debounce = (func:any, timeout = 500) => {
		let timer:any;
		return (...args:any) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		}
	}
	const goToPage = (p:number) => {
		setActivePage(p);
		if (setPageNumber) setPageNumber(p);
	}
	const goToPageInput = (n:string) => {
		const newVal = /^\d*?\d*$/.test(n);
		if (newVal) {
			let newCount = parseInt(n);
			setGoto(n);
			const trigger = debounce(() => {
				if (newCount > totalPages) {
					newCount = totalPages;
				}
				goToPage(newCount);
				setGoto('');
			});
			if (n !== '') {
				trigger();
			}
		}
	}
	const calcRowNumsOnPage = () => {
		const currentFirstRow = rowCountPerPage * activePage - rowCountPerPage + 1;
		const currentLastRow = currentFirstRow + rowCountPerPage - 1; // Optimistic calculation

		if (continuousPagination) {
			return `rows ${currentFirstRow}-${currentLastRow} of many`;
		} else {
			const countOnPage = activePage === totalPages ? totalDocs : currentLastRow;
			return `rows ${currentFirstRow}-${countOnPage} of ${totalDocs}`;
		}
	};

	const SetRowCount = (c:number) => {
		setRowCountPerPage(c);
		if (setRowCount) setRowCount(c);
	}
	const generatePageNums = () => {

		if (continuousPagination) {
			const pages = [];
			const range = 3;

			for (let i = activePage - range; i <= activePage + range; i++) {
				if (i > 0) pages.push(i); // Ensuring we don't push negative page numbers.
			}

			pages.push(0); // Add a spacer.
			pages.push(activePage + range + 1); // Add an optimistic "next" page.

			return pages.map((p, i) => {
				if (p === 0) {
					return <div key={i} className={styles.paginatorSpacer}>...</div>;
				} else {
					return (
						<button
							onClick={() => goToPage(p)}
							key={i}
							className={`${styles.paginatorButton} ${p === activePage ? styles.activePage : styles.inActivePage}`}
							aria-label={'Go To Page'}
						>
							{p}
						</button>
					);
				}
			});
		}

		if (totalPages <= 7) {
			return new Array(totalPages).fill(null).map((_, i) => {
				return <button onClick={() => goToPage(i + 1)} key={i} className={`${styles.paginatorButton} ${i + 1 === activePage ? styles.activePage : styles.inActivePage}`} aria-label={`Go to page ${i + 1}`}>{i + 1}</button>
			});
		} else {
			const lastPage = Math.ceil(totalDocs / rowCountPerPage);
			const pages = [1];
			if (activePage <= 3) {
				pages.push(2);
				pages.push(3);
				pages.push(4);
				pages.push(5);
				pages.push(0);
			} else {
				pages.push(0);
			}
			if (activePage > 3 && activePage < lastPage - 3) {
				pages.push(activePage - 1);
				pages.push(activePage);
				pages.push(activePage + 1);
				if (activePage < lastPage - 2) {
					pages.push(0);
				}
			}
			if (activePage === lastPage - 3) {
				pages.push(activePage - 1);
				pages.push(activePage);
				pages.push(activePage + 1);
				pages.push(0);
			}
			if (activePage === lastPage - 2) {
				pages.push(activePage - 2);
				pages.push(activePage - 1);
				pages.push(activePage);
				pages.push(activePage + 1);
			}
			if (activePage === lastPage - 1) {
				pages.push(activePage - 3);
				pages.push(activePage - 2);
				pages.push(activePage - 1);
				pages.push(activePage);
			}
			if (activePage === lastPage) {
				pages.push(activePage - 4);
				pages.push(activePage - 3);
				pages.push(activePage - 2);
				pages.push(activePage - 1);
			}
			pages.push(lastPage);
			return pages.map((p, i) => {
				if (p === 0) {
					return <div key={i} className={styles.paginatorSpacer}>...</div>
				} else {
					return <button onClick={() => goToPage(p)} key={i} className={`${styles.paginatorButton} ${p === activePage ? styles.activePage : styles.inActivePage}`} aria-label={`Go to page ${p}`}>{p}</button>
				}
			});
		}
	}

	useEffect(() => {
		setRowCountPerPage(rowsPerPage)
	}, [rowsPerPage])
	useEffect(() => {
		setActivePage(currentPage);
	}, [currentPage]);
	useEffect(() => {
		const totalPageCount = Math.ceil(totalDocs / rowCountPerPage);
		setTotalPages(totalPageCount);
	}, [rowCountPerPage, totalDocs]);
	useEffect(() => {
		if (rowsPerPageOpts) {
			const newPageOpts = rowsPerPageOpts.map((opt) => {
				return {
					value: opt,
					label: `${opt}`
				}
			});
			setRowCountOpts(newPageOpts);
		}
	}, [rowsPerPageOpts]);
	return (
		<footer className={styles.footer}>
			<div className={styles.footerSpacer} />
			{canChangeRowCount && <>
				<Text text={'Rows per page:'} />
				<div style={{ width: '70px', marginLeft: '10px' }}>
					<Select name={'Select Page Row Count'} value={rowCountPerPage} onChange={(v) => SetRowCount(v)} displayThin>
						{pageRowCountOpts.map((option, i) => {
							return <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
						})}
					</Select>
				</div>
			</>}
			{showRowCount && <>
				<Spacer dir={SPACER_DIRECTION.VERTICAL} size={40} />
				<Text text={calcRowNumsOnPage()} />
			</>}
			{pagination && <div className={styles.paginationContainer}>
				<button onClick={() => goToPage(1)} disabled={activePage === 1 } aria-label={'go to page 1'}>
					<DoubleArrowLeftIcon className={activePage === 1 ? styles.inActivePaginator : styles.activePaginator} />
				</button>
				<button onClick={() => goToPage(activePage - 1)} disabled={activePage === 1 } aria-label={`Go to page ${activePage - 1}`}>
					<ArrowLeftIcon className={activePage === 1 ? styles.inActivePaginator : styles.activePaginator} />
				</button>
				{generatePageNums()}
				<button onClick={() => goToPage(activePage + 1)} disabled={activePage === totalPages } aria-label={`Go to page ${activePage + 1}`}>
					<ArrowRightIcon className={activePage === totalPages ? styles.inActivePaginator : styles.activePaginator} />
				</button>
				<button onClick={() => goToPage(totalPages)} disabled={activePage === totalPages } aria-label={`Go to page ${totalPages}`}>
					<DoubleArrowRightIcon className={activePage === totalPages ? styles.inActivePaginator : styles.activePaginator} />
				</button>
				<div style={{ width: '70px', marginLeft: '10px' }}>
					<InputField name={'Go To Page'} placeholder={'Go to'} value={goto} onChange={(e) => { goToPageInput(e.target.value) }} displayThin Icon={SearchIcon} iconPos={INPUT_ICON_POS.RIGHT} keepErrorSpace={false} />
				</div>
			</div>}
		</footer>
	);
}

export default Footer;

import { useGetAPI } from './useAPI';

export const useGetOptedInFreightSafe = () => {
  const { isLoading, data, error, callAPI, clearData, clearError } = useGetAPI({
    endpoint: '/freight-safe/list?{1}',
    domain: 'Inertia',
  });

  const triggerAPI = (query: string = '') => {
    callAPI({ routes: [query] });
  };

  return {
    isLoading: isLoading,
    data: data as any,
    error: error,
    callAPI: triggerAPI,
	clearData, 
	clearError
  };
};

export const useGetCovered = () => {
  const { isLoading, data, error, callAPI } = useGetAPI({
    endpoint: '/freight-safe/covered?{1}',
    domain: 'Inertia',
  });

  const triggerAPI = (query: string = '') => {
    callAPI({ routes: [query] });
  };

  return {
    isLoading: isLoading,
    data: data as any,
    error: error,
    callAPI: triggerAPI,
  };
};

import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

interface ReactQueryProviderProps {
    children: ReactNode;
}

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error: any) => {
                if (failureCount > MAX_RETRIES) {
                    return false;
                }

                if (
                    error?.response?.status &&
                    //@ts-ignore
                    HTTP_STATUS_TO_NOT_RETRY.includes(error.response.status)
                ) {
                    return false;
                }

                return true;
            },
            retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 200 : 200, 3 * 1000),
            staleTime: 0,
			gcTime: 0,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        },
    },
});

function ReactQueryProvider({ children }: ReactQueryProviderProps) {
    return <QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>;
}

export default ReactQueryProvider;

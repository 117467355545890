type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const OverviewIcon = (props:IconProps) => {
	const {
		width = 24,
		height = 24,
		color = 'var(--neutral-07)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 24 24'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d={'M20 20.65L20.7 19.95L18.825 18.075V15.275H17.825V18.475L20 20.65ZM4.5 21C4.0875 21 3.73438 20.8531 3.44063 20.5594C3.14688 20.2656 3 19.9125 3 19.5V4.5C3 4.0875 3.14688 3.73438 3.44063 3.44063C3.73438 3.14688 4.0875 3 4.5 3H19.5C19.9125 3 20.2656 3.14688 20.5594 3.44063C20.8531 3.73438 21 4.0875 21 4.5V12.725C20.7667 12.5917 20.5208 12.4833 20.2625 12.4C20.0042 12.3167 19.75 12.25 19.5 12.2V4.5H4.5V19.5H12.225C12.2917 19.7667 12.3669 20.0264 12.4506 20.2793C12.5343 20.5322 12.6341 20.7724 12.75 21H4.5ZM4.5 18.325V19.5V4.5V12.2V12.1V18.325ZM7 17H12.275C12.3417 16.7333 12.4167 16.475 12.5 16.225C12.5833 15.975 12.6917 15.7333 12.825 15.5H7V17ZM7 12.75H15.6C15.8333 12.6333 16.0583 12.5375 16.275 12.4625C16.4917 12.3875 16.7333 12.3167 17 12.25V11.25H7V12.75ZM7 8.5H17V7H7V8.5ZM18.3125 22.975C17.0208 22.975 15.9167 22.5125 15 21.5875C14.0833 20.6625 13.625 19.5667 13.625 18.3C13.625 16.9928 14.0833 15.8784 14.9998 14.9571C15.9163 14.0357 17.0247 13.575 18.325 13.575C19.6083 13.575 20.7125 14.0357 21.6375 14.9571C22.5625 15.8784 23.025 16.9928 23.025 18.3C23.025 19.5667 22.5625 20.6625 21.6375 21.5875C20.7125 22.5125 19.6042 22.975 18.3125 22.975Z'} fill={color} />
		</svg>
	);
};

export const injectPendo = (apiKey: string) => {
	const pendoScriptID = 'PENDO-SCRIPT';
	(function (apiKey) {
		(function (p, e, n, d, o) {
			const pendoScript = document.getElementById(pendoScriptID);
			if (!pendoScript) {
				const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
				let w, x;
				// @ts-ignore
				o = p[d] = p[d] || {};
				// @ts-ignore
				o._q = o?._q || [];

				for (w = 0, x = v.length; w < x; ++w) {
					(function (m) {
						// @ts-ignore
						o[m] = o[m] || function () {
							// @ts-ignore
							o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); // eslint-disable-line
						};
					}(v[w]));
				}

				const y = e.createElement(n);
				// @ts-ignore
				y.async = !0;
				// @ts-ignore
				y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
				// @ts-ignore
				y.id = pendoScriptID;

				const [z] = e.getElementsByTagName(n);
				z.parentNode?.insertBefore(y, z);
			}
		}(window, document, 'script', 'pendo'));
	}(apiKey));
}

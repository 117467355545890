type IconProps = {
    size?: number;
    color?: string;
}

export const GavelIcon = (props:IconProps) => {
	const {
		size = 24,
		color = 'var(--func-red-dark)'
	} = props;
	return (
		<svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
			<path d='M4 21V19.5H16V21H4ZM9.55 15.7L4 10.15L5.75 8.35L11.35 13.9L9.55 15.7ZM15.9 9.35L10.35 3.75L12.15 2L17.7 7.55L15.9 9.35ZM20.95 20L7.55 6.6L8.6 5.55L22 18.95L20.95 20Z' fill={color} />
		</svg>
	);
}

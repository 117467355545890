import axios from 'axios';

const {
	protocol,
	hostname,
	port
} = window.location;


const refreshToken = async () => {
	try {
		const currentRefreshToken = localStorage.getItem('refreshToken');
		const response = await axios.post('/api/user/refresh', { refresh_token: currentRefreshToken, });
		const newAccessToken = response.data.accessToken;
		localStorage.setItem('jwt', newAccessToken);
		return newAccessToken;
	} catch (error) {
		console.error('Failed to refresh token:', error);
		localStorage.removeItem('jwt');
		localStorage.removeItem('refreshToken');
		const base = `${protocol}${port}//${hostname}`;
		if (window.location.pathname !== '/login') {
			window.location.href = `${base}`;
		}
	}
}

// const ApiClient = axios.create({ baseURL: import.meta.env.PROD ? import.meta.env.VITE_API_URL : 'http://localhost:3000' });
const ApiClient = axios.create();
let apiCache: {
	url: string;
	time: number;
}[] = [];

ApiClient.interceptors.request.use((config) => {
	const token = localStorage.getItem('jwt');
	if (token) {
		config.headers = config.headers || {};
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	let avoidCache = false;
	if (
		config.headers &&
		config.headers['X-AvoidCache'] &&
		config.headers['X-AvoidCache'] === 'avoid'
	) {
		avoidCache = true;
	}

	if (avoidCache) {
		return config;
	} else if (
		config.url &&
		!apiCache.some((cache) => cache.url === config.url)
	) {
		apiCache.push({
			url: config.url,
			time: new Date().getTime()
		});
		return config;
	}
	throw new axios.Cancel('Cache hit');
}, (error) => {
	if (error.code === 'ERR_NETWORK') {
		return {
			isError: true,
			status: -1,
			code: error.code,
			message: error.message
		}
	}
	return Promise.reject(error);
});

ApiClient.interceptors.response.use((response) => {
	if (response.config.url && apiCache.some((cache) => cache.url === response.config.url)) {
		apiCache = apiCache.filter((cache) => cache.url !== response.config.url);
	}
	const { data } = response;
	if (data.status && data.status === 302) {
		if (
			(data.error && data.error === 'Not Authenticated') ||
			(data.message && data.message === 'Not Authenticated')
		) {
			const base = `${protocol}//${hostname}`;
			if (window.location.pathname !== '/login') {
				window.location.href = `${base}`;
			}
		}
	}
	return response
}, async (error) => {
	const originalRequest = error.config;
	if (error.code && error.code === 'ERR_CANCELED') {
		return Promise.reject({
			isError: true,
			status: error.name,
			code: error.code,
			message: error.message,
			response: { data: { message: error.name } }
		});
	}
	if (error.response && error.response.config && error.response.config.url && apiCache.some((cache) => cache.url === error.response.config.url)) {
		apiCache = apiCache.filter((cache) => cache.url !== error.response.config.url);
	}
	// check that response is json response and has key error with value token_expired
	if (error && error.response && error.response.status && error.response.status === 401 &&
			error.response.data.error === 'JWT has expired' &&
			!originalRequest._retry) {

		originalRequest._retry = true;

		try {
			// Assuming `refreshToken()` is a function that refreshes your token
			const newToken = await refreshToken();

			ApiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
			originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
			return ApiClient(originalRequest);
		} catch (refreshError) {
			console.log(refreshError);
			localStorage.removeItem('jwt');
			localStorage.removeItem('refreshToken');
			if (window.location.pathname !== '/login') {
				window.location.href = `${protocol}//${hostname}`;
			}
		}
	}

	return Promise.reject({
		isError: true,
		status: error.response?.status ?? 'ERROR',
		code: error.code,
		message: error.message,
		response: error.response
	});
});

export default ApiClient;

import { useEffect, useState } from 'react';
import { Timepicker, Grid, Text } from '@components';
import styles from '../FilterOptions.module.scss';
import { BUTTON_ICON_POS } from '@types';

type FilterProp = {
	meta: any;
	header: any;
	filterData: any;
	onChange: (val:any) => void;
}

export const FilterTimeRange = (props:FilterProp) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const [timeFrom, setTimeFrom] = useState('');
	const [timeTo, setTimeTo] = useState('');

	const updateTime = (e:any, field:string) => {
		const v = typeof e.target.value === 'object' ?
			e.target.value.toLocaleTimeString('en-AU', {
				hour: '2-digit', minute: '2-digit', hour12: false
			}) :
			e.target.value;
		if (field === 'from') {
			setTimeFrom(v);
		} else if (field === 'to') {
			setTimeTo(v);
		}
		const newFilterData = { ...filterData };
		if (field === 'from') {
			if (!newFilterData.value) {
				newFilterData.value = {
					timeFrom: v,
					timeTo: ''
				}
			} else {
				newFilterData.value.timeFrom = v;
			}
		} else if (field === 'to') {
			if (!newFilterData.value) {
				newFilterData.value = {
					timeFrom: '',
					timeTo: v
				}
			} else {
				newFilterData.value.timeTo = v;
			}
		}
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'timerange';
		onChange(newFilterData);
	}
	useEffect(() => {
		setTimeTo(filterData?.value?.timeTo || '');
		setTimeFrom(filterData?.value?.timeFrom || '');
	}, [filterData]);
	return (
		<div className={styles.inputContainer}>
			<Grid columns={3} columnTemplate={'48% auto 48%'} gap={`2px`}>
				<Grid.Item>
					<div className={styles.filterTimePicker}>
						<Timepicker
							name={`timeRangeFrom`}
							value={timeFrom}
							onChange={(v: any) => updateTime(v, 'from')}
							placeholder={'HH:MM'}
							keepErrorSpace={false}
							iconPos={BUTTON_ICON_POS.RIGHT}
						/>
					</div>
				</Grid.Item>
				<Grid.Item justifySelf={'center'} alignSelf={'center'} placeSelf={'center'}>
					<Text text={'-'} />
				</Grid.Item>
				<Grid.Item>
					<div className={styles.filterTimePicker}>
						<Timepicker
							value={timeTo}
							onChange={(v: any) => updateTime(v, 'to')}
							name={`timeRangeTo`}
							placeholder={'HH:MM'}
							keepErrorSpace={false}
							iconPos={BUTTON_ICON_POS.RIGHT}
						/>
					</div>
				</Grid.Item>
			</Grid>
		</div>);
}

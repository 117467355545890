import { useState, useEffect } from 'react';
import { Select } from '@components/index';

type FilterProps = {
    meta: any;
	header?: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterCustomPartialExpired = (props:FilterProps) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const [selectVal, setSelectVal] = useState('');
	const [selectOptions, setSelectOptions] = useState<any[]>([]);
	const filterOptions = meta?.filterOptions?.options ?? [];
	const updateVal = (v:any) => {
		setSelectVal(v);
		const newFilterData = { ...filterData };
		newFilterData.value = v;
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'customPartialExpired';
		onChange(newFilterData);
	}
	const generateSelectOptions = (options:any[]) => {
		const opts = options.map((opt) => {
			return {
				...opt,
				value: opt,
				label: opt
			}
		});
		return opts;
	}
	useEffect(() => {
		setSelectOptions(generateSelectOptions(filterOptions));
	}, [meta]);
	useEffect(() => {
		if (filterData?.value) {
			setSelectVal(filterData.value || '');
		} else {
			setSelectVal('');
		}
	}, [filterData]);
	return (
		<Select name={'Filter-Option-Partial-Expired-Select'} value={selectVal} onChange={(v) => updateVal(v)}>
			{selectOptions.map((option, i) => {
				return <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
			})}
		</Select>
	);
}

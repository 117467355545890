import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState
} from 'react';
import { debounce } from 'lodash';

export const useLocalStorage = <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
	const [value, setValue] = useState<T>(() => {
		const val = window.localStorage.getItem(key);
		return val !== null
			? JSON.parse(val) as T
			: defaultValue;
	});

	// Limit invocations of stringify and setItem for performance
	const setItem = useCallback(
		debounce((value) => window.localStorage.setItem(key, JSON.stringify(value)), 200),
		[key],
	);

	useEffect(() => {
		setItem(value);
	}, [key, value]);
	return [value, setValue];
}

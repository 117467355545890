import React, { useState, useEffect, ReactElement } from 'react';
import styles from './SettingsPanel.module.scss';
import {
	Text, H2, H3, Button, Spacer, Switch, Select
} from '@components';
import CloseIcon from '@mui/icons-material/Close';
import {
	BUTTON_TYPES, BUTTON_SIZE, TABLE_ROW_DENSITY, SPACER_DIRECTION, TEXT_WEIGHT
} from '@types';
import CompactIcon from '@mui/icons-material/DensitySmall';
import RegularIcon from '@mui/icons-material/DensityMedium';
import ExpandedIcon from '@mui/icons-material/DensityLarge';
import AddIcon from '@mui/icons-material/Add';
import DragIcon from '@mui/icons-material/DragIndicator';
import PinSolidIcon from '@mui/icons-material/PushPin';
import PinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import LeftArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import RightArrowIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpIcon from '@mui/icons-material/ArrowUpwardOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Draggable } from 'react-smooth-dnd';
import { PanelState } from '../Table';

type SettingsPanelProps = {
    setPanelOpen: (b:boolean) => void;
    setSettingsPanel: (b:boolean) => void;
    setRowDensity: (d:TABLE_ROW_DENSITY) => void;
    rowDensity: string;
    columns: any;
    setColSort: (a:any[]) => void;
    columnVisibility: any;
    setColumnVisibility: (c:any[]) => void;
    columnOrder: any;
    pinColumns: any;
    setPinColumns: (c:any) => void;
    fromTableColumns: any;
    setSortModel: (a:any) => void;
    generateURLs: (a:any) => void;
    sortModel: any[];
    clearTableState: () => void;
    canChangeRowDensity: boolean;
    canSort: boolean;
    canReOrderCols: boolean;
    canPin: boolean;
    canHideCols: boolean;
    openPanel?: (panelName: 'filter' | 'settings' | 'matrix' | null) => void;
    closePanel?: () => void;
    panelState?: PanelState;
}

const SettingsPanel = (props:SettingsPanelProps) => {
	const {
		setRowDensity,
		rowDensity,
		columns,
		setColSort,
		columnVisibility,
		setColumnVisibility,
		columnOrder,
		pinColumns,
		setPinColumns,
		fromTableColumns,
		setSortModel,
		sortModel,
		clearTableState,
		canChangeRowDensity,
		canSort,
		canReOrderCols,
		generateURLs,
		canPin,
		canHideCols,
		closePanel
	} = props;
	const [colsArr, setColsArr] = useState(columns);
	const [colSortOpts, setColSortOpts] = useState<any[]>([]);
	const [sortArr, setSortArr] = useState<any[]>(sortModel);
	const [mounted, setMounted] = useState(false);
	const sortDirOpts = [
		{
			id: 1,
			label: <ArrowDownIcon />,
			value: 'desc',
			labelString: '⬇'
		}, {
			id: 2,
			label: <ArrowUpIcon />,
			value: 'asc',
			labelString: '⬆'
		}
	]
	const handleClosePanel = () => {
		closePanel?.();
	}
	const clearSettings = () => {
		setSortArr([]);
		clearTableState();
	}
	const applyDrag = (arr:any[], dragResult:any) => {
		const { removedIndex, addedIndex, payload } = dragResult;
		if (removedIndex === null && addedIndex === null) return arr;
		const result = [...arr];
		let itemToAdd = payload;
		if (removedIndex !== null) {
			[itemToAdd] = result.splice(removedIndex, 1);
		}
		if (addedIndex !== null) {
			result.splice(addedIndex, 0, itemToAdd);
		}
		return result;
	};
	const applyColSort = (arr:any[], dragResult:any) => {
		const dragged = applyDrag(arr, dragResult)
		setColsArr(dragged);
		setColSort(dragged.map((col) => col.accessorKey))
	}
	const columnVisible = (column:any) => {
		const isVisible = columnVisibility[column.accessorKey] ?? true;
		return isVisible;
	}
	const toggleVisibleColumn = (column:any) => {
		const newVis = { ...columnVisibility };
		if (newVis[column.accessorKey] === undefined) {
			newVis[column.accessorKey] = false;
		} else {
			newVis[column.accessorKey] = !newVis[column.accessorKey];
		}
		setColumnVisibility(newVis);
	}
	const extractString:any = (obj:any, column:any) => {
		if (!obj && !column && !column.accessorKey) {
			return '';
		}
		if (typeof obj === 'string' && obj !== '') {
			return obj;
		} else if (React.isValidElement(obj)) {
			const rObj:ReactElement = { ...obj }
			if (rObj.props?.text) {
				return extractString(rObj.props?.text);
			}
			return extractString(rObj.props?.children);
		} else if (Array.isArray(obj)) {
			const newStrings:string[] = [];
			obj.forEach((el) => {
				if (typeof el === 'string') {
					newStrings.push(el);
				}
			});
			return newStrings.join(' ');
		} else if (column.accessorKey) {
			return column.accessorKey;
		} else {
			return obj.toString();
		}
	}
	const isActivePin = (column:any, dir:'left'|'right') => {
		const isPined = pinColumns[dir].includes(column.accessorKey) ?? false;
		return isPined;
	}
	const setActivePin = (column:any, dir:'left'|'right') => {
		const newPins = { ...pinColumns }
		const opDir = dir === 'left' ? 'right' : 'left';
		let isPinned = false;
		const newArr = newPins[opDir].filter((e:string) => {
			return e !== column.accessorKey;
		});
		newPins[opDir] = newArr;
		if (newPins[dir].includes(column.accessorKey)) {
			isPinned = true;
			const newArr = newPins[dir].filter((e:string) => {
				return e !== column.accessorKey;
			});
			newPins[dir] = newArr;
		} else {
			newPins[dir].push(column.accessorKey);
		}
		setPinColumns(newPins);
		const fromTableColumn = fromTableColumns.filter((col:any) => {
			return col.id === column.accessorKey;
		});
		fromTableColumn[0]?.column.pin(isPinned ? false : dir);
	}
	const filteredSorts = () => {
		const copy:any[] = [...sortArr];
		const filtered = colSortOpts.filter((col:any) => {
			const inSort = copy.findIndex((cCol:any) => {
				return cCol?.id === col.id;
			});
			return inSort < 0;
		});
		return filtered;
	}
	const canAddSort = () => {
		const filtered = filteredSorts();
		if (filtered.length) {
			return true;
		}
		return false;
	}
	const addSort = () => {
		const copy:any[] = [...sortArr];
		const filtered = filteredSorts();
		copy.push({
			_id: new Date().getTime(),
			id: filtered[0]?.id ?? '',
			desc: true,
			label: filtered[0].label,
			value: filtered[0].value
		});
		const lastObject = copy[copy.length - 1]
		const copyvalue = lastObject ? [lastObject] : []
		setSortArr(copyvalue);
	}
	const removeSort = (id:any) => {
		const copy:any[] = [...sortArr];
		const index = copy.findIndex((item) => {
			return item.id === id;
		});
		if (index > -1) {
			copy.splice(index, 1);
		}
		setSortArr(copy);
	}
	const updateSortItem = (id:any, v:any, field:string, index:number) => {
		const filtered = filteredSorts();
		const filterValue = filtered.filter((filter) => {
			if (filter.value === v) {
				return filter;
			}
			return false
		});
		const copy:any[] = [...sortArr];
		const lastObject = copy[copy.length - 1]
		const copyvalue = lastObject ? [lastObject] : []
		const updated = copyvalue.map((item, indexValue) => {
			if (item._id === id) {
				if (field === 'desc') {
					item[field] = v !== 'asc';
				} else {
					item[field] = v;
				}
			}
			if (indexValue === index) {
				if (field === 'desc') {
					item[field] = v !== 'asc';
				} else {
					item['id'] = filterValue[0]?.id;
					item['value'] = filterValue[0]?.value;
					item['label']= filterValue[0]?.label
				}
			}
			return item;
		});
		setSortArr(updated);
	}
	const hasOptions = () => {
		return canReOrderCols || canPin || canHideCols;
	}
	const hasNoSettings = () => {
		return !hasOptions() && !canChangeRowDensity && !canSort;
	}
	useEffect(() => {
		let newColArr = []
		if (columnOrder.length) {
			for (let i=0, l=columnOrder.length; i<l; ++i) {
				const index = columns.findIndex((col:any) => {
					return col.accessorKey === columnOrder[i];
				});
				newColArr.push(columns[index]);
			}
		} else {
			newColArr = columns;
		}
		setColsArr(newColArr);
	}, [columnOrder]);
	useEffect(() => {
		const cols = fromTableColumns
			.filter((col:any) => {
				const meta = col?.column?.columnDef?.meta;
				return !meta?.dataType || meta?.dataType === 'display';
			})
			.filter((col:any) => col.column.getCanSort())
			.map((col:any) => {
				const colDef = col.column.columnDef;
				return {
					id: col.id,
					label: colDef.header,
					value: colDef?.meta?.databaseLocation ?? colDef?.accessorKey
				}
			});
		setColSortOpts(cols);
	}, [fromTableColumns]);

	useEffect(() => {
		if (mounted) {
			setSortModel(sortArr);
			generateURLs({ sortModel: sortArr })
		} else {
			setMounted(true);
		}

	}, [sortArr]);

	useEffect(() => {
		setSortArr(sortModel);
	}, [sortModel]);

	return (
		<div className={styles.container}>
			<div className={styles.padded}>
				<button className={styles.closeBtn} onClick={() => handleClosePanel()} aria-label={'Close Panel'}><CloseIcon /></button>
				<H2 weight={TEXT_WEIGHT.BOLD} text={'Display settings'} />
				<Spacer size={20} />
				{hasNoSettings() && <Text text={'This table has no configurable display settings.'} />}
				{canChangeRowDensity && <>
					<H3 weight={TEXT_WEIGHT.BOLD} text={'Row density'} />
					<Spacer size={10} />
					<div className={styles.rowDensitySelect}>
						<button className={`${styles.densityBtn} ${rowDensity === TABLE_ROW_DENSITY.COMPACT ? styles.densityBtnActive : ''}`} onClick={() => setRowDensity(TABLE_ROW_DENSITY.COMPACT)} aria-label={'Set Compact Density'}><CompactIcon /></button>
						<button className={`${styles.densityBtn} ${rowDensity === TABLE_ROW_DENSITY.REGULAR ? styles.densityBtnActive : ''}`} onClick={() => setRowDensity(TABLE_ROW_DENSITY.REGULAR)} aria-label={'Set Regular Density'}><RegularIcon /></button>
						<button className={`${styles.densityBtn} ${rowDensity === TABLE_ROW_DENSITY.EXPANDED ? styles.densityBtnActive : ''}`} onClick={() => setRowDensity(TABLE_ROW_DENSITY.EXPANDED)} aria-label={'Set Expanded Density'}><ExpandedIcon /></button>
					</div>
					<Spacer size={20} />
				</>}
				{canSort && <>
					<H3 weight={TEXT_WEIGHT.BOLD} text={'Sorting'} />
					<Spacer size={10} />
					{sortArr.map((sort:any, index:any) => {
						return <React.Fragment key={sort._id}>

							<div className={styles.sortItem}>
								<Select name={'Column Sorting Select'} value={colSortOpts.length ? sort.value : ''} onChange={(v:any) => { updateSortItem(sort._id, v, 'id', index) }}>
									{colSortOpts.map((option, i) => {
										return <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
									})}
								</Select>
								<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
								<div style={{ width: '100px' }}>
									<Select name={'Sort Direction'} value={sort.desc ? 'desc' : 'asc'} onChange={(v:any) => { updateSortItem(sort._id, v, 'desc', index) }}>
										{sortDirOpts.map((option, i) => {
											return <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
										})}
									</Select>
								</div>
								<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
								<button onClick={() => { removeSort(sort.id) }} aria-label={'Remove Sort'}><DeleteIcon /></button>
							</div>
							<Spacer size={5} />
						</React.Fragment>
					})}
					{sortArr.length > 0 && <Spacer size={10} />}
					<div>
						<Button disabled={sortArr.length === 1 ? true : !canAddSort()} type={BUTTON_TYPES.SECONDARY} size={BUTTON_SIZE.SMALL} text={'New sort'} Icon={AddIcon} onClick={() => { addSort() }} />
					</div>
					<Spacer size={20} />
				</>}
				{hasOptions() && <>
					<H3 weight={TEXT_WEIGHT.BOLD} text={'Columns showing'} />
					<Spacer size={10} />
					<Container onDrop={(e) => applyColSort(colsArr, e)} shouldAcceptDrop={() => canReOrderCols}>
						{colsArr.map((column:any, i:number) => {
							if (column.isPlaceholder || column?.meta?.dataType === 'data') return null;
							return <Draggable key={i}>
								<div className={styles.columnChip}>
									{canReOrderCols && <DragIcon />}
									<div className={styles.columnChipText}>
										<Text text={extractString(column?.header, column)} />
									</div>
									{canPin && <>
										<button className={`${styles.columnChipPin} ${isActivePin(column, 'left') ? styles.activePin : ''}`} onClick={() => { setActivePin(column, 'left') }} aria-label={'Set Left Active Pin'}>
											<LeftArrowIcon className={styles.arrowIcon} />
											{!isActivePin(column, 'left') && <PinOutlinedIcon />}
											{isActivePin(column, 'left') && <PinSolidIcon />}
										</button>
										<button className={`${styles.columnChipPin} ${isActivePin(column, 'right') ? styles.activePin : ''}`} onClick={() => { setActivePin(column, 'right') }} aria-label={'Set Right Active Pin'}>
											{!isActivePin(column, 'right') && <PinOutlinedIcon />}
											{isActivePin(column, 'right') && <PinSolidIcon />}
											<RightArrowIcon className={styles.arrowIcon} />
										</button>
									</>}
									{canHideCols && <Switch name={'Can Hide Columns Switch'} value={column.accessorKey} onChange={() => { toggleVisibleColumn(column) }} checked={columnVisible(column)} />}
								</div>
							</Draggable>
						})}
					</Container>
				</>}
			</div>
			{!hasNoSettings() && <div className={styles.panelBtns}>
				<Button text={'Clear'} onClick={() => clearSettings()} type={BUTTON_TYPES.TERTIARY} size={BUTTON_SIZE.SMALL} />
				{/* <Spacer size={20} dir={SPACER_DIRECTION.VERTICAL} />
                <Button text={'Save'} onClick={() => saveSettings()} size={ButtonSize.SMALL} /> */}
			</div>}
		</div>
	);
}

export default SettingsPanel;

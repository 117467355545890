import { SPACER_DIRECTION } from '@types';

type SpacerProps = {
	dir?: SPACER_DIRECTION;
	size: number;
	fill?: boolean;
	divider?: boolean;
	dividerColor?: string
	dividerSize?: number
}
type StyleType = {
	width: string;
	height: string;
	marginTop?: string;
	borderTop?: string;
	marginRight?: string;
	borderRight?: string;
	flex?: string;
}

export const Spacer = (props:SpacerProps) => {
	const { dir = SPACER_DIRECTION.HORIZONTAL, size, fill = false, divider = false, dividerColor = 'var(--neutral-03)', dividerSize = 1 } = props;
	const generateStyles = () => {
		const styles = {} as StyleType;
		switch (dir) {
		case SPACER_DIRECTION.HORIZONTAL:
			styles.width = '100%';
			if (divider) {
				styles.height = `${size/2}px`;
				styles.marginTop = `${size/2}px`;
				styles.borderTop = `${dividerSize}px solid ${dividerColor}`;
			} else {
				styles.height = `${size}px`;
			}
			break;
		case SPACER_DIRECTION.VERTICAL:
			styles.height = '100%';
			if (divider) {
				styles.width = `${size/2}px`;
				styles.marginRight = `${size/2}px`;
				styles.borderRight = `${dividerSize}px solid ${dividerColor}`;
			} else {
				styles.width = `${size}px`;
			}
			break;
		default:
			break;
		}
		if (fill) styles['flex'] = '1';
		return styles
	}
	return (
		<div data-testid={'@Ofload-Spacer'} style={generateStyles()} />
	);
}

import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { InputField, Spacer, Text } from '@components';
import { SPACER_DIRECTION } from '@types';

type FilterProp = {
    meta?: any;
	header?: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterRange = (props:FilterProp) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const [range, setRange] = useState(['', '']);
	const updateRange = (point:'min'|'max', e:any) => {
		const newRange = [...range];
		newRange[point === 'min' ? 0 : 1] = e.target.value;
		if (point === 'min' && newRange[1] !== '') {
			if (parseInt(e.target.value) > parseInt(newRange[1])) {
				newRange[1] = e.target.value;
			}
		}
		const newFilterData = { ...filterData };
		newFilterData.value = newRange;
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'range';
		onChange(newFilterData);
		setRange(newRange);
	}
	const checkMinMax = () => {
		const newRange = [...range];
		if (parseInt(newRange[1]) < parseInt(newRange[0])) {
			newRange[0] = newRange[1]; //eslint-disable-line
		}
		const newFilterData = { ...filterData };
		newFilterData.value = newRange;
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = 'range';
		onChange(newFilterData);
		setRange(newRange);
	}
	useEffect(() => {
		if (filterData?.value) {
			setRange(filterData.value || ['', '']);
		} else {
			setRange(['', '']);
		}
	}, [filterData]);
	return (
		<div className={styles.inputContainer}>
			<InputField name={'Filter-Option-Range-Min'} placeholder={'Minimum'} value={range[0]} onChange={(e) => updateRange('min', e)} keepErrorSpace={false} />
			<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
			<Text text={'-'} />
			<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
			<InputField name={'Filter-Option-Range-Max'} placeholder={'Maximum'} value={range[1]} onChange={(e) => updateRange('max', e)} onBlur={() => checkMinMax()} keepErrorSpace={false} />
		</div>
	);
}

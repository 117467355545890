import {
	useState,
	useRef,
	useEffect,
	createContext,
	PropsWithChildren
} from 'react';
import { Menu } from './FloatingMenu.Menu';
import { TObject } from '@types';

type TProps = {
	parentWrapContent?: boolean;
	open?: boolean;
	onMenuToggle?: (b:boolean) => void;
}
type TContext = {
	showMenu: boolean;
	parentRef: HTMLDivElement | null;
	toggleMenu: (b:boolean) => void;
}

export const FloatingMenuContext = createContext<TContext | null>(null);

const MenuContainer = (props:PropsWithChildren<TProps>) => {
	const {
		parentWrapContent = true,
		open,
		onMenuToggle,
		children
	} = props;
	const parentRef = useRef<HTMLDivElement | null>(null);
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = (b:boolean) => {
		setShowMenu(b);
		if (onMenuToggle) onMenuToggle(b);
	}
	const [ctx, setCtx] = useState<TContext>({
		showMenu: false,
		parentRef: null,
		toggleMenu
	});
	const setStyles = () => {
		const styles:TObject = {}
		if (parentWrapContent) styles.display = 'inline';
		return styles;
	}

	useEffect(() => {
		const clone = { ...ctx };
		clone.showMenu = showMenu;
		setCtx(clone);
	}, [showMenu]) // eslint-disable-line

	useEffect(() => {
		if (parentRef.current) {
			const clone = { ...ctx };
			clone.parentRef = parentRef.current;
			if (open !== undefined) {
				clone.showMenu = open;
			}
			setCtx(clone);
		}
	}, [parentRef.current]); // eslint-disable-line

	useEffect(() => {
		const handleClickOutside = (event:Event) => {
			const { target } = event;
			const { parentNode } = (target as HTMLElement);
			if (
				!(target as HTMLElement).classList.contains('floating-menu-menu') &&
				!(parentNode as HTMLElement)?.classList.contains('floating-menu-menu') &&
				!(parentNode as HTMLElement)?.closest('.floating-menu-menu')
			) {
				toggleMenu(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [parentRef.current]); // eslint-disable-line

	return (
		<FloatingMenuContext.Provider value={ctx}>
			<div onClick={() => toggleMenu(!ctx.showMenu)} ref={parentRef} style={setStyles()}>
				{children}
			</div>
		</FloatingMenuContext.Provider>
	);
}

MenuContainer.Menu = Menu;

export const FloatingMenu = MenuContainer;

type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const TransportIcon = (props:IconProps) => {
	const {
		width = 40,
		height = 40,
		color = 'var(--neutral-07)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 24 22'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d={'M2.98999 3.17616L6.40634 16.8377L7.99558 16.3607L4.42108 1.83122L0.607422 0.713867V2.46126L2.98999 3.17616Z'} fill={color} />
			<path d={'M23.0069 13.7412L8.86963 17.0766L9.74268 18.5861L23.0069 15.4089V13.7412Z'} fill={color} />
			<path d={'M6.72266 2.69866L8.07788 8.41786L14.3525 6.90963L13.0025 1.19043L12.6849 1.26886L6.72266 2.69866Z'} fill={color} />
			<path d={'M18.1606 6.75781L19.5904 12.477L9.66147 14.8583L9.26545 13.3501L8.23682 9.1391L18.1606 6.75781Z'} fill={color} />
			<path d={'M7.28014 17.0766C7.6382 17.0766 7.9816 17.2188 8.23479 17.472C8.48798 17.7252 8.63022 18.0686 8.63022 18.4267C8.63022 18.7847 8.48798 19.1281 8.23479 19.3813C7.9816 19.6345 7.6382 19.7767 7.28014 19.7767C7.10041 19.786 6.92074 19.7574 6.75276 19.6928C6.58478 19.6282 6.43222 19.5291 6.30496 19.4018C6.17771 19.2746 6.07858 19.122 6.014 18.954C5.94942 18.7861 5.92082 18.6064 5.93006 18.4267C5.9401 18.0718 6.08556 17.7342 6.33661 17.4831C6.58765 17.2321 6.92525 17.0866 7.28014 17.0766ZM7.28014 15.2495C6.68334 15.2497 6.10001 15.4269 5.60392 15.7587C5.10782 16.0904 4.72123 16.5618 4.49302 17.1133C4.26481 17.6647 4.20523 18.2714 4.32182 18.8568C4.4384 19.4421 4.72592 19.9796 5.14801 20.4016C5.5701 20.8235 6.10781 21.1108 6.69316 21.2271C7.27852 21.3434 7.88522 21.2836 8.43657 21.0551C8.98791 20.8267 9.45915 20.4399 9.79068 19.9437C10.1222 19.4474 10.2992 18.864 10.2992 18.2672C10.306 17.869 10.2325 17.4734 10.0832 17.1041C9.93385 16.7348 9.7117 16.3994 9.42997 16.1178C9.14825 15.8362 8.81271 15.6142 8.44336 15.465C8.07401 15.3158 7.67841 15.2425 7.28014 15.2495Z'} fill={color} />
		</svg>
	);
};

import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { navigator } from '@types';

const inboxNav = () => {
	const root = `/inbox`;
	return [
		{
			label: 'Quotes',
			icon: (color:string) => <RequestQuoteOutlinedIcon style={{ color: color }} />,
			link: `${root}/quotes`,
			enabled: true,
			activeURL: 'quotes'
		},
		{
			label: 'New Shippers',
			icon: (color:string) => <WarehouseOutlinedIcon style={{ color: color }} />,
			link: `${root}/new_shippers`,
			enabled: true,
			activeURL: 'new_shippers'
		},
		{
			label: 'New Carriers',
			icon: (color:string) => <LocalShippingOutlinedIcon style={{ color: color }} />,
			link: `${root}/new_carriers`,
			enabled: true,
			activeURL: 'new_carriers'
		}
	] as navigator[];
}

export default inboxNav;

import React, { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import {
	Radio,
	InputField,
	Spacer,
	Label,
	Switch,
	Button,
	AddressPicker,
	Text
} from '@components/index';
import { TEXT_WEIGHT, BUTTON_TYPES, BUTTON_SIZE } from '@types';

type FilterProps = {
	meta: any;
	header: any;
	filterData: any;
	onChange: (val:any) => void;
}
type option = {
	label: string;
	value: string;
	checked: boolean;
}
type location = {
	pickupType: option[];
	dropOffType: option[];
	pickupVal: string | {
		label: string;
		value: any;
	};
	dropOffVal: string | {
		label: string;
		value: any;
	};
	biDir: boolean;
}

export const FilterLocation = (props:FilterProps) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const fullName = meta?.columnFullName;

	// --> Check if these are required anymore...
	/*
	 *const [pickRegionOpts, setPickRegionOpts] = useState<any[]>([]);
	 *const [dropRegionOpts, setDropRegionOpts] = useState<any[]>([]);
	 *const [pickupLoader, setPickupLoader] = useState(false);
	 *const [dropoffLoader, setDropoffLoader] = useState(false);
	 */

	const [locations, setLocations] = useState<location[]>([
		{
			pickupType: [
				{
					label: 'Region',
					value: 'Region',
					checked: false
				},
				{
					label: 'Address',
					value: 'Address',
					checked: false
				}
			],
			pickupVal: '',
			dropOffType: [
				{
					label: 'Region',
					value: 'Region',
					checked: false
				},
				{
					label: 'Address',
					value: 'Address',
					checked: false
				}
			],
			dropOffVal: '',
			biDir: false
		}
	]);
	const updateOnce = (v:any) => {
		setLocations(v);
		const newFilterData = { ...filterData };
		newFilterData.value = v;
		newFilterData.columnField = meta?.databaseLocation;
		newFilterData.operatorValue = 'location';
		onChange(newFilterData);
	}
	const updateLocationType = (i:number, type:'pickupType' | 'dropOffType', v:any) => {
		const cloned = [...locations]
		const newOpts = cloned[i][type].map((opt) => {
			opt.checked = false;
			if (opt.value === v.target.value) opt.checked = true;
			return opt;
		});
		cloned[i][type] = newOpts;
		if (type === 'pickupType') {
			cloned[i]['pickupVal'] = '';
		} else {
			cloned[i]['dropOffVal'] = '';
		}
		updateOnce(cloned);
	}
	const updateLocationVal = (i:number, type:'pickupVal' | 'dropOffVal', v:any) => {
		const cloned = [...locations];
		cloned[i][type] = v;
		updateOnce(cloned);
	}
	const hasSelected = (i:number, type:'pickupType' | 'dropOffType') => {
		let hasSelected = false;
		for (let index = 0, l = locations[i][type].length; index<l; ++index) {
			if (locations[i][type][index].checked) {
				hasSelected = true;
			}
		}
		return hasSelected;
	}
	const setLocBiDir = (i:number, b:boolean) => {
		const clone = [...locations];
		clone[i].biDir = b;
		updateOnce(clone);
	}
	const changeLocationCount = (i:number) => {
		let cloned = [...locations];
		if (i === 0) {
			cloned.push({
				pickupType: [
					{
						label: 'Region',
						value: 'Region',
						checked: false
					},
					{
						label: 'Address',
						value: 'Address',
						checked: false
					}
				],
				pickupVal: '',
				dropOffType: [
					{
						label: 'Region',
						value: 'Region',
						checked: false
					},
					{
						label: 'Address',
						value: 'Address',
						checked: false
					}
				],
				dropOffVal: '',
				biDir: false
			});
		} else {
			cloned = cloned.filter((location, index) => {
				if (i !== index) {
					return location;
				}
				return false;
			});
		}
		updateOnce(cloned);
	}

	// --> Double check if this code is required anymore...
	/*
	 *const regionSearch = useCallback(debounce((search, fromPick) => {
	 *	axios({
	 *		method: 'POST',
	 *		url: `${window.location.origin}/getrangedata`,
	 *		data: {
	 *			state: '',
	 *			name: search
	 *		}
	 *	}).then((result) => {
	 *		const newOpts = result.data.map((item:any) => {
	 *			return {
	 *				label: item.name,
	 *				value: item.id
	 *			}
	 *		});
	 *		fromPick ? setPickRegionOpts(newOpts) : setDropRegionOpts(newOpts);
	 *		setPickupLoader(false);
	 *		setDropoffLoader(false);
	 *	});
	 *}, 500), []);
	 */
	const selectedLocationType = (i:number, fromPick:boolean, value:'Region'|'Address') => {
		const selected = fromPick ? locations[i].pickupType.filter((item:any) => {
			return item.checked;
		})[0].value : locations[i].dropOffType.filter((item:any) => {
			return item.checked;
		})[0].value;
		return selected === value;
	}
	useEffect(() => {
		if (filterData?.value) {
			setLocations(filterData?.value || [
				{
					pickupType: [
						{
							label: 'Region',
							value: 'Region',
							checked: false
						},
						{
							label: 'Address',
							value: 'Address',
							checked: false
						}
					],
					pickupVal: '',
					dropOffType: [
						{
							label: 'Region',
							value: 'Region',
							checked: false
						},
						{
							label: 'Address',
							value: 'Address',
							checked: false
						}
					],
					dropOffVal: '',
					biDir: false
				}
			]);
		} else {
			setLocations([
				{
					pickupType: [
						{
							label: 'Region',
							value: 'Region',
							checked: false
						},
						{
							label: 'Address',
							value: 'Address',
							checked: false
						}
					],
					pickupVal: '',
					dropOffType: [
						{
							label: 'Region',
							value: 'Region',
							checked: false
						},
						{
							label: 'Address',
							value: 'Address',
							checked: false
						}
					],
					dropOffVal: '',
					biDir: false
				}
			]);
		}
	}, [filterData]);
	return (
		<>
			{locations.map((location, i) => {
				return <React.Fragment key={i}>
					{i !== 0 && <Spacer size={20} />}
					<div className={styles.filterLabel}>
						<Label weight={TEXT_WEIGHT.BOLD} text={'Pick-Up Location'} />
					</div>
					<div className={styles.inset}>
						{location.pickupType.map((opt, index) => {
							return <React.Fragment key={index}>
								<Radio
									name={`Option${opt}`}
									label={opt.label}
									onChange={(v) => updateLocationType(i, 'pickupType', v)}
									value={opt.value}
									checked={opt.checked}
								/>
								<Spacer size={5} />
							</React.Fragment>
						})}
						{hasSelected(i, 'pickupType') && selectedLocationType(i, true, 'Region') && <>
							<AddressPicker
								locationType='REGION'
								name={'LocationFilterPickupSelect'}
								value={typeof location.pickupVal === 'string' ? location.pickupVal : location.pickupVal.label}
								onChange={(e) => updateLocationVal(i, 'pickupVal', e?.target.value)}
								onSelected={(o:any) => updateLocationVal(i, 'pickupVal', o)}
								options={null} // --> pickRegionOpts - this is used for manual option updates...
								onClearInput={(v) => updateLocationVal(i, 'pickupVal', v)}
							/>
							<Spacer size={10} />
						</>}
						{hasSelected(i, 'pickupType') && selectedLocationType(i, true, 'Address') && <>
							<InputField name={'pickup location'} value={typeof location.pickupVal === 'string' ? location.pickupVal : location.pickupVal.label} onChange={(e) => updateLocationVal(i, 'pickupVal', e?.target.value)} keepErrorSpace={false} />
							<Spacer size={10} />
						</>}
					</div>
					<div className={styles.filterLabel}>
						<Label weight={TEXT_WEIGHT.BOLD} text={'Drop-Off Location'} />
					</div>
					<div className={styles.inset}>
						{location.dropOffType.map((opt, index) => {
							return <React.Fragment key={index}>
								<Radio
									name={`Option${opt}`}
									label={opt.label}
									onChange={(v) => updateLocationType(i, 'dropOffType', v)}
									value={opt.value}
									checked={opt.checked}
								/>
								<Spacer size={5} />
							</React.Fragment>
						})}
						{hasSelected(i, 'dropOffType') && selectedLocationType(i, false, 'Region') &&
							<AddressPicker
								locationType='REGION'
								name={'LocationFilterDropoffSelect'}
								value={typeof location.dropOffVal === 'string' ? location.dropOffVal : location.dropOffVal.label}
								onChange={(e) => updateLocationVal(i, 'dropOffVal', e?.target.value)}
								onSelected={(o:any) => updateLocationVal(i, 'dropOffVal', o)}
								options={null} // --> dropRegionOpts - this is used for manual option updates...
								onClearInput={(v) => updateLocationVal(i, 'pickupVal', v)}
							/>
						}
						{hasSelected(i, 'dropOffType') && selectedLocationType(i, false, 'Address') && <>
							<InputField name={'drop off location'} value={typeof location.dropOffVal === 'string' ? location.dropOffVal : location.dropOffVal.label} onChange={(e) => updateLocationVal(i, 'dropOffVal', e?.target.value)} keepErrorSpace={false} />
						</>}
					</div>
					<Spacer size={10} />
					<div className={styles.filterLabel}>
						<Text text={'Both directions'} />
						<Switch name={'FilterAddressBothDirections'} value={fullName !== undefined ? fullName : header} checked={location.biDir} onChange={() => setLocBiDir(i, !location.biDir)} />
					</div>
					<Spacer size={10} />
					<Button text={i === 0 ? 'Add a Lane' : 'Remove this lane'} onClick={() => changeLocationCount(i)} type={BUTTON_TYPES.SECONDARY} size={BUTTON_SIZE.SMALL} />
				</React.Fragment>
			})}
		</>
	);
}

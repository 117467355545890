export const debounce = (fn:any, delay=400) => {
	let promise:Promise<any> | null = null
	let resolve:any = null;
	let resetHandle:any = null;

	return (...args:any[]) => {
		if (!promise) {
			promise = new Promise((_resolve) => {
				resolve = _resolve;
			});
		}
		if (resetHandle) {
			clearTimeout(resetHandle);
		}
		resetHandle = setTimeout(() => {
			promise = null;
			resolve(fn(...args))
		}, delay);

		return promise;
	};
};

import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { Text, Switch } from '@components/index';
import { UserID } from '@state';
import { useRecoilValue } from 'recoil';

type FilterProps = {
    meta: any;
    header: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterAssignedToMe = (props:FilterProps) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const userID = useRecoilValue(UserID);
	const fullName = meta?.columnFullName;
	const [switchChecked, setSwitchChecked] = useState(false);
	const changeSwitchValue = () => {
		setSwitchChecked(!switchChecked);
		onChange(!switchChecked ? {
			value: userID,
			columnField: meta?.databaseLocation,
			operatorValue: 'is'
		} : null);
	}
	useEffect(() => {
		setSwitchChecked(filterData?.value || false);
	}, [filterData]);
	return (
		<div className={styles.filterLabel}>
			<Text  text={fullName !== undefined ? fullName : header} />
			<Switch name={'Filter-Option-Assigned-To-Me'} value={fullName !== undefined ? fullName : header} checked={switchChecked} onChange={() => changeSwitchValue()} />
		</div>
	);
}

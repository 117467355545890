import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import { Spacer, Text } from '@components/CORE/index';
import { Table, Tabs } from '@components/LAYOUT/index';
import OfloadLogo from '@img/logo-full.png';
import { OptedInColumns, coveredColumns } from './constants';
import { useGetCovered, useGetOptedInFreightSafe } from '@api/freightSafe';

import styles from './FSDetails.module.scss';

const FSDetails = () => {
  const navigate = useNavigate();

  const isAuthenticatedUser = localStorage.getItem('jwt');

  useEffect(() => {
    if (!isAuthenticatedUser) {
      navigate('/freight-safe/login');
    }
  }, []);

  const handleLogout = () => {
    if (isAuthenticatedUser) {
      localStorage.removeItem('jwt');
      navigate('/freight-safe/login');
    }
  };

  const tabs = [
    { tabTitle: 'Opted In', TabContent: <OptedIn /> },
    { tabTitle: 'Covered', TabContent: <Covered /> },
  ];

  return (
    <div className={styles.freightSafeDetails}>
      <div className={styles.header}>
        <img alt={'logo'} src={OfloadLogo} className={styles.logo} />
        <div className={styles.headerActions}>
          {/* <Text text={'Name'} /> */}
          <LogoutIcon onClick={handleLogout} />
        </div>
      </div>
      <div className={styles.content}>
        <Spacer size={20} />
        <Text text="Freight Safe Warranty" type={'h1'} />
        <Spacer size={20} />
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};
export default FSDetails;

const OptedIn = () => {
  const tableID = 'FREIGHTSAFE_OPTED_IN';
  const navigate = useNavigate();

  const { isLoading, data, callAPI} = useGetOptedInFreightSafe();
  const { data: rows, meta } = data || {};

  useEffect(() => {
    callAPI();
  }, []);

  const cols = useMemo(() => OptedInColumns(), []);

  const onChangeURL = (url: string) => {
    const [, query] = url.split('?');
    callAPI(query);
    navigate(`?${query}`);
  };

  return (
    <div className={styles.tabContent}>
      <Table
        tableID={tableID}
        data={rows || []}
        columns={cols}
        tableTitle={'Opted in Shippers'}
        loading={isLoading}
        tableHeight={'window'}
        tableHeightPadding={220}
        currentPage={meta?.current_page > 0 ? meta?.current_page : 1}
        rowsPerPage={meta?.per_page > 0 ? meta?.per_page : 25}
        rowsPerPageOpts={[5, 10, 15, 25, 50, 100]}
        totalRows={meta?.total || 0}
        onChangeURL={onChangeURL}
      />
    </div>
  );
};

const Covered = () => {
  const tableID = 'COVERED';
  const navigate = useNavigate();

  const cols = useMemo(() => coveredColumns(), []);

  const { isLoading, data, callAPI} = useGetCovered();
  const { data: rows, meta } = data || {};

  useEffect(() => {
    callAPI();
  }, []);

  const onChangeURL = (url: string) => {
    const [, query] = url.split('?');
    callAPI(query);
    navigate(`?${query}`);
  };

  return (
    <div className={styles.tabContent}>
      <Table
        tableID={tableID}
        data={rows || []}
        columns={cols}
        tableTitle={'Covered Shippers'}
        loading={isLoading}
        tableHeight={'window'}
        tableHeightPadding={220}
        currentPage={meta?.current_page > 0 ? meta?.current_page : 1}
        rowsPerPage={meta?.per_page > 0 ? meta?.per_page : 25}
        rowsPerPageOpts={[5, 10, 15, 25, 50, 100]}
        totalRows={meta?.total || 0}
        onChangeURL={onChangeURL}
      />
    </div>
  );
};

import { atom, selector } from 'recoil';
import { DATE_FORMATS, formatDate, parseDateString } from '@utils/dateUtils';
import { v4 as uuidV4 } from 'uuid';
import {
	CARGO_OPTIONS,
	Cargo,
	CargoStop,
	Shipment,
	Stop
} from '@types';

/* eslint-disable */
const keys = {
	NEW_BOOKING:                '@Ofload/New_Booking',
	EXISTING_BOOKING:           '@Ofload/Existing_Booking',

	CARGO_TYPE:                 '@Ofload/Cargo_Type',
	TRUCK_SIZE:                 '@Ofload/Truck_Size',
	TRUCK_TYPE:                 '@Ofload/Truck_Type',
	NEW_BOOKING_STOPS:          '@Ofload/New_Booking_Stops',
	NEW_BOOKING_CARGOS:         '@Ofload/New_Booking_Cargos',
	NEW_BOOKING_CARGO_STOPS:    '@Ofload/New_Booking_Cargo_Stops',
	ADD_PICKUP:                 '@Ofload/Add_Pickup',
	REMOVE_PICKUP:              '@Ofload/Remove_Pickup',
	CARGO_FIELDS:               '@Ofload/Cargo_Fields',
	STOP_FIELDS:                '@Ofload/Stop_Fields',
	SHIPMENT_FIELDS:            '@Ofload/Shipment_Fields',
	SET_TERMS:                  '@Ofload/Set_Terms',
	LOAD_RESTRAINTS:            '@Ofload/Load_Restraints',
	LOAD_OPTIONS:               '@Ofload/Load_Options',
	NEW_BOOKING_PRICE:          '@Ofload/New_Booking_Price',
	PALLET_HANDLING:            '@Ofload/Pallet_Handling',
	NEW_BOOKING_TEMPERATURE:    '@Ofload/New_Booking_Temperature',
	PICKUP_POSTCODE:            '@Ofload/Pickup_Postcode',
	IS_DANGEROUS:               '@Ofload/Is_Dangerous',
	DANGEROUS_META:             '@Ofload/Dangerous_Meta',
	SHIPPER_QUOTE_SESSION_ID:   '@Ofload/Shipper_Quote_Session_Id',
	ADDRESS_FIELDS:             '@Ofload/Address_Fields',
	FORM_VALIDATION:            '@Ofload/Form_Validation',
	FORM_VALIDATION_SELECTOR:            '@Ofload/Form_Validation_Selector',
	API_FORM_ERRORS:            '@Ofload/Api_Form_Errors',
	BOOKING_ACCORDION_IDS:		'@Ofload/Booking_Accordion_Page',
	BOOKING_ADDRESS_SEARCH:		'@Ofload/Booking_Address_Search_From'
}
/* eslint-enable */

export const BookingAddressSearchFrom = atom<string>({
	key: keys.BOOKING_ADDRESS_SEARCH,
	default: ''
});

export const BookingAccordionPage = atom<string[]>({
	key: keys.BOOKING_ACCORDION_IDS,
	default: ['0']
});

export const NewBooking = atom<Shipment>({
	key: keys.NEW_BOOKING,
	default: {
		cargo_stop: [
			{
				cargo_index: 0,
				stop_index: 0
			},
			{
				cargo_index: 0,
				stop_index: 1
			}
		],
		cargo_type_id: null,
		cargos: [
			{
				id: null,
				pallet_quantity: 0,
				pallet_space_quantity: 0,
				weight: 0,
				height: 0,
				length: 0,
				width: 0,
				load_meter: 0,
				is_ftl: false,
				stops: [],
				freight_detail: '',
				has_overweight_pallet: false,
				overweight_pallets_information: '',
			}
		],
		dangerous_good_meta_data: [],
		id: null,
		is_dangerous: false,
		is_stackable: false,
		loading_options: [],
		loading_restraints: [],
		pallet_handling: [],
		price: null,
		reference: '',
		shipper_id: null,
		shipper_ref1: '',
		shipper_ref2: '',
		shipper_ref3: '',
		terms_conditions: false,
		stops: [
			{
				stopID: '0',
				action_type: 'PICKUP',
				has_overweight: false,
				overweight_details: '',
				is_time_slot: false,
				time_slot_detail: '',
				time_slot_option: 'NOT_REQUIRED',
				delivery_date: '',
				start_at: '',
				end_at: '',
				address: {
					id: null,
					formatted_address: '',
					address: '',
					address2: '',
					gm_place_id: '',
					shipment_address_documents: [],
					time_slot_option: '',
					postcode: {
						id: null,
						postcode: '',
						suburb: '',
						state: '',
						country_code: '',
						latitude: null,
						longitude: null,
						map_polygon: ''
					},
					contact: {
						business_name: null,
						contact: null,
						phone: null,
						email: null,
						instruction: null,
						slot: null,
						gate_house: null,
						open_time: '09:00',
						close_time: '17:00'
					},
				}
			}, {
				stopID: '1',
				action_type: 'DROPOFF',
				has_overweight: false,
				overweight_details: '',
				is_time_slot: false,
				time_slot_detail: '',
				time_slot_option: 'NOT_REQUIRED',
				delivery_date: '',
				start_at: '',
				end_at: '',
				address: {
					id: null,
					formatted_address: '',
					address: '',
					address2: '',
					gm_place_id: '',
					shipment_address_documents: [],
					time_slot_option: '',
					postcode: {
						id: null,
						postcode: '',
						suburb: '',
						state: '',
						country_code: '',
						latitude: null,
						longitude: null,
						map_polygon: ''
					},
					contact: {
						business_name: null,
						contact: null,
						phone: null,
						email: null,
						instruction: null,
						slot: null,
						gate_house: null,
						open_time: '09:00',
						close_time: '17:00'
					},
				}
			}
		],
		temperature_id: 1,
		truck_size_id: 0,
		truck_type_id: null,
		type: 'Quote',
		shipper_quote_session_id: 0,
	}
});

export const CargoType = selector({
	key: keys.CARGO_TYPE,
	get: ({ get }) => get(NewBooking).cargo_type_id,
	set: ({ get, set }, newValue) => {
		let otherFields = {}
		if (newValue as unknown as CARGO_OPTIONS === CARGO_OPTIONS.PALLETS) {
			otherFields = { truck_size_id: 2 };
		}
		if (newValue as unknown as CARGO_OPTIONS === CARGO_OPTIONS.FULL_TRUCK_LOAD) {
			otherFields = { truck_size_id: 0 }
		}
		if (newValue as unknown as CARGO_OPTIONS === CARGO_OPTIONS.VOLUMETRIC) {
			otherFields = { truck_size_id: 1 }
		}

		const clonedData = {
			...get(NewBooking),
			...otherFields,
			cargo_type_id: newValue as number
		}
		set(NewBooking, clonedData);
	}
});

export const TruckSize = selector({
	key: keys.TRUCK_SIZE,
	get: ({ get }) => get(NewBooking).truck_size_id,
	set: ({ get, set }, newValue) => {
		set(NewBooking, {
			...get(NewBooking),
			truck_size_id: newValue as number
		});
	}
});

export const TruckType = selector({
	key: keys.TRUCK_TYPE,
	get: ({ get }) => get(NewBooking).truck_type_id,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		set(NewBooking, { ...currentBooking, truck_type_id: newValue as unknown as number });
	}
});

export const NewBookingStops = selector({
	key: keys.NEW_BOOKING_STOPS,
	get: ({ get }) => get(NewBooking).stops
});

export const NewBookingCargos = selector({
	key: keys.NEW_BOOKING_CARGOS,
	get: ({ get }) => get(NewBooking).cargos
});

export const NewBookingCargoStops = selector({
	key: keys.NEW_BOOKING_CARGO_STOPS,
	get: ({ get }) => get(NewBooking).cargo_stop as CargoStop[]
});

export const AddPickup = selector({
	key: keys.ADD_PICKUP,
	get: ({ get }) => {
		return {
			...get(NewBooking).cargo_stop,
			...get(NewBooking).cargos,
			...get(NewBooking).stops
		}
	},
	set: ({ get, set }) => {
		const newStop: Stop = {
			stopID: uuidV4(),
			action_type: 'PICKUP',
			is_time_slot: false,
			time_slot_detail: '',
			time_slot_option: 'NOT_REQUIRED',
			delivery_date: '',
			start_at: '',
			end_at: '',
			has_overweight: false,
			overweight_details: '',
			address: {
				id: null,
				formatted_address: '',
				address: '',
				address2: '',
				gm_place_id: '',
				shipment_address_documents: [],
				time_slot_option: '',
				'postcode': {
					id: null,
					postcode: '',
					suburb: '',
					state: '',
					country_code: '',
					latitude: null,
					longitude: null,
					map_polygon: ''
				},
				'contact': {
					business_name: null,
					contact: null,
					phone: null,
					email: null,
					instruction: null,
					slot: null,
					gate_house: null,
					open_time: null,
					close_time: null
				},
			}
		};

		const newCargo: Cargo = {
			id: null,
			pallet_quantity: 0,
			pallet_space_quantity: 0,
			weight: 0,
			height: 0,
			length: 0,
			width: 0,
			load_meter: 0,
			is_ftl: false,
			stops: [],
			freight_detail: '',
			has_overweight_pallet: false,
			overweight_pallets_information: '',
		};

		const stops = get(NewBookingStops);
		const cargos = get(NewBookingCargos);
		const cargoStops = get(NewBookingCargoStops);

		const dropoffStopIndex = stops.findIndex(
			(stop) => stop.action_type === 'DROPOFF'
		);

		const updatedStops = [...stops, newStop];
		const updatedCargos = [...cargos, newCargo];
		const newCargoStop: CargoStop = {
			cargo_index: updatedCargos.length - 1,
			stop_index: updatedStops.length - 1,
		};
		const newDropoffCargoStop: CargoStop = {
			cargo_index: updatedCargos.length - 1,
			stop_index: dropoffStopIndex,
		};
		const updatedCargoStop = [...cargoStops, newCargoStop, newDropoffCargoStop];

		set(NewBooking, {
			...get(NewBooking),
			stops: updatedStops,
			cargos: updatedCargos,
			cargo_stop: updatedCargoStop,
		});
	}
});

export const RemovePickup = selector({
	key: keys.REMOVE_PICKUP,
	get: ({ get }) => {
		return {
			...get(NewBooking).cargo_stop,
			...get(NewBooking).cargos,
			...get(NewBooking).stops
		}
	},
	set: ({ get, set }, index) => {
		const pickupStopIndex = index as unknown as number;
		const state = { ...get(NewBooking) }
		const dropoffStopIndex = state.stops.findIndex(
			(stop) => stop.action_type === 'DROPOFF'
		);
		if (dropoffStopIndex === -1) {
			console.error('DROPOFF not found', pickupStopIndex);
		}
		const pickupCargoStop = state.cargo_stop.filter((cargoStop) =>
			cargoStop.stop_index === pickupStopIndex
		);


		const cargoIndex = pickupCargoStop[0].cargo_index;

		// Remove the associated Cargos
		const updatedCargos = state.cargos.filter((_, index) =>
			!pickupCargoStop.some((cargoStop: any) => cargoStop.cargo_index === index)
		);

		const updatedCargoStop = state.cargo_stop
			.filter((cargoStop) => cargoStop.cargo_index !== cargoIndex) // Remove the associated cargo stop
			.map((cargoStop) => ({
				...cargoStop,
				stop_index: cargoStop.stop_index > pickupStopIndex ? cargoStop.stop_index - 1 : cargoStop.stop_index,
				cargo_index: cargoStop.cargo_index > cargoIndex ? cargoStop.cargo_index - 1 : cargoStop.cargo_index,
			}));
		const newStops = state.stops.filter((_, index) => index !== pickupStopIndex );

		set(NewBooking, {
			...state,
			stops: newStops,
			cargos: updatedCargos,
			cargo_stop: updatedCargoStop,
		});
	}
});

export const CargoFields = selector({
	key: keys.CARGO_FIELDS,
	get: ({ get }) => get(NewBooking).cargos,
	set: ({ get, set }, data) => {
		const cargos = [...get(NewBookingCargos)];
		const cargoStop = get(NewBookingCargoStops);
		const { index, name, value } = data as any;
		let parsedValue: string | number | boolean;
		const numberFields = [
			'id', 'pallet_quantity', 'pallet_space_quantity', 'weight',
			'height', 'length', 'width', 'load_meter'
		];
		if (numberFields.includes(name)) {
			parsedValue = parseInt(value || 0);
		} else {
			parsedValue = value;
		}

		if (name === 'weight') {
			const weight = parseInt(value || 0);
			const palletQuantity = parseInt(String(cargos[index].pallet_quantity || 0));
			if (weight / palletQuantity > 1000) {
				cargos[index] = { ...cargos[index], has_overweight_pallet: true }
			}
		}

		if (name === 'has_overweight_pallet') {
			parsedValue = !cargos[index].has_overweight_pallet;
		}

		const associatedCargoStop = cargoStop.find((cs) => cs.stop_index === index);
		if (!associatedCargoStop) return;
		const cargoIndex = associatedCargoStop.cargo_index;
		const updatedCargos = cargos.map((cargo, index) =>
			index === cargoIndex ? { ...cargo, [name]: parsedValue } : cargo
		);
		set(NewBooking, {
			...get(NewBooking),
			cargos: updatedCargos,
		});
	}
});

export const StopFields = selector({
	key: keys.STOP_FIELDS,
	get: ({ get }) => get(NewBooking).stops,
	set: ({ get, set }, data) => {
		if (!data) return;
		const stops = get(NewBookingStops);
		let { index, name, value } = data as any; // eslint-disable-line
		if (index < 0 || index >= stops.length) return;
		if (name === 'has_overweight') {
			value = !stops[index].has_overweight;
		}

		if (name === 'delivery_date') {
			const dateParts = value.split('/');
			if (dateParts.length > 2) {
				if (
					dateParts[0].length === 2 &&
					dateParts[1].length === 2 &&
					dateParts[2].length === 4
				) {
					value = formatDate(parseDateString(value, DATE_FORMATS.SHORT_DATE), DATE_FORMATS.API_DATE_FORMAT);
				}
			}
		}

		const updatedStops = stops.map((stop, idx) =>
			idx === index
				? { ...stop, [name]: value }
				: stop
		);
		set(NewBooking, {
			...get(NewBooking),
			stops: updatedStops
		});
	}
});

export const AddressFields = selector<Stop[]>({
	key: keys.ADDRESS_FIELDS,
	get: ({ get }) => get(NewBooking).stops,
	set: ({ get, set }, data) => {
		const {
			index,
			name,
			value,
			level = 'root'
		} = data as unknown as {
			index: number,
			name: string,
			value: any,
			level?: 'root' | 'postcode' | 'contact'
		};
		const stops:Stop[] = [...get(NewBooking).stops];
		// let stop:any = {...stops[index]};
		let stop;
		if (level === 'root') {
			stop = {
				...stops[index],
				address: {
					...stops[index].address,
					[name]: value
				}
			}
		} else {
			stop = {
				...stops[index],
				address: {
					...stops[index].address,
					[level]: {
						...stops[index].address[level],
						[name]: value
					}
				}
			}
		}
		stops[index] = stop;
		set(NewBooking, {
			...get(NewBooking),
			stops
		});
	}
});

export const ExistingBooking = selector({
	key: keys.EXISTING_BOOKING,
	get: ({ get }) => get(NewBooking),
	set: ({ get, set }, data) => {
		set(NewBooking, {
			...get(NewBooking),
			...data
		});
	}
})

export const ShipmentFields = selector({
	key: keys.SHIPMENT_FIELDS,
	get: ({ get }) => get(NewBooking),
	set: ({ get, set }, data) => {
		if (!data) return;
		const { field, value } = data as any;
		set(NewBooking, {
			...get(NewBooking),
			[field]: value
		});
	}
});

export const TermsConditions = selector({
	key: keys.SET_TERMS,
	get: ({ get }) => get(NewBooking).terms_conditions,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		set(NewBooking, { ...currentBooking, terms_conditions: newValue as unknown as boolean });
	}
});

export const LoadRestraints = selector({
	key: keys.LOAD_RESTRAINTS,
	get: ({ get }) => get(NewBooking).loading_restraints,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		const loadingRestraints = currentBooking.loading_restraints;
		if (currentBooking.loading_restraints.includes(newValue as unknown as string)) {
			set(NewBooking, { ...currentBooking, loading_restraints: [...loadingRestraints.filter((option) => option !== newValue as unknown as string)] })
		} else {
			set(NewBooking, { ...currentBooking, loading_restraints: [...loadingRestraints, newValue as unknown as string] });
		}
	}
});

export const LoadOptions = selector({
	key: keys.LOAD_OPTIONS,
	get: ({ get }) => get(NewBooking).loading_options,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		const loadingOptions = currentBooking.loading_options;
		if (currentBooking.loading_options.includes(newValue as unknown as string)) {
			set(NewBooking, { ...currentBooking, loading_options: [...loadingOptions.filter((option) => option !== newValue as unknown as string)] })
		} else {
			set(NewBooking, { ...currentBooking, loading_options: [...loadingOptions, newValue as unknown as string] });
		}
	}
});

export const Price = selector({
	key: keys.NEW_BOOKING_PRICE,
	get: ({ get }) => get(NewBooking).price,
	// set: ({get,set},newValue) => {

	// }
});

export const PalletHandling = selector({
	key: keys.PALLET_HANDLING,
	get: ({ get }) => get(NewBooking).pallet_handling,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		const palletHandling = currentBooking.pallet_handling;
		if (currentBooking.pallet_handling.includes(newValue as unknown as string)) {
			set(NewBooking, { ...currentBooking, pallet_handling: [...palletHandling.filter((option) => option !== newValue as unknown as string)] })
		} else {
			set(NewBooking, { ...currentBooking, pallet_handling: [...palletHandling, newValue as unknown as string] });
		}
	}
});

export const BookingTemperature = selector({
	key: keys.NEW_BOOKING_TEMPERATURE,
	get: ({ get }) => get(NewBooking).temperature_id,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		set(NewBooking, { ...currentBooking, temperature_id: newValue as unknown as number });
	}
});

export const PickupPostcode = selector({
	key: keys.PICKUP_POSTCODE,
	get: ({ get }) => get(NewBooking).stops,
	set: ({ get, set }, data) => {
		if (!data) return;
		const { index, value } = data as any;
		const updatedStops = [...get(NewBookingStops)];
		if (updatedStops.length > index && index >= 0) {
			updatedStops[index] = {
				...updatedStops[index],
				address: {
					...updatedStops[index].address,
					postcode: {
						...updatedStops[index].address.postcode,
						...value
					}
				}
			}
		}
		set(NewBooking, {
			...get(NewBooking),
			stops: updatedStops
		});
	}
});

export const IsDangerous = selector({
	key: keys.IS_DANGEROUS,
	get: ({ get }) => get(NewBooking).is_dangerous,
	set: ({ get, set }) => {
		const currentBooking = get(NewBooking);
		set(NewBooking, { ...currentBooking, is_dangerous: !currentBooking.is_dangerous });
	}
});

export const DangerousMeta = selector({
	key: keys.DANGEROUS_META,
	get: ({ get }) => get(NewBooking).dangerous_good_meta_data,
	set: ({ get, set }, newValue) => {
		const currentBooking = get(NewBooking);
		const dangerousGoodMetaData = currentBooking.dangerous_good_meta_data;
		if (currentBooking.dangerous_good_meta_data.includes(newValue as unknown as string)) {
			set(NewBooking, { ...currentBooking, dangerous_good_meta_data: [...dangerousGoodMetaData.filter((option) => option !== newValue as unknown as string)] })
		} else {
			set(NewBooking, { ...currentBooking, dangerous_good_meta_data: [...dangerousGoodMetaData, newValue as unknown as string] });
		}
	}
});

export const ShipperQuoteSessionId = selector({
	key: keys.SHIPPER_QUOTE_SESSION_ID,
	get: ({ get }) => get(NewBooking).shipper_quote_session_id,
	set: ({ get, set }, newValue) => {
		console.log('ShipperQuoteSessionId', newValue);
		set(NewBooking, {
			...get(NewBooking),
			shipper_quote_session_id: newValue as number,
		});
	},
});

export const FormValidation = atom({
	key: keys.FORM_VALIDATION,
	default: {
		validateForm: false,
		stops: [],
	}
});

export const FormValidationSelector = selector({
	key: keys.FORM_VALIDATION_SELECTOR,
	get: ({ get }) => get(FormValidation),
	set: ({ set }, newValue) => {
		set(FormValidation, newValue);
	},
});

export const ApiFormErrors = atom({
	key: keys.API_FORM_ERRORS,
	default: { errors: [], }
});

import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { InputField, Spacer } from '@components';
import { SPACER_DIRECTION } from '@types';

type FilterProps = {
    meta: any;
    header: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterString = (props:FilterProps) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const [newFields, setNewFields] = useState(0);
	const [inputVals, setInputVals] = useState<any[]>([]);
	const fullName = meta?.columnFullName;
	const updateInputVal = (e:any, i:number) => {
		const newFilterData = { ...filterData };
		const cloned = [...inputVals];
		cloned[i] = e.target.value;
		setInputVals(cloned);
		newFilterData.value = cloned;
		newFilterData.repeats = newFields;
		newFilterData.columnField = meta?.databaseLocation ?? header;
		newFilterData.operatorValue = newFields === 0 ? 'contains' : 'In';
		onChange(newFilterData);
	}
	const changeFieldCount = (add:boolean, key:number = 0) => {
		const newFilterData = { ...filterData };
		if (add) {
			setNewFields(newFields + 1);
			newFilterData.repeats = newFields + 1;
			newFilterData.value = inputVals;
		} else {
			if (key > 0) {
				const cloned = [...inputVals].filter((_, index) => {
					return index !== key;
				});
				setInputVals(cloned);
				newFilterData.value = cloned;
			}
			setNewFields(newFields - 1);
			newFilterData.repeats = newFields - 1;
		}
		newFilterData.columnField = meta?.databaseLocation;
		newFilterData.operatorValue = newFields === 0 ? 'contains' : 'In';
		onChange(newFilterData);
	}
	useEffect(() => {
		if (filterData?.value) {
			setInputVals(filterData.value || []);
			setNewFields(filterData.repeats || 0);
		} else {
			setInputVals([]);
			setNewFields(0);
		}
	}, [filterData]);
	return (
		<>
			{
				<div className={styles.inputContainer}>
					<div style={{ flex: 1 }}>
						<InputField name={'Filter-Option-String'} placeholder={meta?.filterOptions?.allowPlaceHolder ? meta?.filterOptions?.allowPlaceHolder : fullName !== undefined ? fullName : header} value={inputVals[0] || '' } onChange={(e) => updateInputVal(e, 0)} keepErrorSpace={false} />
					</div>
					{meta?.filterOptions?.allowAdditional && <>
						<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
						<button onClick={() => changeFieldCount(true)} className={styles.addMoreBtn} aria-label={'Add Lookup Field'}>+</button>
					</>}
				</div>
			}
			{
				newFields > 0 && new Array(newFields).fill(null).map((_, key) => {
					return <div key={key} className={styles.inputContainer} style={{ marginTop: '5px' }}>
						<div style={{ flex: 1 }}>
							<InputField name={`Filter-Option-String-${key}`} placeholder={meta?.filterOptions?.allowPlaceHolder ? meta?.filterOptions?.allowPlaceHolder : fullName !== undefined ? fullName : header} value={inputVals[key + 1]} onChange={(e) => updateInputVal(e, key + 1)} keepErrorSpace={false} />
						</div>
						<>
							<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
							<button onClick={() => changeFieldCount(false, key + 1)} className={styles.addMoreBtn} aria-label={'Remove Lookup Field'}>-</button>
						</>
					</div>
				})
			}
		</>
	)
}

import { PropsWithChildren } from 'react';
import { TObject } from '@types';

type TProps = {
	columnSpan?: string | number;
	rowSpan?: string | number;
	justifySelf?: string;
	alignSelf?: string;
	placeSelf?: string;
}

export const Item = (props:PropsWithChildren<TProps>) => {
	const {
		children,
		columnSpan,
		rowSpan,
		justifySelf,
		alignSelf,
		placeSelf
	} = props;

	const getStyles = () => {
		const cellStyles:TObject = {};
		if (columnSpan) {
			cellStyles.gridColumn = `span ${columnSpan}`;
		}
		if (rowSpan) {
			cellStyles.gridRow = `span ${rowSpan}`;
		}
		if (justifySelf) {
			cellStyles.justifySelf = justifySelf
		}
		if (alignSelf) {
			cellStyles.alignSelf = alignSelf
		}
		if (placeSelf) {
			cellStyles.placeSelf = placeSelf
		}
		return cellStyles;
	}

	return (
		<div data-testid={'@Grid-Cell'} style={getStyles()}>
			{children}
		</div>
	);
}

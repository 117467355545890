import {
	createContext,
	useContext,
	PropsWithChildren,
	ReactElement
} from 'react';
import { FeatureFlag, FeatureFlagResolver } from '@types';
import { resolveFlag } from './Utils';

type TProviderProps = {
	flags: FeatureFlag[];
}

type TFlagProps = {
	name: string;
	on?: ReactElement | ((f:FeatureFlag | null) => ReactElement);
	off?: ReactElement | ((f:FeatureFlag | null) => ReactElement);
	test?: FeatureFlagResolver;
}

export const FeatureFlagContext = createContext<FeatureFlag[]>([]);

export const FlagsProvider = (props:PropsWithChildren<TProviderProps>) => {
	const { flags, children } = props;
	return <FeatureFlagContext.Provider value={flags}>
		{children}
	</FeatureFlagContext.Provider>
}

export const Flag = (props:PropsWithChildren<TFlagProps>) => {
	const {
		name,
		on,
		off,
		test,
		children
	} = props;
	const flags = useContext<FeatureFlag[]>(FeatureFlagContext);
	const flag:FeatureFlag = flags.find((flag) => flag.name === name) ?? { name: '', on: false };
	const isOn:boolean = resolveFlag(flag.on, test);

	return (
		<>
			{on !== undefined && isOn && typeof on === 'function' && on(flag)}
			{on !== undefined && isOn && typeof on !== 'function' && on}
			{off !== undefined && !isOn && typeof off === 'function' && off(flag)}
			{off !== undefined && !isOn && typeof off !== 'function' && off}
			{children !== undefined && isOn && children}
		</>
	);
}

type IconProps = {
	size?: number;
	color?: string;
	accent?: string;
}

export const OfloadIcon = (props:IconProps) => {
	const {
		size = 16,
		color = 'var(--brand-navy-default)',
		accent = 'var(--brand-navy-default)'
	} = props;
	return (
		<svg role={'img'} width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M10.663 2.936c.402.19.754.472 1.028.823.293.382.51.817.638 1.282.15.537.223 1.093.218 1.651h3.074a.306.306 0 0 0 .307-.311 7.6 7.6 0 0 0-.427-2.349 6.237 6.237 0 0 0-1.238-2.109A5.72 5.72 0 0 0 12.3.515 6.36 6.36 0 0 0 9.934.004a.307.307 0 0 0-.306.264l-.322 2.377a3.15 3.15 0 0 1 1.356.291z' fill={accent}/>
			<path d='M12.163 7.538a.607.607 0 0 0-.605.576 8.156 8.156 0 0 1-.285 1.808 5.665 5.665 0 0 1-.865 1.833 3.81 3.81 0 0 1-3.184 1.596 3.187 3.187 0 0 1-1.388-.29c-.4-.187-.75-.468-1.02-.818a3.722 3.722 0 0 1-.623-1.271 6.01 6.01 0 0 1-.213-1.657 8.84 8.84 0 0 1 .3-2.375c.173-.66.465-1.281.86-1.835a3.979 3.979 0 0 1 1.361-1.182 3.87 3.87 0 0 1 1.36-.41.608.608 0 0 0 .54-.523L8.69.858a8.106 8.106 0 0 0-2.196.296A8.022 8.022 0 0 0 4.539 2c-.6.363-1.148.805-1.63 1.315a8.13 8.13 0 0 0-1.24 1.719 8.826 8.826 0 0 0-.79 2.048 9.36 9.36 0 0 0-.276 2.303 7.663 7.663 0 0 0 .43 2.598c.27.774.69 1.486 1.238 2.094a5.798 5.798 0 0 0 1.97 1.404c.773.342 1.645.513 2.617.513a7.995 7.995 0 0 0 4.176-1.146 7.587 7.587 0 0 0 1.625-1.317c.484-.517.9-1.095 1.235-1.719a8.84 8.84 0 0 0 .786-2.043c.183-.73.276-1.478.277-2.23h-2.794z' fill={color}/>
		</svg>
	);
}

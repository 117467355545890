import { PODStatusChip, TempChip, Tooltip } from '@components/CORE/index';
import { CARGO_TYPE_ID, TOOLTIP_POSITION } from '@types';
import { formatTimeFromString, parseAndFormatDate } from '@utils/dateUtils';
import { getCargoDescription } from '@utils/getCargoDescription';
import { format } from 'date-fns';

import styles from './FSDetails.module.scss';

export const idToTemperatureStatus = (id: any) => {
  switch (id) {
    case 1:
      return { id, name: 'Ambient', temp_code: 'AMBIENT' };
    case 2:
      return { id, name: 'Chilled', temp_code: 'CHILLED' };
    case 3:
      return { id, name: 'Frozen', temp_code: 'FROZEN' };
    default:
      return { id: 0, name: 'Unknown', temp_code: 'UNKNOWN' };
  }
};

export const OptedInColumns = () => {
  return [
    {
      accessorKey: 'shipper_name',
      header: 'Shipper Name',
      enableSorting: false,
      size: 200,
      accessorFn: (row: any) => row?.shipper_name,
      meta: {
        databaseLocation: 'shipper_insurance.cost_centre',
        canFilter: true,
        filterOptions: { type: 'string' },
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: false,
      size: 120,
      cell: ({ row }: any) => {
        const status = row?.original?.status;
        const customClass =
          status === 'Active' ? styles.statusSuccess : styles.statusDefault;
        return (
          <span className={`${styles.statusChip} ${customClass}`}>
            {status}
          </span>
        );
      },
      meta: {
        databaseLocation: 'status',
        canFilter: false,
        filterOptions: { type: 'string' },
      },
    },
    {
      accessorKey: 'opted_in_user',
      header: 'Opted In User',
      enableSorting: false,
      size: 120,
      accessorFn: (row: any) => row?.opted_in_user,
      meta: {
        databaseLocation: 'opted_in_user',
        canFilter: false,
        filterOptions: { type: 'string' },
      },
    },
    {
      accessorKey: 'opt_in_at',
      header: 'Opt In Date',
      enableSorting: false,
      size: 120,
      accessorFn: (row: any) => row?.opt_in_at,
      meta: {
        databaseLocation: 'opted_in_at',
        canFilter: true,
        filterOptions: {
          type: 'date',
          allowPlaceHolder: 'Please select a range',
        },
      },
    },
    {
      accessorKey: 'opted_out_user',
      header: 'Opted Out User',
      enableSorting: false,
      size: 120,
      accessorFn: (row: any) => row?.opted_out_user,
      meta: {
        databaseLocation: 'opted_out_user',
        canFilter: false,
        filterOptions: { type: 'string' },
      },
    },
    {
      accessorKey: 'opt_out_at',
      header: 'Opt Out Date',
      enableSorting: false,
      size: 120,
      cell: ({ row }: { row: any }) => {
		const date = row?.original?.opt_out_at;
		return date ? format(new Date(date), 'yyyy-MM-dd') : '';
      },
      meta: {
        databaseLocation: 'opted_out_at',
        canFilter: true,
        filterOptions: {
          type: 'date',
          allowPlaceHolder: 'Please select a range',
        },
      },
    },
    {
      accessorKey: 'opt_out_effective_date',
      header: 'Opt Out Effective Date',
      accessorFn: (row: any) => row?.opt_out_effective_date,
      enableSorting: false,
      size: 140,
      meta: {
        databaseLocation: 'opt_out_effective_date',
        canFilter: true,
        filterOptions: {
          type: 'date',
          allowPlaceHolder: 'Please select a range',
        },
      },
    },
  ];
};

export const coveredColumns = () => {
  return [
    {
      accessorKey: 'ShipperName',
      header: 'Shipper Name',
      enableSorting: false,
      size: 150,
      accessorFn: (row: any) => row?.shipper?.company?.business_name,
      meta: {
        databaseLocation: 'shipper_company.trade_name',
        canFilter: true,
        filterOptions: {
          type: 'string',
        },
      },
    },
    {
      accessorKey: 'reference',
      header: 'AUSH Reference',
      enableSorting: false,
      size: 145,
      accessorFn: (row: any) => row?.reference,
      meta: {
        databaseLocation: 'reference',
        canFilter: false,
      },
    },
    {
      accessorKey: 'PickUpDateTime',
      header: 'Pick Up Date/Time',
      enableSorting: false,
      size: 150,
      cell: ({ row }: any) => {
        return (
          <div>
            <span>
              {parseAndFormatDate(
                row?.original?.cargos[0]?.pickup_at,
                'DATE_WITH_SHORT_MONTH_NAME'
              )}
            </span>{' '}
            <span>
              {formatTimeFromString(row?.original?.cargos[0]?.pick_from)} -{' '}
              {formatTimeFromString(row?.original?.cargos[0]?.pick_to)}
            </span>
          </div>
        );
      },
      meta: {
        databaseLocation: 'pickup_at',
        canFilter: true,
        filterOptions: {
          type: 'date',
        },
      },
    },
    {
      accessorKey: 'PickUpLocation',
      header: 'Pick Up Location',
      enableSorting: false,
      size: 150,
      cell: ({ row }: any) => {
        return row?.original?.cargos[0]?.pickup?.address ?? '';
      },
      meta: {
        databaseLocation: 'address',
        canFilter: false,
      },
    },
    {
      accessorKey: 'DropOffDateTime',
      header: 'Drop Off Date/Time',
      enableSorting: false,
      size: 150,
      cell: ({ row }: any) => {
        return (
          <div>
            <span>
              {parseAndFormatDate(
                row?.original?.cargos[0]?.drop_at,
                'DATE_WITH_SHORT_MONTH_NAME'
              )}
            </span>{' '}
            <span>
              {formatTimeFromString(row?.original?.cargos[0]?.drop_from)} -{' '}
              {formatTimeFromString(row?.original?.cargos[0]?.drop_to)}
            </span>
          </div>
        );
      },
      meta: {
        databaseLocation: 'drop_at',
        canFilter: true,
        filterOptions: {
          type: 'date',
        },
      },
    },
    {
      accessorKey: 'price',
      header: 'Shipment Price',
      enableSorting: false,
      size: 140,
      cell: ({ row }: any) => {
        return row?.original?.price ? `$${row?.original?.price}` : '';
      },
      meta: {
        databaseLocation: 'price',
        canFilter: false,
      },
    },
    {
      accessorKey: 'CargoDetails',
      header: 'Cargo Detail',
      enableSorting: false,
      size: 130,
      cell: ({ row }: any) => {
        let qty: any = 0;
        if (row?.original?.cargos?.[0]?.cargo_type_id === CARGO_TYPE_ID.Loose) {
          qty = '';
        } else {
          row?.original?.cargos?.map((cargo: any) => {
            qty += cargo?.qty ?? 0;
          });
        }
        return (
          <div className={styles.alignRow}>
            <Tooltip
              text={idToTemperatureStatus(row?.original?.temperature_id).name}
              position={TOOLTIP_POSITION.BOTTOM}
              wrapperStyles={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TempChip name={row?.original?.temperature_id} iconOnly={true} />
            </Tooltip>
            <Tooltip
              text={getCargoDescription(row?.original)}
              position={TOOLTIP_POSITION.BOTTOM}
              wrapperStyles={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {qty}
            </Tooltip>
          </div>
        );
      },
      meta: {
        databaseLocation: 'Cargo',
        canFilter: false,
      },
    },
    {
      accessorKey: 'status',
      header: 'POD Status',
      enableSorting: false,
      size: 130,
      cell: ({ row }: any) => {
        return (
          (row?.original?.admin_pod_status || row?.original?.pod_status) && (
            <PODStatusChip
              status={
                row?.original?.admin_pod_status
                  ? row?.original?.admin_pod_status?.name
                  : row?.original?.pod_status?.name
              }
              iconOnly={true}
            />
          )
        );
      },
      meta: {
        databaseLocation: 'status',
        canFilter: false,
      },
    },
    {
      accessorKey: 'CarrierName',
      header: 'Carrier Name',
      enableSorting: false,
      size: 150,
      accessorFn: (row: any) =>
        row?.manifest?.master_manifest?.carrier?.company?.business_name,
      meta: {
        databaseLocation: 'CarrierName',
        canFilter: false,
      },
    },
    {
      accessorKey: 'DriverInstruction',
      header: 'Driver Instruction',
      accessorFn: (row: any) => row?.cargos[0]?.pickup?.location?.driver_instruction,
      enableSorting: false,
      size: 150,
      meta: {
        databaseLocation: 'instructions',
        canFilter: false,
      },
    },
  ];
};

import { useNavigate } from 'react-router-dom';

type TProps = {
	path: string;
}

const Redirect = (props: TProps) => {
	const { path } = props;
	const navigate = useNavigate();
	navigate(path);
	return null;
}

export default Redirect;

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { navigator } from '@types';

const transportNav = () => {
	const root = `/transport`;
	return [
		{
			label: 'All Loads',
			icon: (color:string) => <LocalShippingOutlinedIcon style={{ color: color }} />,
			link: `${root}/all_loads`,
			enabled: true,
			activeURL: 'all_loads'
		},
		{
			label: 'To be verified',
			icon: (color:string) => <VerifiedOutlinedIcon style={{ color: color }} />,
			link: `${root}/to_be_verfied`,
			enabled: true,
			activeURL: 'to_be_verfied'
		},
		{
			label: 'POD to be assigned',
			icon: (color:string) => <DescriptionOutlinedIcon style={{ color: color }} />,
			link: `${root}/pod_tobe_assigned`,
			enabled: true,
			activeURL: 'pod_tobe_assigned'
		},
		{
			label: 'Rejected PODs',
			icon: (color:string) => <CancelOutlinedIcon style={{ color: color }} />,
			link: `${root}/rejected_pods`,
			enabled: true,
			activeURL: 'rejected_pods'
		},
		{
			label: 'Cancelled Loads',
			icon: (color:string) => <CancelPresentationOutlinedIcon style={{ color: color }} />,
			link: `${root}/cancelled_loads`,
			enabled: true,
			activeURL: 'cancelled_loads'
		}
	] as navigator[];
}

export default transportNav;

import { filterString, filterRange, filterSelect } from './';

export const localFilter = (rows:any, columnIds:any, filterOpts: any):boolean => {
	if (typeof filterOpts === 'boolean') return true;
	const filterType = filterOpts?.meta?.filterOptions?.type ?? 'string'

	let filtered
	switch (filterType) {
	case 'range':
		filtered = filterRange(rows, columnIds, filterOpts);
		break;
	case 'select':
		filtered = filterSelect(rows, columnIds, filterOpts);
		break;
	case 'date':
	case 'location':
	case 'shipperLookup':
	case 'carrierLookup':
	case 'userLookup':
	case 'selectMultiple':
	case 'boolean':
	case 'assignedToMe':
		filtered = true;
		break;
	default:
		filtered = filterString(rows, columnIds, filterOpts);
	}
	return filtered;
}

import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import styles from './Radio.module.scss';
import { Text, Caption, Spacer } from '@components';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { VALIDATION_ERROR } from '@types';
import { RadioGroup } from './RadioGroup';
import { ZodTypeAny } from 'zod';

interface RadioProps extends React.ComponentPropsWithoutRef<'input'> {
	name: string;
	label: ReactNode;
	value: string;
	onChange: (e:ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	activeColor?: string;
	inActiveColor?: string;
	onFocus?: (e:FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e:FocusEvent<HTMLInputElement>) => void;
	validations?: ZodTypeAny;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
	disabled?: boolean;
}

const RadioButton = (props:RadioProps) => {
	const {
		name,
		label,
		value,
		onChange,
		checked = false,
		activeColor = 'var(--brand-orange-default)',
		inActiveColor = 'var(--neutral-03)',
		onFocus,
		onBlur,
		validations,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace,
		disabled
	} = props;

	const onFieldFocus = (e:FocusEvent<HTMLInputElement>) => {
		if (onFocus) onFocus(e);
	}
	const onFieldBlur = (e:FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(e);
		if (validations) {
			const valid = validations.safeParse(value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}

	return (
		<>
			<label data-testid={'@Ofload-Radio-Label'} className={styles.inputContainer}>
				<input
					type={'radio'}
					value={value}
					name={name}
					checked={checked}
					onChange={onChange}
					className={styles.input}
					onFocus={onFieldFocus}
					onBlur={onFieldBlur}
					disabled={disabled}
				/>
				{checked && <RadioButtonCheckedIcon data-testid={`@Ofload-Radio-Checked`} className={styles.icons} style={{ color: disabled ? 'var(--neutral-03)' : activeColor }} />}
				{!checked && <RadioButtonUncheckedIcon data-testid={`@Ofload-Radio-UnChecked`} className={styles.icons} style={{ color: disabled ? 'var(--neutral-03)' : inActiveColor }} />}
				<Text>
					{label}
				</Text>
			</label>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
		</>
	);
}

RadioButton.Group = RadioGroup;

export const Radio = RadioButton;
import { atom, selector } from 'recoil';

/* eslint-disable */
const keys = {
	JWT:			'@Ofload/JWT',
	USERID:			'@Ofload/UserID',
	USERTYPE:			'@Ofload/User/UserType',
	COMPANYID:		'@Ofload/CompanyID',
	ISADMIN:		'@Ofload/IsAdmin',
	USERNAME:		'@Ofload/UserName',
	COMPANYNAME:	'@Ofload/CompanyName',
  	USER_ROLES: 	'@Ofload/UserRoles',
	WAIT_FOR_GUEST:	'@Ofload/WaitForGuest',
	AUTHENTICATED_USER:	'@Ofload/User/CurrentUser'
}
/* eslint-enable */

export const authenticatedUser = atom<AuthenticatedUser | null>({
	key: keys.AUTHENTICATED_USER,
	default: null
});

export const JWT = atom<string | null>({
	key: keys.JWT,
	default: null
});

export const UserID = atom<number | string | null>({
	key: keys.USERID,
	default: null
});

export const JWTUserType = atom<number | string | null>({
	key: keys.USERTYPE,
	default: null
});

export const CompanyID = atom<number | string | null>({
	key: keys.COMPANYID,
	default: null
});

export const IsAdmin = atom<boolean>({
	key: keys.ISADMIN,
	default: false
});

export const UserName = selector<string>({
	key: keys.USERNAME,
	get: ({ get }) => {
		const user = get(authenticatedUser);
		return user ? `${user.team.first_name} ${user.team.last_name}` : '';
	}
});

export const CompanyName = selector<string>({
	key: keys.COMPANYNAME,
	get: ({ get }) => {
		const user = get(authenticatedUser);
		return user ? user.company.company_name : '';
	}
});

export const UserRoles = atom<string[]>({
	key: keys.USER_ROLES,
	default: []
});

export const WaitForGuest = atom<boolean>({
	key: keys.WAIT_FOR_GUEST,
	default: true
});

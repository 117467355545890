export const waitForPendoLoad = (callback: any, maxAttempts: number = 10, interval: number = 100) => {
	let attempts = 0;

	const intervalId = setInterval(function () {
		if ((window as any).pendo && (window as any).pendo.initialize) {
			clearInterval(intervalId);
			callback();
		} else if (++attempts >= maxAttempts) {
			clearInterval(intervalId);
			console.warn('Pendo did not load in the expected timeframe.');
		}
	}, interval);
}

import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { Text, Switch } from '@components/index';

type FilterProps = {
    meta: any;
    header: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterBoolean = (props:FilterProps) => {
	const {
		meta,
		header,
		filterData,
		onChange
	} = props;
	const fullName = meta?.columnFullName;
	const [switchChecked, setSwitchChecked] = useState(false);
	const changeSwitchValue = () => {
		setSwitchChecked(!switchChecked);
		if (meta.canReturnFalse) {
			onChange({
				value: !switchChecked,
				columnField: meta?.databaseLocation,
				operatorValue: 'is'
			});
		} else {
			onChange(!switchChecked ? {
				value: !switchChecked,
				columnField: meta?.databaseLocation,
				operatorValue: 'is'
			} : null);
		}
	}
	useEffect(() => {
		setSwitchChecked(filterData?.value || false);
	}, [filterData]);
	return (
		<div className={styles.filterLabel}>
			<Text text={fullName !== undefined ? fullName : header} />
			<Switch name={'Filter-Boolean-Option'} value={fullName !== undefined ? fullName : header} checked={switchChecked} onChange={() => changeSwitchValue()} />
		</div>
	);
}

type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const PODNotDeliveredIcon = (props:IconProps) => {
	const {
		width = 18,
		height = 18,
		color = 'var(--func-yellow-dark)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 18 18'} xmlns={'http://www.w3.org/2000/svg'}>
			<path
				d="M5.67901 3.93041L4.7416 3L3.3395 4.39163L1.9374 3L1 3.93041L2.4021 5.32204L1 6.71368L1.9374 7.64408L3.3395 6.26835L4.7416 7.64408L5.67901 6.71368L4.29293 5.32204L5.67901 3.93041Z"
				fill={color}
			/>
			<path
				d="M14.9009 6.65021H12.7937V3.86694H7.07313C7.2494 4.29636 7.34554 4.76554 7.35355 5.25858H11.3916V11.517H6.62446C6.42416 11.3102 6.19983 11.1432 5.94344 11.016C5.68706 10.8887 5.39863 10.8251 5.07815 10.8251C4.75767 10.8251 4.47725 10.8887 4.22086 11.016C3.96448 11.1432 3.74014 11.3102 3.53984 11.517H2.979V9.27444C2.48226 9.23468 2.00955 9.09949 1.5769 8.88478V12.9165H2.979C2.979 13.4891 3.18732 13.9821 3.59593 14.3877C4.00454 14.7933 4.50128 15 5.07815 15C5.65501 15 6.15977 14.7933 6.56838 14.3877C6.97699 13.9821 7.1853 13.4891 7.1853 12.9165H11.3916C11.3916 13.4891 11.5999 13.9821 12.0085 14.3877C12.4171 14.7933 12.9139 15 13.4907 15C14.0676 15 14.5724 14.7933 14.981 14.3877C15.3896 13.9821 15.5979 13.4891 15.5979 12.9165H17V9.43348L14.9009 6.65021ZM5.5829 13.4096C5.4467 13.5448 5.27845 13.6084 5.07815 13.6084C4.87785 13.6084 4.71761 13.5448 4.5814 13.4096C4.4452 13.2744 4.3811 13.1074 4.3811 12.9165C4.3811 12.7257 4.4452 12.5507 4.5814 12.4156C4.71761 12.2804 4.88586 12.2168 5.07815 12.2168C5.27043 12.2168 5.4467 12.2804 5.5829 12.4156C5.71911 12.5507 5.7832 12.7177 5.7832 12.9165C5.7832 13.1153 5.71911 13.2744 5.5829 13.4096ZM13.9955 13.4096C13.8593 13.5448 13.691 13.6084 13.4907 13.6084C13.2904 13.6084 13.1302 13.5448 12.994 13.4096C12.8578 13.2744 12.7937 13.1074 12.7937 12.9165C12.7937 12.7257 12.8578 12.5507 12.994 12.4156C13.1302 12.2804 13.2985 12.2168 13.4907 12.2168C13.683 12.2168 13.8593 12.2804 13.9955 12.4156C14.1317 12.5507 14.1958 12.7177 14.1958 12.9165C14.1958 13.1153 14.1317 13.2744 13.9955 13.4096ZM12.7937 10.1333V8.04185H14.1958L15.7742 10.1333H12.7937Z"
				fill={color}
			/>
		</svg>
	);
};
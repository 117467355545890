import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import styles from './Switch.module.scss';
import { Text, Caption, Spacer } from '@components';
import { VALIDATION_ERROR, TObject } from '@types';
import { ZodTypeAny } from 'zod';

interface SwitchProps extends React.ComponentPropsWithoutRef<'input'> {
	name: string;
	label?: string | ReactNode;
	labelBefore?: boolean;
	value: string;
	onChange: (e:ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	activeColor?: string;
	inActiveColor?: string;
	onFocus?: (e:FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e:FocusEvent<HTMLInputElement>) => void;
	validations?: ZodTypeAny;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
}

export const Switch = (props:SwitchProps) => {
	const {
		value,
		onChange,
		activeColor = 'var(--brand-orange-default)',
		inActiveColor = 'var(--neutral-03)',
		checked = false,
		label = '',
		labelBefore = false,
		name,
		onFocus,
		onBlur,
		validations,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace
	} = props;
	const switchStyles = () => {
		const newStyles:TObject = {};
		if (checked) {
			newStyles.backgroundColor = activeColor;
		} else {
			newStyles.backgroundColor = inActiveColor;
		}
		return newStyles;
	}
	const switchPosStyle = () => {
		const newStyles:TObject = {};
		if (label !== '') {
			labelBefore ? newStyles.marginLeft = '10px' : newStyles.marginRight = '10px';
		}
		return newStyles;
	}
	const onFieldFocus = (e:FocusEvent<HTMLInputElement>) => {
		if (onFocus) onFocus(e);
	}
	const onFieldBlur = (e:FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(e);
		if (validations) {
			const valid = validations.safeParse(value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}
	return (
		<>
			<div data-testid={'@Ofload-Switch'} className={styles.row}>
				{labelBefore && label && <Text data-testid={'@Ofload-Switch-Label-Left'}>{label}</Text>}
				<label data-testid={'@Ofload-Switch-Label'} className={styles.switch} style={switchPosStyle()}>
					<input type={'checkbox'} value={value} onChange={onChange} checked={checked} name={name} onFocus={onFieldFocus} onBlur={onFieldBlur} />
					<span data-testid={'@Ofload-Switch-Slider'} className={styles.slider} style={switchStyles()} />
				</label>
				{!labelBefore && label && <Text data-testid={'@Ofload-Switch-Label-Right'}>{label}</Text>}
			</div>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
		</>
	);
}

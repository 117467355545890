import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { Text, Switch } from '@components/index';

type FilterProps = {
    header: any;
    meta: any;
    filterData: any;
    onChange: (val: any) => void;
}

export const FilterMultipleBoolean = (props: FilterProps) => {
	const {
		meta,
		filterData,
		onChange
	} = props;

	const [switchOptions, setSwitchOptions] = useState<any[]>([]);
	const filterOptions = meta?.filterOptions?.options ?? [];
	const changeSwitchValue = (optionIndex: number) => {
		const newOptions = [...switchOptions];
		newOptions[optionIndex].checked = !newOptions[optionIndex].checked;
		setSwitchOptions(newOptions);

		const filterResult = newOptions.filter((opt) => opt.checked).map((opt) => opt.value);
		const newFilterData = {
			...filterData,
			value: filterResult.join(', '),
			columnField: meta?.databaseLocation,
			operatorValue: filterResult.length > 1 ? 'In' : 'Is'
		};
		onChange(newFilterData);
	};

	useEffect(() => {
		if (filterData?.value) {
			const checkedValues = filterData.value.split(', ');
			const newOptions = filterOptions.map((option: any) => ({
				...option,
				checked: checkedValues.includes(option.value.toString())
			}));
			setSwitchOptions(newOptions);
		} else {
			const newOptions = filterOptions.map((option: any) => ({
				...option,
				checked: false
			}));
			setSwitchOptions(newOptions);
		}
	}, [meta, filterData]);

	return (
		<>
			{switchOptions.map((option, index) => (
				<div key={index}
					className={styles.filterLabel}
					style={{ marginBottom: index !== switchOptions.length - 1 ? '20px' : '5px' }}
				>
					<Text text={option.label} />
					<Switch
						name={'Filter-Multiple_Boolean'}
						value={option.value}
						checked={option.checked}
						onChange={() => changeSwitchValue(index)}
					/>
				</div>
			))}
		</>
	);
};

import { useEffect } from 'react';
import styles from './Toast.module.scss';
import toastUI from 'react-hot-toast';
import headlessToast, {
	useToaster,
	Toast as ToastType,
	Renderable,
	ValueOrFunction,
	ToastOptions
} from 'react-hot-toast/headless';
import {
	SuccessChip,
	ErrorChip,
	WarnChip,
	InfoChip
} from '@components';

type Message = ValueOrFunction<Renderable, ToastType | 'info' | 'warn'>;
type ToastHandler = (message: Message, options?: ToastOptions) => string;

interface hToastType extends ToastType {
	info: ToastHandler;
	warn: ToastHandler;
	error: ToastHandler;
	success: ToastHandler;
	dismiss(toastId?: string): void;
	remove(toastId?: string): void;
}

export const ToastUI = () => {
	const { toasts } = useToaster();
	useEffect(() => {
		toasts.filter((toast) => toast.visible).map((t) => {
			switch (t.type) {
			case 'success':
				return toastUI(<SuccessChip text={(t.message as string)} />, { className: styles.toast, id: t.id });
			case 'error':
				return toastUI(<ErrorChip text={(t.message as string)} />, { className: styles.toast, id: t.id });
			default:
				return toastUI(t.message);
			}
		});
	}, [toasts]);

	return <div></div>
}

(headlessToast as unknown as hToastType).info = (message: Message) => {
	return toastUI(<InfoChip text={(message as string)} />, { className: styles.toast });
}
(headlessToast as unknown as hToastType).warn = (message: Message) => {
	return toastUI(<WarnChip text={(message as string)} />, { className: styles.toast });
}

export const toast = headlessToast as unknown as hToastType; //eslint-disable-line

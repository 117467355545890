import { atom, selector } from 'recoil';

/* eslint-disable */
const keys = {
	DEMO:       '@Ofload/DEMO_CARRIER_KEY',
	DEMONAME:   '@Ofload/DEMO_CARRIER_NAME'
}
/* eslint-enable */

export const DemoCarrierAtom = atom({
	key: keys.DEMO,
	default: {
		firstName: 'Ofload',
		lastName: 'Demo'
	}
});

export const DemoCarrierName = selector({
	key: keys.DEMONAME,
	get: ({ get }) => {
		const demo = get(DemoCarrierAtom);
		return `${demo.firstName} ${demo.lastName}`;
	}
});

import React, { useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import {
	Button,
	InputField,
	Spacer,
	Text,
	H1
} from '@components';
import { BUTTON_TYPES, INPUT_ICON_POS, SPACER_DIRECTION, TEXT_WEIGHT } from '@types';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import TuneIcon from '@mui/icons-material/TuneOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import { PanelState } from '../Table';

type HeaderProps = {
    tableTitle?: string;
    canSearch?: boolean;
    panelOpen?: boolean;
    setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    setSettingsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    settingsPanel?: boolean;
    filterPanel?: boolean;
    canChangeDisplaySettings?: boolean;
    canFilter?: boolean;
    searchPlaceholder?: string | null;
    tableFilters?: any[];
    applyFilter: (id: string | null) => void;
    applyMatrixFilter?: (filter: any) => void;
    loadedFilter?: any;
    onSearch?: (value: string) => void;
    searchFromParent?: string;
    openPanel?: (panelName: 'filter' | 'settings' | 'matrix' | null) => void;
    closePanel?: () => void;
    panelState?: PanelState;
    opsMatrixFilters?: any[];
    buttonComponents?:any;
	fullWidthSearch?: boolean;
	filterFromSearchOn?: string[];
	filterFromSearch: (f:any) => void;
	downloadCsvPlugin?: React.ReactNode;
}

const Header = ({
	tableTitle,
	canSearch = true,
	canChangeDisplaySettings = true,
	canFilter = true,
	searchPlaceholder = null,
	tableFilters = [],
	applyFilter,
	applyMatrixFilter,
	loadedFilter,
	onSearch,
	searchFromParent = '',
	openPanel,
	closePanel,
	panelState,
	opsMatrixFilters,
	buttonComponents,
	fullWidthSearch,
	filterFromSearchOn,
	filterFromSearch,
	downloadCsvPlugin,
}: HeaderProps) => {
	const [searchVal, setSearchVal] = useState(searchFromParent);
	const [toggleFilterList, setToggleFilterList] = useState(false);
	const filterListContainer = useRef<any>(null);
	const savedFiltersButton = useRef<any>(null);
	const [filterSearchValue, setFilterSearchValue] = useState('');
	const search = (e: any) => {
		const val = e.target.value;
		if (onSearch) {
			onSearch(val);
			setSearchVal(val);
		}
	}

	const onPaste = (e:any) => {
		if (filterFromSearchOn && filterFromSearchOn.length >= 1) {
			e.preventDefault();
			e.stopPropagation();
			let cb = e.clipboardData.getData('text/plain');
			cb = cb.replace(/,/g, '');
			cb = cb.replace(/ {4}|[\t\n\r\s]/gm, ',');
			setSearchVal(cb);
			const newFilter:any = {}
			for (let i=0, l=filterFromSearchOn.length; i<l; ++i) {
				newFilter[`searchAsFilter${i}`] = {
					operatorValue: 'Contains',
					columnField: filterFromSearchOn[i],
					value: cb
				}
			}
			console.log(newFilter);
			filterFromSearch(newFilter);
		}
	}

	const ApplyFilter = (filterId: string) => {
		setToggleFilterList(false);
		applyFilter(filterId);
	}

	const handleApplyMatrixFilter = (filter: any) => {
		applyMatrixFilter?.(filter);
		setToggleFilterList(false);
	}

	const toggleSidePanel = (type: 'filter' | 'settings') => {
		if (panelState?.isOpen && panelState.activePanel === type) {
			closePanel?.();
			return;
		} else if (panelState?.isOpen && loadedFilter?.type === 'matrix' && type === 'filter') {
			closePanel?.();
			return;
		}
		if (loadedFilter && loadedFilter.type === 'matrix' && type === 'filter') {
			openPanel?.('matrix');
			return;
		}
		openPanel?.(type);
	}
	const clearLoadedFilter = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		applyFilter(null);
	}
	const openFilterPanel = () => {
		if (loadedFilter) {
			openPanel?.('filter');
			setToggleFilterList(false);
		}
	}
	const clearAll = () => {
		applyFilter(null);
		setToggleFilterList(false);
	}
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				(filterListContainer.current && !filterListContainer?.current?.contains(event.target)) &&
                (savedFiltersButton.current && !savedFiltersButton?.current?.contains(event.target))
			) {
				setToggleFilterList(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	useEffect(() => {
		if (!searchVal) {
			setSearchVal(searchFromParent);
		}
	}, [searchFromParent]);

	return (
		<header className={styles.header}>
			{!fullWidthSearch && <>
				<div className={styles.tableTitle}>
					{tableTitle !== '' && tableTitle !== undefined && typeof tableTitle === 'string' && <H1 weight={TEXT_WEIGHT.BOLD} text={tableTitle} />}
					{tableTitle !== '' && tableTitle !== undefined && typeof tableTitle !== 'string' && tableTitle}
				</div>
				<div className={styles.actionMenuContainer}>
					<div className={styles.buttonsContainer}>
						{
							buttonComponents && (
								<>
									{buttonComponents}
									<Spacer dir={SPACER_DIRECTION.VERTICAL} size={10} />
								</>
							)
						}
						{downloadCsvPlugin &&
							<>
								{downloadCsvPlugin}
								<Spacer dir={SPACER_DIRECTION.VERTICAL} size={10} />
							</>
						}
						{canFilter &&
							<button
								className={`
									${styles.button}
									${styles.ctaButton}
									${panelState?.isOpen && panelState.activePanel === 'filter' ? styles.activeBtn : ''}
								`}
								onClick={() => { toggleSidePanel('filter') }}
								aria-label={'Toggle Filter Side Panel'}
							>
								<FilterIcon />
								<Text text={'Filter'} />
							</button>
						}
						{canFilter && (tableFilters.length > 0 || loadedFilter || (opsMatrixFilters && opsMatrixFilters.length > 0)) &&
							<div className={styles.buttonDropDownContainer}>
								<Spacer dir={SPACER_DIRECTION.VERTICAL} size={10} />
								<button
									ref={savedFiltersButton}
									className={`${styles.button} ${toggleFilterList || (loadedFilter) ? styles.activeBtn : ''}`}
									onClick={() => { setToggleFilterList(!toggleFilterList) }}
									aria-label={'Toggle Filter List'}
								>
									{
										loadedFilter ?
											<BookmarkIcon style={{ color: 'var(--brand-orange-dark)' }} /> :
											<BookmarkBorderIcon style={{ color: toggleFilterList ? 'var(--brand-orange-dark)' : 'var(--brand-navy-default)' }} />
									}
									<Text
										text={
											loadedFilter
												? loadedFilter.type === 'matrix'
													? loadedFilter.name
													: loadedFilter.filterName
														? loadedFilter.filterName
														: 'Temp Filter'
												: 'Saved Filters'
										}
										color={toggleFilterList || (loadedFilter) ? 'var(--brand-orange-dark)' : 'var(--brand-navy-default)'}
									/>
									{loadedFilter && <CloseIcon onClick={(e) => { clearLoadedFilter(e) }} style={{ color: 'var(--brand-orange-dark)' }} />}
								</button>
								{toggleFilterList && <div ref={filterListContainer} className={styles.filterListContainer}>
									<div className={styles.inputWrapper}>
										<InputField
											name={'Filter Search Value'}
											value={filterSearchValue}
											onChange={(e: any) => setFilterSearchValue(e.target.value)}
											placeholder={'Start typing filter name'}
											Icon={SearchIcon}
											iconPos={INPUT_ICON_POS.RIGHT}
											className={styles.filterSearch}
											displayThin
											keepErrorSpace={false}
										/>
									</div>
									<div className={styles.filterScrollContainer}>
										{tableFilters.filter((filter) => filter.filterName.toLowerCase().includes(filterSearchValue.toLowerCase())).length > 0 && <>
											<div className={styles.filterHeading}>
												<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={'My Filters'} />
											</div>
											{tableFilters.filter((filter) => filter.filterName.toLowerCase().includes(filterSearchValue.toLowerCase())).map((filter, i) => {
												return <button className={styles.filterButton} key={i} onClick={() => ApplyFilter(filter.id)} aria-label={`Apply Filter ${filter.filterName}`}>{filter.filterName}</button>
											})}
										</>}
										{opsMatrixFilters && opsMatrixFilters.filter((filter) => filter.name.toLowerCase().includes(filterSearchValue.toLowerCase())).length > 0 && <>
											<div className={styles.filterHeading}>
												<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={'Matrix Filters'} />
											</div>
											{opsMatrixFilters.filter((filter) => filter.name.toLowerCase().includes(filterSearchValue.toLowerCase())).map((filter, i) => {
												return <button className={styles.filterButton} key={i} onClick={() => handleApplyMatrixFilter(filter)} aria-label={`Apply Filter ${filter.name}`}>{filter.name}</button>
											})}
										</>}
										{loadedFilter && <div className={styles.filterListButtons}>
											<Button type={BUTTON_TYPES.TERTIARY} text={'Clear All'} onClick={() => { clearAll() }} />
											{!loadedFilter.type && <Button type={BUTTON_TYPES.TERTIARY} text={'Edit Filters'} onClick={() => { openFilterPanel() }} />}
										</div>}
									</div>
								</div>}
							</div>}
						{canChangeDisplaySettings && <>
							<Spacer dir={SPACER_DIRECTION.VERTICAL} size={10} />
							<button
								className={`${styles.button} ${styles.ctaButton} ${panelState?.isOpen && panelState.activePanel === 'settings' ? styles.activeBtn : ''}`}
								onClick={() => { toggleSidePanel('settings') }}
								aria-label={'Toggle Settings Side Panel'}
							>
								<TuneIcon />
								<Text text={'Display settings'} />
							</button>
						</>}
					</div>
					<Spacer size={10} dir={SPACER_DIRECTION.VERTICAL} />
					{canSearch && <div className={styles.searchContainer}>
						<InputField name={'Search'} onPaste={(e) => { console.log(e); onPaste(e) }} displayThin className={styles.searchInput} data-testid={'search-shipment'} placeholder={searchPlaceholder ? searchPlaceholder : `Search ${typeof tableTitle === 'string' ? tableTitle : ''}`} value={searchVal} onChange={(e: any) => search(e)} Icon={SearchIcon} keepErrorSpace={false} />
					</div>}
				</div>
			</>}
			{fullWidthSearch && canSearch && <InputField name={'Search'} onPaste={onPaste} displayThin stretch data-testid={'search-shipment'} placeholder={searchPlaceholder ? searchPlaceholder : `Search ${typeof tableTitle === 'string' ? tableTitle : ''}`} value={searchVal} onChange={(e: any) => search(e)} Icon={SearchIcon} keepErrorSpace={false} />}
		</header>
	)
}

export default Header;

import { getTruckSizeLabel } from './truckSizeTypesLabels';
import { TRUCK_SIZE } from '@types';

export const getTruckSizes = [
	{
		label: getTruckSizeLabel(TRUCK_SIZE.RIGID),
		value: 1,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.SINGLE),
		value: 2,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.B_DOUBLE),
		value: 3,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.SKELETON),
		value: 5,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.SIDELOADER),
		value: 6,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.B_DOUBLE_HIGH_CUBE),
		value: 7,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_20FT_COASTAL),
		value: 8,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_40FT_COASTAL),
		value: 9,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_46FT_COASTAL),
		value: 12,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.CONTAINER_48FT_COASTAL),
		value: 13,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.B_TRIPLE),
		value: 10,
	},
	{
		label: getTruckSizeLabel(TRUCK_SIZE.SUPER_B_DOUBLE),
		value: 11,
	}
];
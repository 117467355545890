import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIsLocal } from '@hooks';

type LinkPropsT = {
	domain?: 'eos' | 'spa';
	to: string;
	className?: string;
	target?: '_blank' | '_self' | '_parent' | '_top'
}

export const Link = (props:PropsWithChildren<LinkPropsT>) => {
	const {
		domain = 'spa',
		to,
		className,
		target,
		children
	} = props;
	const isLocal = useIsLocal();
	let href = to;
	if (isLocal) {
		href = `http://localhost:3000${to}`;
	}
	return (
		<>
			{domain === 'spa' && <RouterLink to={to} className={className} target={target}>{children}</RouterLink>}
			{domain === 'eos' && <a href={href} className={className} target={target}>{children}</a>}
		</>
	);
}

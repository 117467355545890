import { useEffect, PropsWithChildren } from 'react';
import styles from './Radio.module.scss';
import { Spacer, Caption } from '@components';
import { VALIDATION_ERROR } from '@types';
import { ZodTypeAny } from 'zod';

type TProps = {
	selected?: any;
	name?: string;
	className?: string;
	validations?: ZodTypeAny;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
}

export const RadioGroup = (props:PropsWithChildren<TProps>) => {
	const {
		selected,
		name,
		className,
		validations,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace,
		children
	} = props;

	useEffect(() => {
		if (selected !== null && selected !== undefined && selected !== '') {
			if (!name) return;
			if (validations) {
				const valid = validations.safeParse(selected);
				if (!valid.success) {
					const issues = [];
					for (let i=0, l=valid.error.issues.length; i<l; ++i) {
						issues.push(valid.error.issues[i].message);
					}
					const error = {
						field: name,
						issues
					};
					if (onValidationError) {
						onValidationError(error, false);
					}
				} else if (onValidationSuccess) {
					onValidationSuccess({ field: name }, true);
				}
			}
		}
	}, [selected]); // eslint-disable-line

	return (
		<>
			<div className={className}>
				{children}
			</div>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
		</>
	)
}

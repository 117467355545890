import { StatusChip } from '@components/index';
import { POD_STATUS, CHIP_TYPE } from '@types';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import { 
	PODRejectedIcon,
	PODAwaitingVerificationIcon,
	PODNotAvailableIcon,
	PODNotDeliveredIcon,
} from '@img/icons';

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';

type TProps = {
	status: POD_STATUS;
	iconOnly?: boolean;
}

export const PODStatusChip = (props: TProps) => {
	const {
		status,
		iconOnly = false
	} = props;

	const POD_CHIPS = {
		[POD_STATUS.POD_VERIFIED]: {
			text: POD_STATUS.POD_VERIFIED,
			CustomIcon: TaskOutlinedIcon,
			type: CHIP_TYPE.SUCCESS,
			customIconColor: undefined
		},
		[POD_STATUS.POD_AUTO_APPROVED]: {
			text: POD_STATUS.POD_AUTO_APPROVED,
			CustomIcon: TaskOutlinedIcon,
			type: CHIP_TYPE.SUCCESS,
			customIconColor: undefined
		},
		[POD_STATUS.POD_REQUIRED]: {
			text: POD_STATUS.POD_REQUIRED,
			CustomIcon: UploadFileOutlinedIcon,
			type: CHIP_TYPE.WARN,
			customIconColor: undefined
		},
		[POD_STATUS.POD_AWAITING_VERIFICATION]: {
			text: POD_STATUS.AWAITING_VERIFICATION,
			CustomIcon: PODAwaitingVerificationIcon,
			type: CHIP_TYPE.WARN,
			customIconColor: undefined
		},
		[POD_STATUS.AWAITING_VERIFICATION]: {
			text: POD_STATUS.AWAITING_VERIFICATION,
			CustomIcon: PODAwaitingVerificationIcon,
			type: CHIP_TYPE.WARN,
			customIconColor: undefined
		},
		[POD_STATUS.POD_CHASING]: {
			text: POD_STATUS.POD_CHASING,
			CustomIcon: HistoryToggleOffOutlinedIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: undefined
		},
		[POD_STATUS.POD_IN_DISPUTE]: {
			text: POD_STATUS.POD_IN_DISPUTE,
			CustomIcon: ErrorOutlineOutlinedIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: undefined
		},
		[POD_STATUS.POD_REJECTED]: {
			text: POD_STATUS.POD_REJECTED,
			CustomIcon: PODRejectedIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: 'var(--func-red-dark)'
		},
		[POD_STATUS.POD_REJECTED_BY_SHIPPER]: {
			text: POD_STATUS.POD_REJECTED_BY_SHIPPER,
			CustomIcon: PODRejectedIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: 'var(--func-red-dark)'
		},
		[POD_STATUS.POD_CHASED_AND_ABANDONED]: {
			text: POD_STATUS.POD_CHASED_AND_ABANDONED,
			CustomIcon: BlockOutlinedIcon,
			type: CHIP_TYPE.NEUTRAL,
			customIconColor: undefined
		},
		[POD_STATUS.LOAD_NOT_DELIVERED]: {
			text: POD_STATUS.LOAD_NOT_DELIVERED,
			CustomIcon: PODNotDeliveredIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: 'var(--func-red-dark)'
		},
		[POD_STATUS.POD_SHIPPER_CHASING]: {
			text: POD_STATUS.POD_SHIPPER_CHASING,
			CustomIcon: HistoryToggleOffOutlinedIcon,
			type: CHIP_TYPE.ERROR,
			customIconColor: 'var(--func-red-dark)'
		},
		[POD_STATUS.POD_NOT_AVAILABLE]: {
			text: POD_STATUS.POD_NOT_AVAILABLE,
			CustomIcon: PODNotAvailableIcon,
			type: CHIP_TYPE.NEUTRAL,
			customIconColor: undefined
		},
		[POD_STATUS.UNKNOWN]: {
			text: POD_STATUS.UNKNOWN,
			CustomIcon: DoNotDisturbOnOutlinedIcon,
			type: CHIP_TYPE.NEUTRAL,
			customIconColor: undefined
		},
	}

	return (
		<StatusChip
			text={POD_CHIPS[status]?.text}
			type={POD_CHIPS[status]?.type}
			CustomIcon={POD_CHIPS[status]?.CustomIcon}
			showIcon
			data-testID="@Ofload-PODStatusChip"
			iconOnly={iconOnly}
			customIconColor={POD_CHIPS[status]?.customIconColor}
		/>
	);
}

import {
	UnallocatedCargo,
	TEOSCargo,
	TotalQtyAndWeight,
	CARGO_TYPE_ID
} from '@types';

export const getTotalQuantityAndWeight = (cargos: UnallocatedCargo[] | TEOSCargo[], variant: number): TotalQtyAndWeight => {
	return cargos.reduce((accumulator: TotalQtyAndWeight, cargo: UnallocatedCargo | TEOSCargo) => {
		const palletsCount = variant === CARGO_TYPE_ID.Pallets ?
			(cargo as UnallocatedCargo).pallet_qty || (cargo as UnallocatedCargo).qty :
			(cargo as TEOSCargo).pallet_quantity;
		const palletsSpaces = (variant === CARGO_TYPE_ID.Pallets || variant === CARGO_TYPE_ID.Other) ?
			(cargo as UnallocatedCargo).qty :
			(cargo as TEOSCargo).pallet_space_quantity;

		accumulator.totalPallets += palletsCount;
		accumulator.totalQty += palletsSpaces;
		accumulator.totalWeight += typeof cargo.total_weight === 'number'
			? cargo.total_weight
			: parseFloat(cargo.total_weight);

		return accumulator;
	}, {
		totalQty: 0,
		totalWeight: 0,
		totalPallets: 0
	});
}

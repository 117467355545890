import { useState, useEffect } from 'react';
import styles from './FilterPanel.module.scss';
import { H2, Spacer, Button, InputField } from '@components/index';
import { PanelState } from '../Table';
import CloseIcon from '@mui/icons-material/Close';
import { FilterOption } from './FilterOptions';
import { BUTTON_SIZE, BUTTON_TYPES, SPACER_DIRECTION } from '@types';
type FilterPanelProps = {
    fromTableColumns: any;
    setPanelOpen: (b:boolean) => void;
    setFilterPanel: (b:boolean) => void;
    saveFilters?: (f:any) => void;
    applyFilter?: any;
    clearAppliedFilter: (n:null) => void;
    deleteFilter: (id:string) => void;
    onApplyFilter?: (filter:any) => void;
    openPanel?: (panelName: 'filter' | 'settings' | 'matrix' | null) => void;
    closePanel?: () => void;
    panelState?: PanelState;
}

const FilterPanel = (props:FilterPanelProps) => {
	const {
		fromTableColumns,
		saveFilters,
		applyFilter = null,
		clearAppliedFilter,
		deleteFilter,
		onApplyFilter,
		closePanel
	} = props;
	const [filterData, setFilterData] = useState<any>({});
	const [filterName, setFilterName] = useState('');
	const handleClosePanel = () => {
		closePanel?.();
	}
	const onChange = (key:string, value:any) => {
		const newFilterData = { ...filterData };
		newFilterData[key] = value;
		setFilterData(newFilterData);
	}
	const clearFilter = () => {
		setFilterData({});
		clearAppliedFilter(null);
	}
	const saveFilter = () => {
		const clonedData = { ...filterData };
		if (filterData.filterName !== undefined && filterData.filterName !== filterName) {
			clonedData.filterName = filterName;
		}
		if (saveFilters) saveFilters(clonedData);
	}
	const isEmptyObj = (obj:any) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}
	useEffect(() => {
		if (applyFilter !== null) {
			setFilterData(applyFilter);
		} else {
			setFilterData({});
		}
	}, [applyFilter]);
	useEffect(() => {
		setFilterName(filterData.filterName);
	}, [filterData]);
	return (
		<div className={styles.container}>
			<div className={styles.padded}>
				<button className={styles.closeBtn} onClick={handleClosePanel} aria-label={'Close Filter Panel'}><CloseIcon /></button>
				<H2 text={'Filter'} />
				<Spacer size={20} />
				{!isEmptyObj(filterData) && filterData.id && <>
					<InputField name={'Name of Filter'} label={'Name of Filter'} value={filterName} onChange={(e) => { setFilterName(e.target.value) }} />
					<Spacer size={20} />
				</>}
				{fromTableColumns.map((column:any, i:number) => {
					return <FilterOption
						key={i}
						columnId={column.accessorKey}
						filterData={filterData[column.accessorKey] || null}
						update={onChange}
						meta={column?.meta ?? {}}
						header={column?.header}
						canFilter={column?.meta?.canFilter ?? true}
					/>
				})}
			</div>
			<div className={styles.buttonContainer}>
				{!isEmptyObj(filterData) && <>
					<Button type={BUTTON_TYPES.TERTIARY} size={BUTTON_SIZE.SMALL} text={'Clear'} onClick={clearFilter} />
				</>}
				{!isEmptyObj(filterData) && filterData.id !== undefined && <>
					<Spacer size={20} dir={SPACER_DIRECTION.VERTICAL} />
					<Button size={BUTTON_SIZE.SMALL} text={'Delete Filter'} onClick={() => { deleteFilter(filterData.id) }} type={BUTTON_TYPES.SECONDARY} />
				</>}
				<Spacer size={10} dir={SPACER_DIRECTION.VERTICAL} />
				<Button size={BUTTON_SIZE.SMALL} type={BUTTON_TYPES.SECONDARY} text={filterData?.filterName ? 'Save & Apply' : 'Save'} onClick={saveFilter} disabled={isEmptyObj(filterData)} />
				{!isEmptyObj(filterData) && filterData.id === undefined && <>
					<Spacer size={10} dir={SPACER_DIRECTION.VERTICAL} />
					<Button size={BUTTON_SIZE.SMALL} text={'Apply'} onClick={() => { if (onApplyFilter) onApplyFilter(filterData) }} />
				</>}
			</div>
		</div>
	);
}

export default FilterPanel;

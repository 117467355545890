import { ChangeEvent, useEffect, useState } from 'react';
import css from './FilterRateCardLane.module.scss';
import { Switch, TypeAhead } from '@components/FORM';
import { Tooltip } from '@components/CORE';
import { INPUT_ICON_POS, TOOLTIP_POSITION, TypeAheadOption } from '@types';
import { IconButton } from '@mui/material';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { getSearchRegion, searchPostcode } from '@utils/laneLookup';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from '@utils/debounce';

type filterValue = { origin: typeof EmptyStop; destination: typeof EmptyStop };

type FilterRateCardLaneProps = {
    header: any;
    meta: any;
    filterData: { value: string | undefined; option: filterValue } | undefined;
    onChange: (val: any) => void;
};

const FilterRateCardLane = ({ meta, filterData, onChange }: FilterRateCardLaneProps) => {
	const { origin = EmptyStop, destination = EmptyStop } = filterData?.option || {};
	const EmptyFilterData = {
		columnField: meta?.databaseLocation,
		operatorValue: 'like',
	};

	const onSwapped = () => {
		handleChanged({ origin: destination, destination: origin });
	};

	const handleChanged = (newValue: filterValue | undefined) => {
		const laneId = [newValue?.origin, newValue?.destination]
			.map((stop) => {
				const code = stop?.usePostcode ? 'P' : 'R';
				return stop?.lane ? `${code}${stop.lane.value}` : '';
			})
			.join(':');
		onChange({
			...(filterData || EmptyFilterData),
			value: laneId === ':' ? undefined : laneId,
			option: newValue,
		});
	};

	return (
		<div className={css.root}>
			<div className={css.withSwap}>
				<FilterRateCardStop
					onChange={(newOrigin) => handleChanged({ destination, origin: newOrigin })}
					placeholder='Pick-Up Location'
					stop={origin}
					label='Pick-up'
				/>
				<Tooltip position={TOOLTIP_POSITION.BOTTOM} text='Swap origin and destination'>
					<IconButton onClick={onSwapped}>
						<SwapHorizOutlinedIcon />
					</IconButton>
				</Tooltip>
			</div>
			<FilterRateCardStop
				onChange={(newDestination) => handleChanged({ origin, destination: newDestination })}
				placeholder='Drop-Off Location'
				stop={destination}
				label='Drop-off'
			/>
		</div>
	);
}

interface FilterRateCardStopProps {
    stop: typeof EmptyStop;
    onChange: (stop: typeof EmptyStop) => void;
    placeholder?: string;
    label: string;
}

const FilterRateCardStop = ({ stop, onChange, label }: FilterRateCardStopProps) => {
	const [value, setValue] = useState('');
	const [options, setOptions] = useState<TypeAheadOption[]>([]);
	const [loading, setLoading] = useState(false);
	const lookupAddress = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		const getOptions = stop.usePostcode ? debouncedSearchPostcode : debouncedSearchRegion;

		setLoading(true);
		getOptions(e.target.value)
			.then((opts) => {
				setOptions(opts);
			})
			.finally(() => {
				setLoading(false);
			})
	}

	useEffect(() => {
		setValue((stop.lane?.label || '') as string)
	}, [stop])

	return (
		<div className={css.stop}>
			<label>{label}</label>
			<Switch
				name='usePostcode'
				onChange={() => onChange({ lane: undefined, usePostcode: !stop.usePostcode })}
				checked={stop.usePostcode}
				label='Use a Postcode'
				value=''
			/>
			<TypeAhead
				label={'Address'}
				name={'address'}
				value={value}
				onChange={(e) => lookupAddress(e)}
				keepErrorSpace={false}
				placeholder={'Search for an address'}
				Icon={SearchIcon}
				required
				options={options}
				onSelected={(option) => {
					onChange({ ...stop, lane: option as TypeAheadOption });
					setValue((option as TypeAheadOption).label as string)
				}}
				isLoadingOptions={loading}
				iconPos={INPUT_ICON_POS.LEFT}
			/>
		</div>
	);
}

export default FilterRateCardLane;

const debouncedSearchRegion = debounce(getSearchRegion());
const debouncedSearchPostcode = debounce(searchPostcode);

const EmptyStop = {
	lane: undefined as TypeAheadOption | undefined,
	usePostcode: false,
};

import {
	PropsWithChildren,
	Children,
	cloneElement,
	KeyboardEvent,
	MouseEvent,
	ReactElement,
	useContext,
	useRef,
	useState,
	useEffect
} from 'react';
import styles from './Select.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TGroupObj } from './Select';
import { v4 as uuid } from 'uuid';
import { TContext, SelectContext } from './Select';
import { createPortal } from 'react-dom';

type TProps = {
	label: string;
	index?: number;
	selected?: any;
	group?: string[] | string;
	multiple?: boolean;
	listItemMouseClick?: (v: MouseEvent<HTMLElement>, selected: boolean, groupName: string) => void;
	listItemKeyClick?: (e:KeyboardEvent<HTMLElement>, index: number, v: any, selected: boolean, groupName: string) => void;
	selectid?: string;
	currentID?: string;
	parent?: string;
}

export const OptGroup = (props:PropsWithChildren<TProps>) => {
	const {
		label,
		index,
		selected,
		group,
		multiple,
		listItemMouseClick,
		listItemKeyClick,
		selectid,
		currentID,
		parent,
		children
	} = props;
	const [hovered, setHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const context = useContext(SelectContext);
	const thisItem = useRef<HTMLLIElement | null>(null);
	const levelIDs = useRef<string[]>([]);
	const childArray = Children.toArray(children);
	const options = Children.map(childArray, (child, i) => {
		const newID = uuid();
		levelIDs?.current.push(newID);
		return cloneElement(child as ReactElement, {
			index: (index ? index + i : i),
			selected,
			group,
			multiple,
			listItemMouseClick,
			listItemKeyClick,
			selectid,
			currentID: levelIDs?.current[i],
			parent: currentID,
			groupName: label
		});
	});
	const openNextLevel = (e:MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (context?.hasNested) {
			const newPrevLevels: TGroupObj[] = [...(context?.prevLevel ?? [])];
			newPrevLevels.push({
				id: currentID ?? '',
				name: label
			});
			const newContext: TContext = { ...context };
			newContext.prevLevel = newPrevLevels;
			if (context?.setContextState) context?.setContextState(newContext);
		}
	}

	const showGroup = () => {
		if (!context?.hasNested) return true;
		if (parent === '' && context?.prevLevel && context?.prevLevel.length === 0) return true;
		if (context?.prevLevel && parent === context?.prevLevel[context?.prevLevel.length - 1]?.id) return true;
		return false;
	}

	const showBackBtn = () => {
		if (context?.prevLevel && currentID === context?.prevLevel[context?.prevLevel.length - 1]?.id) return true;
		return false;
	}
	const goBack = (e: MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const newLevels: TGroupObj[] = [...(context?.prevLevel ?? [])];
		newLevels.pop();
		const newContext: TContext = { ...context };
		newContext.prevLevel = newLevels;
		if (context?.setContextState) context?.setContextState(newContext);
	}

	const setStyles = () => {
		const css: { [key: string]: string } = {};
		const winWidth = window.innerWidth;
		if (thisItem.current) {
			const { top, left, width } = thisItem.current.getBoundingClientRect();
			if (left + (width * 2) > winWidth) {
				css['right'] = `${winWidth - left}px`;
			} else {
				css['left'] = `${left + width}px`;
			}
			css['top'] = `${top}px`;
			css['position'] = 'absolute';
			css['zIndex'] = '9999999';
			css['opacity'] = '1';
			css['minWidth'] = `${width}px`;
			css['width'] = 'fit-content';
		}
		return css;
	}
	const hasSelected = () => {
		let isSelected = false;
		Children.map(childArray, (child) => {
			if ((child as ReactElement).props.value && (child as ReactElement).props.value === selected && group === label) isSelected = true;
		});
		return isSelected;
	}
	useEffect(() => {
		if (hasSelected()) setIsOpen(true);
	}, []);

	return (
		<>
			{context?.hasNested && showBackBtn() && <li className={styles.goBack} onClick={goBack}><ArrowBackIosNewIcon className={styles.arrows} style={{ marginRight: '5px' }} /> Back</li>}
			{!context?.hasMultiLevel && !context?.hasAccordian && showGroup() && <li role={'group'} className={context?.hasNested ? styles.nestedGroupHeader : styles.groupHeader} onClick={openNextLevel}>{label} {context?.hasNested && <ArrowForwardIosIcon className={styles.arrows} />}</li>}
			{context?.hasMultiLevel &&
				<li role={'group'} ref={thisItem} className={`${context?.hasMultiLevel ? styles.multiGroupHeader : styles.groupHeader} ${group as string === label ? styles.selectedGroup : ''}`} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
					{label} {context?.hasMultiLevel && <ArrowForwardIosIcon className={styles.msArrows} />}
					{hovered && createPortal(<ul className={`${styles.list}`} role={'listbox'} style={setStyles()}>
						{options}
					</ul>, document.body)}
				</li>
			}
			{context?.hasAccordian && <li role={'group'} className={styles.accordianHeader} onClick={openNextLevel}>
				<span onClick={() => setIsOpen(!isOpen)}>{label} {<KeyboardArrowDownIcon className={`${styles.arrows} ${isOpen && styles.rotate}`} />}</span>
				{isOpen && <ul className={`${styles.accordianList}`} role={'listbox'}>
					{options}
				</ul>}
			</li>}
			{!context?.hasMultiLevel && !context?.hasAccordian && options}
		</>
	);
}

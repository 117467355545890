import {
	useState,
	useEffect,
	useCallback, ChangeEvent, useRef,
} from 'react';
import styles from '../FilterOptions.module.scss';
import { Spacer, toast, TypeAhead } from '@components/index';
import { SPACER_DIRECTION, TypeAheadOption } from '@types';
import { debounce } from 'lodash';
import { useGetTeamABMList } from '@api/general.ts';
import SearchIcon from '@mui/icons-material/Search';

type FilterProps = {
	meta: any;
	header: any;
	filterData: any;
	onChange: (val:any) => void;
}

export const FilterUserLookup = (props:FilterProps) => {
	const {
		meta,
		filterData,
		onChange
	} = props;
	const [newFields, setNewFields] = useState(0);
	const [inputVals, setInputVals] = useState<any[]>([]);
	const [selectOpts, setSelectOpts] = useState<any[]>([]);
	const currentSearchField = useRef<number>(0);
	const {
		team,
		teamError,
		callGetTeam
	} = useGetTeamABMList();
	const changeFieldCount = (add:boolean, key:number = 0) => {
		const newFilterData = { ...filterData };
		if (add) {
			setNewFields(newFields + 1);
			newFilterData.repeats = newFields + 1;
			newFilterData.value = inputVals;
		} else {
			if (key > 0) {
				const cloned = [...inputVals].filter((item, index) => {
					if (index !== key) {
						return item;
					}
				});
				setInputVals(cloned);
				newFilterData.value = cloned;
			}
			setNewFields(newFields - 1);
			newFilterData.repeats = newFields - 1;
		}
		newFilterData.columnField = meta?.databaseLocation;
		newFilterData.operatorValue = newFields === 0 ? 'contains' : 'In';
		onChange(newFilterData);
	}

	const fetchAdmin = useCallback(debounce(() => {
		callGetTeam()
	}, 500), []);

	const updateSelectAdmin = (v: ChangeEvent<HTMLInputElement> | string | TypeAheadOption, i:number, fromSearch:boolean) => {
		const cloned = [...inputVals];
		currentSearchField.current = i;
		if ((v as ChangeEvent<HTMLInputElement>).target && (v as ChangeEvent<HTMLInputElement>).target.value !== '' && !fromSearch) {
			fetchAdmin();
			cloned[i] = (v as ChangeEvent<HTMLInputElement>).target.value;
			setInputVals(cloned);
		} else if ((v as ChangeEvent<HTMLInputElement>).target && (v as ChangeEvent<HTMLInputElement>).target.value === '') {
			cloned[i] = '';
			setInputVals(cloned);
		} else if (fromSearch) {
			cloned[i] = (v as TypeAheadOption).value;
			setInputVals(cloned);
			const newFilterData = {
				...filterData,
				value: cloned,
				repeats: newFields,
				columnField: meta?.databaseLocation,
				operatorValue: 'In'
			}
			onChange(newFilterData);
		}
	}

	const getLabelUsingValue = (index: number) => {
		if (!Array.isArray(selectOpts) || !selectOpts[index]) {
			return undefined;
		}
		const options = selectOpts[index];
		if (!Array.isArray(options)) {
			return undefined;
		}
		return options.find((opt: { value: string, label: string }) =>
			opt && typeof opt === 'object' && opt.value === inputVals[index]
		)?.label ?? inputVals[index];
	};

	useEffect(() => {
		const cloned = [...inputVals];
		const v = cloned[currentSearchField.current ?? 0];
		if (team) {
			const TeamOptions = team.map((member: any) => ({
				value: member.user_id,
				label: [member.first_name, member.last_name].join(' '),
			}));
			if (v && v !== '') {
				const newOpts = TeamOptions.filter((user:{label: string, value: string}) => {
					return user.label.toLowerCase().includes(v.toLowerCase());
				});
				const clonedSelectOpts = [...selectOpts];
				clonedSelectOpts[currentSearchField.current ?? 0] = newOpts;
				setSelectOpts(clonedSelectOpts);
			}
		}

		if (teamError) {
			toast.error('Error fetching team member');
		}
	}, [team, teamError]);

	useEffect(() => {
		callGetTeam();
	}, []);

	useEffect(() => {
		if (filterData?.value) {
			setInputVals(filterData.value || []);
			setNewFields(filterData.repeats || 0);
		} else {
			setInputVals([]);
			setNewFields(0);
		}
	}, [filterData]);
	return (
		<>
			{
				<div className={styles.inputContainer}>
					<div style={{ flex: 1 }}>
						<TypeAhead
							name={'Filter-Option-User-Lookup'}
							value={getLabelUsingValue(0) || ''}
							onChange={(v) => updateSelectAdmin(v, 0, false)}
							onSelected={(v) => updateSelectAdmin(v, 0, true)}
							options={selectOpts[0] || []}
							keepErrorSpace={false}
							Icon={SearchIcon}
						/>
					</div>
					{meta?.filterOptions?.allowAdditional && <>
						<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
						<button onClick={() => changeFieldCount(true)} className={styles.addMoreBtn}>+</button>
					</>}
				</div>
			}
			{
				newFields > 0 && new Array(newFields).fill(null).map((_, key) => {
					return <div key={key} className={styles.inputContainer} style={{ marginTop: '5px' }}>
						<div style={{ flex: 1 }}>
							<TypeAhead
								name={'Filter-Option-User-Lookup'}
								value={getLabelUsingValue(key + 1) || ''}
								onChange={(v) => updateSelectAdmin(v, key + 1, false)}
								onSelected={(v) => updateSelectAdmin(v, key + 1, true)}
								options={selectOpts[key + 1] || []}
								keepErrorSpace={false}
								Icon={SearchIcon}
							/>
						</div>
						<>
							<Spacer dir={SPACER_DIRECTION.VERTICAL} size={5} />
							<button onClick={() => changeFieldCount(false, key + 1)} className={styles.addMoreBtn}>-
							</button>
						</>
					</div>
				})
			}
		</>
	)
}

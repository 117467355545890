import { useState, useEffect } from 'react';
import styles from '../FilterOptions.module.scss';
import { Checkbox, Text } from '@components/index';
import { TEXT_WEIGHT } from '@types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type FilterProps = {
    header: any;
    meta: any;
    filterData: any;
    onChange: (val:any) => void;
}

export const FilterSelectMultiple = (props:FilterProps) => {
	const {
		header,
		meta,
		filterData,
		onChange
	} = props;
	const [checkOptions, setCheckOptions] = useState<any[]>([]);
	const [accordionExpanded, setAccordionExpanded] = useState(meta?.isOpen ?? false);
	const fullName = meta?.columnFullName;
	const filterOptions = meta?.filterOptions?.options ?? [];
	const changeCheckValue = (e:any) => {
		const newOpts = [...checkOptions].map((opt) => {
			if (opt.label === e.target.name) {
				opt.checked = e.target.checked;
			}
			return opt;
		});
		setCheckOptions(newOpts);
		const newFilterData = { ...filterData };
		newFilterData.value = newOpts;
		newFilterData.columnField = meta?.databaseLocation;
		if (meta.operatorValue) {
			newFilterData.operatorValue = meta.operatorValue;
		} else {
			newFilterData.operatorValue = newOpts.filter((opt) => {
				return opt.checked;
			}).length > 1 ? 'In' : 'Is';
		}
		onChange(newFilterData);
	}
	const generateCheckOptions = (options:any[]) => {
		const opts = options.map((opt) => {
			return {
				checked: false,
				value: typeof opt === 'string' ? opt : opt.value,
				label: typeof opt === 'string' ? opt : opt.label
			}
		});
		return opts;
	}
	useEffect(() => {
		if (filterData?.value) {
			setCheckOptions(filterData.value);
		} else {
			setCheckOptions(generateCheckOptions(filterOptions));
		}
	}, [meta, filterData]);
	return (
		<>
			<div className={styles.filterLabel}>
				<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={fullName !== undefined ? fullName : header} />
				<button
					onClick={() => { setAccordionExpanded(!accordionExpanded) }}
					className={`${styles.accordionButton} ${accordionExpanded ? styles.flipArrow : ''}`}
					aria-label={'Set Accordion Expanded'}
				>
					<KeyboardArrowDownIcon />
				</button>
			</div>
			{accordionExpanded && <div className={styles.inset}>
				{/* <Checkbox value={''} onChange={(e:any) => { changeCheckValue(e) }} options={checkOptions} /> */}
				{checkOptions.map((checkbox) => {
					return <Checkbox
						name={checkbox.label}
						label={checkbox.label}
						value={checkbox.value}
						onChange={(e) => changeCheckValue(e)}
						checked={checkbox.checked}
					/>
				})}
			</div>}
		</>
	);
}

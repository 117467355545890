import { DownloadIcon } from '@img/icons';
import styles from './Header.module.scss';
import { Loader, Text } from '@components/CORE';
import { useEffect, useMemo, useState } from 'react';
import ApiClient from '@api/axiosSetup';
import toast from 'react-hot-toast';
import { AxiosInstance } from 'axios';
import { formatDate } from '@utils/dateUtils';

export interface DownloadCsvProps {
	tableId?: string,
    urlHaveTableId?: boolean,
	downloadCsvConfig?: {
		fileName: string;
		apiEndpoint?: string;
		apiClient?: AxiosInstance;
		modifyDownloadUrl?: (url: URL) => URL;
	};
}

const DownloadCsv = ({
	tableId,
	urlHaveTableId,
	downloadCsvConfig,
}: DownloadCsvProps) => {
	const [filterUrl, setFilterUrl] = useState<string>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const urlObj = new URL(window.location.href, window.location.origin);
		if (!urlHaveTableId || urlObj.searchParams.get('tableId') === tableId) {
			setFilterUrl(window.location.href);
		}
	}, [window.location.href]);

	const hasActiveFilter = useMemo(() => {
		const urlObj = new URL(filterUrl || window.location.href, window.location.origin);
		const decodedSearch = decodeURIComponent(urlObj.search);

		return decodedSearch.includes('filter[');
	}, [filterUrl]);

	const handleDownloadCsv = () => {
		if (!downloadCsvConfig) {
			return;
		}

		let urlObj = new URL(filterUrl || window.location.href, window.location.origin);
		urlObj.searchParams.set('export', 'true');
		urlObj.searchParams.set('perPage', '1000'); // max rows to download
		urlObj.searchParams.set('page', '1');

		if (downloadCsvConfig.apiEndpoint) {
			const newUrl = new URL(downloadCsvConfig.apiEndpoint, urlObj.origin);
			newUrl.search = urlObj.search;
			urlObj = newUrl;
		}

		const modifyDownloadUrl = downloadCsvConfig?.modifyDownloadUrl || ((url) => url);
		const modifiedUrl = modifyDownloadUrl(urlObj);
		const downloadApiClient = downloadCsvConfig.apiClient || ApiClient;
		const downloadUrl = modifiedUrl.pathname + modifiedUrl.search;

		setLoading(true);

		downloadApiClient
			.get(downloadUrl, { responseType: 'blob' })
			.then((resp) => {
				const blob = resp.data;

				const link = document.createElement('a');
				const url = URL.createObjectURL(blob);
				const fileName = `${downloadCsvConfig.fileName}-${formatDate(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`;

				link.href = url;
				link.download = fileName; // Name the file
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(link);
				setLoading(false);
			})
			.catch(() => {
				toast.error('Unable to download file.');
				setLoading(false);
			});
	}

	if (!downloadCsvConfig) {
		return null;
	}

	return (
		<button
			disabled={loading}
			className={`
				${styles.button}
				${styles.ctaButton}
			`}
			onClick={handleDownloadCsv}
			aria-label={'Export to CSV format'}
		>
			{!loading && <DownloadIcon />}
			{loading && <Loader size={20} thickness={3} />}
			<Text text={hasActiveFilter ? 'Export CSV - On filtered range' : 'Export CSV'} />
		</button>
	);
}

export default DownloadCsv;

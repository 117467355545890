import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, InputField, Button, Spacer } from '@components';
import { BUTTON_ACTION_TYPE, INPUT_TYPE, TEXT_WEIGHT } from '@types';
import LoginImg from '@img/login-bg.png';
import OfloadLogo from '@img/logo-full.png';
import { useFSLogin } from '@api';
import { toast } from '@components/CORE/index';

import styles from './FSLogin.module.scss';

const FreightSafeLogin = () => {
  const [token, setToken] = useState<string>('');
  const isAuthenticatedUser = localStorage.getItem('jwt');

  const { isLoading, data, error, callAPI, clearData, clearError } =
    useFSLogin();

  const { jwtKey = '' } = data || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtKey) {
      localStorage.setItem('jwt', jwtKey);
      navigate('/freight-safe/details');
      toast.success('Logged In Successfully!');
      clearData();
    }
    if (error) {
      toast.error('Invalid token, please try again');
      clearError();
    }
  }, [data, error]);
 
  useEffect(() => {
    if (isAuthenticatedUser) {
      navigate('/freight-safe/details');
    }
  }, [isAuthenticatedUser]);

  const handleFormChange = (e: any) => {
    const { value } = e.target;
    setToken(value);
  };

  const submitToken = (e: any) => {
    e.preventDefault();
    callAPI({ payload: { token: token } });
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <a href={'https://www.ofload.com'} target={'_blank'} rel={'noreferrer'}>
          <img alt={'Ofload logo'} src={OfloadLogo} className={styles.logo} />
        </a>
        <form className={styles.form} onSubmit={submitToken}>
          <Text weight={TEXT_WEIGHT.BOLD} type={'h4'} text={'Log-in'} />
          <Spacer size={20} />
          <InputField
            type={INPUT_TYPE.TEXT}
            placeholder={'Token'}
            value={token}
            name={'token'}
            onChange={(e: any) => handleFormChange(e)}
          />
          <Spacer size={20} />
          <Button
            loading={isLoading}
            text={'Submit'}
            onClick={submitToken}
            actionType={BUTTON_ACTION_TYPE.SUBMIT}
            disabled={!token}
          />
        </form>
      </div>
      <div className={styles.rightContainer}>
        <img
          alt={'Login Background'}
          src={LoginImg}
          style={{ maxWidth: '100%' }}
        />
      </div>
    </div>
  );
};
export default FreightSafeLogin;

type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const ListAddIcon = (props:IconProps) => {
	const {
		width = 24,
		height = 24,
		color = 'var(--brand-orange-default)'
	} = props;
	return (
		<svg role={'img'} viewBox={'0 0 24 24'} height={height} width={width} xmlns={'http://www.w3.org/2000/svg'}>
			<path fill={'none'} className={'NoFill'} d='M0 0h24v24H0z'></path>
			<path fill={color} d='M18 13c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm3 5.5h-2.5V21h-1v-2.5H15v-1h2.5V15h1v2.5H21v1zM7 5h13v2H7z'></path>
			<circle fill={color} cx='3.5' cy='18' r='1.5'></circle>
			<path fill={color} d='M18 11H7v2h6.11c1.26-1.24 2.99-2 4.89-2zM7 17v2h4.08c-.05-.33-.08-.66-.08-1s.03-.67.08-1H7z'></path>
			<circle fill={color} cx='3.5' cy='6' r='1.5'></circle>
			<circle fill={color} cx='3.5' cy='12' r='1.5'></circle>
		</svg>
	);
};

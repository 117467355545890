import { useRef, forwardRef, ChangeEvent } from 'react';
import styles from './UploadControl.module.css';
import UploadIcon from '@mui/icons-material/FileUpload';
import { Button, Spacer, Caption, InfoChip } from '@components';
import { BUTTON_TYPES, SPACER_DIRECTION, CHIP_TYPE } from '@types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type UploadControlType = {
	handleFiles: (e:ChangeEvent<HTMLInputElement>) => void;
	fileTypeText?: string;
	progressInfo:any
}

export const UploadControl = forwardRef((props:UploadControlType, ref:any) => {
	const { handleFiles, progressInfo, fileTypeText = 'Files to Upload' } = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const Upload = () => {
		if (containerRef !== null) {
			(containerRef.current?.children[2] as HTMLInputElement).focus();
			(containerRef.current?.children[2] as HTMLInputElement).click();
		}
	}

	return (
		<>
			<div className={styles.container} ref={containerRef}>
				<UploadIcon className={styles.icon} />
				<div className={styles.text}>
					<p style={{ fontWeight: 700 }}>Drag & drop {fileTypeText}</p>
					<p>or click the Upload button</p>
				</div>
				<input
					ref={ref}
					type='file'
					accept='image/jpeg, image/png, image/tiff,application/pdf'
					className={styles.input}
					multiple
					onChange={(event) => handleFiles(event)}
				/>
				<div style={{ position: 'relative', zIndex: 2 }}>
					<Button text={'Upload'} onClick={Upload} type={BUTTON_TYPES.SECONDARY} />
				</div>
			</div>
			<Spacer size={10} />
			<div className='flex'>
				<InfoOutlinedIcon className='infoIcon' />
				<Spacer size={7} dir={SPACER_DIRECTION.VERTICAL} />
				<Caption text={'Maximum file size is 80 MB. Accepted file formats: pdf, jpg, png, tiff.'} />
			</div>
			{progressInfo && progressInfo?.map((progress: any) => {
				return (
					<>
						{progress?.is_error &&
							<InfoChip type={CHIP_TYPE.ERROR} text={`Error uploading ${progress?.fileName}. Exceeds the maximum upload size.`} showIcon />}
					</>)
			})}
		</>
	);
});

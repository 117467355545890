import DashboardIcon from '@mui/icons-material/Dashboard';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import { GroupLoadIcon, OverviewIcon } from '@img/icons';
import { navigator } from '@types';

const carrierNav = (enabled:boolean, cID?:string) => {
	const root = `/carrier/${cID}`;
	const carrierMenuItems = [
		{
			label: 'Overview',
			icon: (color:string) => <OverviewIcon color={color} />,
			link: `${root}/overview`,
			enabled,
			activeURL: 'overview'
		},
		{
			label: 'Marketplace',
			icon: (color:string) => <GavelIcon style={{ color: color }} />,
			link: `${root}/marketplace`,
			enabled,
			activeURL: 'marketplace'
		},
		{
			label: 'Your Loads',
			icon: (color:string) => <FormatListBulletedIcon style={{ color: color }} />,
			link: `${root}/your_loads`,
			enabled,
			activeURL: 'your_loads'
		},
		{
			label: 'Group Loads',
			icon: (color:string) => <GroupLoadIcon color={color} />,
			link: `${root}/group_loads`,
			enabled,
			activeURL: 'group_loads'
		},
		{
			label: 'Rate Cards',
			icon: (color:string) => <PriceChangeOutlinedIcon style={{ color: color }} />,
			link: `${root}/rate_cards`,
			enabled,
			activeURL: 'rate_cards'
		},
		{
			label: 'Invoices',
			icon: (color:string) => <ReceiptOutlinedIcon style={{ color: color }} />,
			link: `${root}/invoices`,
			enabled,
			activeURL: 'invoices'
		},
		{
			label: 'Preferred Routes',
			icon: (color:string) => <AltRouteIcon style={{ color: color }} />,
			link: `${root}/preferred_routes`,
			enabled,
			activeURL: 'preferred_routes'
		},
		{
			label: 'Fintech',
			icon: (color:string) => <ContactlessOutlinedIcon style={{ color: color }} />,
			link: `${root}/fintech`,
			enabled,
			activeURL: 'fintech'
		},
		{
			label: 'Analytics',
			icon: (color:string) => <TrendingUpOutlinedIcon style={{ color: color }} />,
			link: `${root}/analytics`,
			enabled,
			activeURL: 'analytics'
		},
		{
			label: 'Fleet',
			icon: (color:string) => <LocalShippingOutlinedIcon style={{ color: color }} />,
			link: `${root}/fleet`,
			enabled,
			activeURL: 'fleet'
		},
		{
			label: 'Exclusive Offers',
			icon: (color:string) => <PercentOutlinedIcon style={{ color: color }} />,
			link: `${root}/exclusive_offers`,
			enabled,
			activeURL: 'exclusive_offers'
		},
		{
			label: 'Company Details',
			icon: (color:string) => <StoreOutlinedIcon style={{ color: color }} />,
			link: `${root}/company_details`,
			enabled: true,
			activeURL: 'company_details'
		},
		{
			label: 'Settings',
			icon: (color:string) => <SettingsOutlinedIcon style={{ color: color }} />,
			link: `${root}/settings`,
			enabled: true,
			activeURL: 'settings'
		}
	];
	const defaultMenuItems = [
		{
			label: 'Dashboard',
			icon: (color:string) => <DashboardIcon style={{ color: color }} />,
			link: `${root}/dashboard`,
			enabled: true,
			activeURL: 'dashboard'
		}
	] as navigator[];

	return cID ? [...defaultMenuItems, ...carrierMenuItems] : defaultMenuItems;
}

export default carrierNav;

import { TEMP_NAMES } from '@types';

export const getTemperatures = [
	{
		label: TEMP_NAMES.AMBIENT,
		value: 1,
	},
	{
		label: TEMP_NAMES.CHILLED,
		value: 2,
	},
	{
		label: TEMP_NAMES.FROZEN,
		value: 3,
	}
];